import { useQuery } from '@tanstack/react-query';
import { API_E2E_GET_APPLICANT_FORM } from 'src/api/e2e-request-objects';
import { TsQueryI } from 'src/api/query-logic/query-interfaces';
import { createQueryKey } from 'src/api/query-logic/query-utils';

import { apiRequest } from '../request-handler';

export const queryE2eForm = (obj: TsQueryI) => {
  return useQuery({
    queryKey: createQueryKey('get-e2e-form', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_E2E_GET_APPLICANT_FORM, obj.reqData);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 5 * 60 * 1000, // 0 Always stale - in ms
    // if you add a stale time, then you can't include initial data
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    // refetchInterval: 0,
    // gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
