import * as React from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { NewE2ERoutes } from 'src/enums/routes.enums';

const StyledNavButton = styled(Button)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.common.white,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  '&:last-child': {
    marginRight: 0,
  },
  textTransform: 'uppercase',
  fontSize: '0.775rem',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: '0.02857em',
  minHeight: 36,
  minWidth: 64,
  width: '100%',
  borderRadius: 0,
  hover: {
    background: '#bad80a',
    color: theme.palette.common.black,
  },
  '&:hover': {
    background: '#bad80a',
    color: theme.palette.common.black,
  },
}));

const Navigation: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [openSection, setOpenSection] = React.useState<number | null>(null);

  const devUUID = 'fh2Wss72uEzzXOW2924UPxtTWx2cta';
  const devStoreName = 'primary-store';
  const aasoId = '661e91a3b7fafc9d4b091e5a';
  const e2eFormId = '1-1';
  const newUrl = `/${devStoreName}/${devUUID}/${e2eFormId}/`;
  const newUrlWithAaso = `/${devStoreName}/${devUUID}/${e2eFormId}/${aasoId}/`;

  const sections = [
    {
      title: 'old flow',
      links: [
        { text: 'Landing Page', path: `/${devStoreName}/${devUUID}` },
        { text: 'Create Account', path: `/${devStoreName}/${devUUID}/create-account` },
        { text: 'Verify Customer', path: `/${devStoreName}/${devUUID}/verify-customer` },
        { text: 'Select Application Type', path: `/${devStoreName}/${devUUID}/select-application-type` },
        { text: 'Welcome', path: `/${devStoreName}/${devUUID}/welcome` },
        { text: 'Privacy Policy', path: `/${devStoreName}/${devUUID}/privacy-policy` },
        { text: 'Lender Disclosure', path: `/${devStoreName}/${devUUID}/lender-disclosure/:lender_id` },
        { text: 'Prime Application', path: `/${devStoreName}/${devUUID}/prime-application` },
        { text: 'Sub-Prime Application', path: `/${devStoreName}/${devUUID}/sub-prime-application` },
        {
          text: 'Prime Lender Information Review',
          path: `/${devStoreName}/${devUUID}/prime-lender-information-review`,
        },
        { text: 'Prime Approval Review', path: `/${devStoreName}/${devUUID}/prime-approval` },
        { text: 'Prime Denied', path: `/${devStoreName}/${devUUID}/prime-denied` },
        { text: 'Sign Contract', path: `/${devStoreName}/${devUUID}/sign-contract` },
        { text: 'Sign Contract Completed', path: `/${devStoreName}/${devUUID}/sign-contract-completed` },
      ],
    },
    {
      title: 'new flow',
      links: [
        { text: 'Welcome', path: newUrl + NewE2ERoutes.LandingPage },
        {
          text: 'Current Customer Accept Changes',
          path: newUrl + NewE2ERoutes.CustomerAcceptNewFpDisclosures,
        },
        { text: 'Create Account New Customer', path: newUrl + NewE2ERoutes.CreateAccount },
        { text: 'Communication Notice', path: newUrl + NewE2ERoutes.CommunicationNotices },
        { text: 'Verify Current Customers', path: newUrl + NewE2ERoutes.OTPVerification },
        {
          text: 'Select Application Type',
          path: newUrlWithAaso + NewE2ERoutes.SelectApplicationType,
        },
        { text: "Let's Get Started", path: newUrlWithAaso + NewE2ERoutes.GetStarted },
        { text: 'Provider Disclosures', path: newUrlWithAaso + NewE2ERoutes.ProviderDisclosures },
        {
          text: 'Enter Prime Provider Information',
          path: newUrlWithAaso + NewE2ERoutes.Application,
        },
        { text: 'Provider Response', path: newUrlWithAaso + NewE2ERoutes.ProviderResponses },
        {
          text: 'Multi-Provider Disclosures',
          path: newUrlWithAaso + NewE2ERoutes.MultiProviderDisclosures,
        },
        {
          text: 'Prime Provider Response',
          path: newUrlWithAaso + NewE2ERoutes.PrimeProviderSingleFlow,
        },
        {
          text: 'Sub-Prime Provider Response',
          path: newUrlWithAaso + NewE2ERoutes.PrimeProviderSingleFlow,
        },
        {
          text: 'Prime Provider Response Single Flow',
          path: newUrlWithAaso + NewE2ERoutes.PrimeProviderSingleFlow,
        },
      ],
    },
  ];

  const handleClickAway = () => {
    setOpenSection(null);
  };

  return (
    <>
      {open ? (
        <AppBar
          position="fixed"
          sx={{
            background: 'rgba(0, 0, 0, 0.87)',
            boxShadow: 'none',
            flexDirection: 'row',
            width: 'fit-content',
            left: 0,
            top: 0,
            height: '100vh',
          }}
        >
          <Toolbar
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 0 }}
            disableGutters
          >
            {sections.map((section, index) => (
              <Box key={index} sx={{ position: 'relative' }}>
                <StyledNavButton
                  onClick={() => setOpenSection(openSection === index ? null : index)}
                  sx={{ justifyContent: 'flex-start', background: openSection === index ? '#bad80a' : 'inherit' }}
                >
                  {section.title}
                </StyledNavButton>
                {openSection === index && (
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '100%',
                        top: 0,
                        background: 'rgba(0, 0, 0, 0.87)',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '15vw',
                        zIndex: 1,
                      }}
                    >
                      {section.links.map((link, linkIndex) => (
                        <StyledNavButton
                          key={linkIndex}
                          onClick={() => {
                            window.location.href = link.path;
                            setOpen(false);
                          }}
                          sx={window.location.pathname === link.path ? { background: '#bad80a', color: '#000' } : {}}
                        >
                          {link.text}
                        </StyledNavButton>
                      ))}
                    </Box>
                  </ClickAwayListener>
                )}
              </Box>
            ))}
          </Toolbar>
        </AppBar>
      ) : null}
      <Button
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999,
          background: '#bad80a',
          width: 100,
          height: 50,
          borderRadius: 0,
        }}
        onClick={() => setOpen(!open)}
      >
        {open ? 'Close' : 'Open'}
      </Button>
    </>
  );
};

export default Navigation;
