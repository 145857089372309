/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { IMaskInput, IMaskMixin } from 'react-imask';
import MaskedInput from 'react-text-mask';
import { alphaNumericWithSpacesOnlyRegex } from 'src/pages/application/dynamic-form/validation-creator';

type CCMask = Record<string, (string | RegExp)[]>;

const ccMasks: CCMask = {
  amex15: [/3/, /[47]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
  standard16: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export default function CreditCardMask(props: any) {
  const { inputRef, ...other } = props;
  const [cardMaskType, setCardMaskType] = useState(ccMasks['standard16']);

  const cardDigits = other?.value;
  useEffect(() => {
    /**
     * Wait until we have at least two digits to determine card mask to use
     */
    const determineCCMask = () => {
      const firstTwoDigits = cardDigits.slice(0, 2);

      /**
       * ALL Amex start with either '34' or '37'
       * {@see {@link https://www.americanexpress.com/content/dam/amex/hk/en/staticassets/merchant/pdf/support-and-services/useful-information-and-downloads/GuidetoCheckingCardFaces.pdf American Express®Card security features}}
       */
      if (firstTwoDigits === '37' || firstTwoDigits === '34') {
        setCardMaskType(ccMasks['amex15']);
      } else {
        setCardMaskType(ccMasks['standard16']);
      }
    };

    determineCCMask();
  }, [cardDigits]);

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={cardMaskType}
      guide={false}
    />
  );
}
