import type { FC } from 'react';

export const Logo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.13 170.56">
      <title>{'FormPiper-LogoSuite'}</title>
      <path
        d="M220.7 32c-4.09-.35-9 .37-12.51.33-6.63-.09-13.25-.29-19.86-.75-27.51-1.94-52.15-13-80.36-10.64-7.14.59-14.3 1.74-21.45 1.19C75.75 21.3 70.09 15.4 61.91 9.4c-5.1-3.75-11.3-6.79-17.55-5.83S33.07 9.3 29.68 14.65c-3 4.69-5 10.27-4.11 15.75a19.51 19.51 0 0 0 2.14 6 1.77 1.77 0 0 1-.21 2L4.57 64.11a1.76 1.76 0 0 0-.4.78l-.29 1.33A1.73 1.73 0 0 0 6 68.28l2.95-.74a1.87 1.87 0 0 0 .62-.3l24.86-18.65a1.73 1.73 0 0 1 2.39.31c2.22 2.81 4.45 5.61 6.73 8.37 10.2 12.39 21.17 24.12 34.55 32.84a81.64 81.64 0 0 0 23.65 10.62 68.26 68.26 0 0 0 12.94 2.13 51.28 51.28 0 0 0 6.44.12c1.7-.11 3.69-.8 5.13.52a5.1 5.1 0 0 1 1.28 2.74c1.43 6.26 1.94 12.78 2.79 19.13a1.72 1.72 0 0 1-.84 1.72c-6 3.48-11.87 7.06-17.68 10.8-3.15 2-6.47 4-9.53 6.13-2.54 1.8-4.85 4.32-5.26 7.41s4.24.61 5.33.14a76.51 76.51 0 0 0 9.25-4.81c-1.7 1-.63 3.76 1.32 1.35 2.92-3.61 3-5.87 5.92-9.48 2.64-3.26 8-7.25 12.11-8.57.07.47.13.94.19 1.42.83 6.22 1.66 12.58.4 18.72-1.09 5.36-4.09 10.61-8.71 13.36a2.75 2.75 0 0 1-.3.14l-1.61.64A19.49 19.49 0 0 0 116 166c-.18.08-.37.21-.37.4s.2.33.39.4a9.32 9.32 0 0 0 4.21.32l7.65-.59a8.34 8.34 0 0 0 4-1 8.45 8.45 0 0 0 1.72-1.66 4 4 0 0 0 .64-1h.14a23.27 23.27 0 0 1 2.79-6.28 1.78 1.78 0 0 1 .84-.81c.63-.2 3 2.82 5.71 5.24a1.73 1.73 0 0 0 2.38-2.52c-2.52-2.6-5.31-5-7.28-8-4-6.16-4.12-14-4.1-21.31v-.12a35.32 35.32 0 0 0 6.62-2c1.61-.72 3.8-1.18 4.69-2.7 1.92-3.24 1.53-8.17 1.41-11.83a56.82 56.82 0 0 0-2-13.09 6.23 6.23 0 0 1-.33-2.86c.32-1.58 2.5-2.17 3.74-3q2.75-1.73 5.36-3.68a108.51 108.51 0 0 0 9.79-8.16c9.47-8.89 18.78-18 28.69-26.37 9.13-7.73 19.06-13.7 28.85-20.23a1.73 1.73 0 0 0-.84-3.15ZM140 118.6a6.28 6.28 0 0 1-2.39 3.56 22 22 0 0 1-2.79 1.45s-.06-2.07-.06-2.8v-12.25c0-2.39 0-4.88-1.13-7a7.15 7.15 0 0 0-.44-.68q.95-.27 1.89-.6a1.74 1.74 0 0 1 2.27 1.26c1 4.29 1.94 8.54 2.53 12.92.06.4.11.81.15 1.22a9.47 9.47 0 0 1-.03 2.92Z"
        data-name="Design Layer"
        style={{
          fill: '#243f8f',
        }}
      />
    </svg>
  );
};
