import { setIn } from 'formik';
import { ApplicationBuilderEditFormType } from 'src/interfaces/application.interfaces';

export const convertFieldsToEmptyObjects = (fields: string[], convertTo: any = '') => {
  return fields.reduce((a, v) => ({ ...a, [v]: convertTo }), {});
};

const removeAllNonNumeric = (str: string) => {
  return str.replace(/\D/g, '');
};

export const getRequiredFieldsForForm = (application_settings: any, selected_form: ApplicationBuilderEditFormType) => {
  return application_settings[selected_form].required_fields;
};

export const getIpAddress = async () => {
  return (async function () {
    // @ts-ignore
    if (getIPs) {
      // @ts-ignore
      const data = await getIPs();
      return data;
    }
  })();
};

export const validateFormValues = (schema: any) => async (values: any) => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce(
      (formError: any, innerError: any) => setIn(formError, innerError.path, innerError.message),
      {},
    );

    return errors;
  }
};

export const convertSectionToLabel = (name: string): string => {
  switch (name) {
    case 'basicFormFields':
      return 'Applicant Personal Information';
    case 'employmentFields':
      return 'Other Income Information';
    case 'housingFields':
      return 'Housing';
    case 'identificationFields':
      return 'Identifications';
    case 'purchaseFields':
      return 'References';
    case 'verificationFields':
      return 'Verification';
    case 'bankingFields':
      return 'Banking';
    case 'coApplicantFields':
      return 'Co-Applicant';
    default:
      return 'General';
  }
};

export const cleanData = (values: any) => {
  const keys = Object.keys(values);
  keys?.forEach((k) => {
    const obj = values[k];
    const isItAStrThatContainsDollarSign = obj && typeof obj === 'string' && obj.includes('$');

    // Updated to strip `-` from newly masked CC Input
    if (k === 'card_number' || isItAStrThatContainsDollarSign) {
      values[k] = removeAllNonNumeric(obj);
    }
  });
  // IMPORTANT - not cleaning this data, bc it will mess up pipes that use substring()
  // values.mobile_phone = removeAllNonNumeric(values.mobile_phone);
  // values.home_phone = removeAllNonNumeric(values.home_phone);
  return values;
};
