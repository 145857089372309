import React, { FC, ReactNode } from 'react';
import { Container } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface ThemedContainerProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

const ThemedContainer: FC<ThemedContainerProps> = ({ children, sx = [] }) => {
  return (
    <Container maxWidth="lg" sx={[{ padding: '20px', paddingTop: '0', flex: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {children}
    </Container>
  );
};

export default ThemedContainer;
