import type { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { fpAuthColors } from '../../theme/fp-auth-colors';

interface FPAuthPortalGreetingProps {
  messaging: string;
}

/**
 * Render the logo, call to action message, and highlight image
 * @function FPAuthImgMessaging
 * @param {object} props
 */

const FPAuthPortalGreeting: FC<FPAuthPortalGreetingProps> = (props: FPAuthPortalGreetingProps) => {
  const { messaging } = props;

  return (
    <Stack direction="column" sx={{ height: '100%' }}>
      <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
        <img src="/assets/formpiper.svg" alt="FormPiper wordmark" width={250} />
        <Typography variant="body1" color={fpAuthColors.fpDarkBlue}>
          {messaging}
        </Typography>
      </Stack>
      <Box
        sx={{
          flex: 0,
          display: { xs: 'none', md: 'none', lg: 'flex' },
          justifyContent: 'flex-end',
        }}
      >
        <img src="/assets/auth/man-checking-phone.png" alt="Man checking phone, doing business" />
      </Box>
    </Stack>
  );
};

export default FPAuthPortalGreeting;
