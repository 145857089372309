import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_E2E_GET_AASO_BY_APPLICANT } from 'src/api/e2e-request-objects';
import {
  E2eBaseCallDetailsI,
  e2eGetBaseAuthenticatedRequests,
  e2eGetBasePublicRequests,
} from 'src/api/query-logic/e2e-query-base-calls';
import { apiRequest } from 'src/api/request-handler';
import { E2eLogicObjectsWithParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  E2eRoutingArgsI,
  E2eRoutingLogicI,
  handleE2eLoginLogicRoute,
  handleE2eRoutingLogic,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { useSearchParams } from 'src/hooks/use-search-params';
import { E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';
import { routeTransitionMap } from 'src/interfaces/transition-maps';

export const newLoginLogic_init = (routingPackage: E2eLogicObjectsWithParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id } = params;
  const { e2eStore, auth } = routingPackage;
  const { isAuthenticated } = auth;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid: params.store_uuid,
    e2e_form_id: params.e2e_form_id,
  };
  // this runs if user is not authenticated
  if (baseCallDetails.store_uuid && baseCallDetails.e2e_form_id) {
    e2eGetBasePublicRequests(e2eStore, baseCallDetails, isAuthenticated);
    // this runs if they are
    e2eGetBaseAuthenticatedRequests(baseCallDetails, e2eStore, isAuthenticated);
  }

  // we can add useEffects here too if we wanted
  useEffect(() => {
    if (store_uuid && e2e_form_id) {
      // if exist add to e2e store
      useE2eStore.setState({ store_uuid, e2e_form_id });
    }
  }, [store_uuid, e2e_form_id]);
};

export interface NewLoginFunctionsI extends ComponentLogicI {
  fn: {
    handleContinueExistingApp: (continueRoute: any | undefined, continueAASO: any) => void;
    handleAASORedirect: () => void;
    handleCreateNewApp: (returnTo: string) => void;
  };
  v: {
    continueRoute: string;
    continueAASO: any;
    openContinueApp: boolean;
    returnTo: string | null;
  };
}
export const newLoginLogic_functionsAndVals = (routingPackage: E2eLogicObjectsWithParams): NewLoginFunctionsI => {
  const { store_uuid, e2e_form_id } = routingPackage.params;
  const { auth } = routingPackage;
  const { user } = auth;
  const createAaso = useE2eStore((state: E2eGlobalStore) => state.createAaso);
  const setAaso = useE2eStore((state: E2eGlobalStore) => state.setAASO);
  const [continueRoute, setContinueRoute] = useState<any>();
  const [continueAASO, setContinueAASO] = useState<any>();
  const [openContinueApp, setOpenContinueApp] = useState<boolean>(false);

  const returnTo = useSearchParams().get('returnTo');

  const handleShowContinue = (continueRoute: string, continueAASO: any) => {
    setContinueAASO(continueAASO);
    setOpenContinueApp(true);
    setContinueRoute(continueRoute);
  };
  const handleReturnTo = async (returnTo: string, AASO?: any) => {
    if (!AASO?._id && store_uuid && e2e_form_id) {
      console.log('[[returnTo]]', returnTo);
      // create new aaso
      const generatedAASO = await createAaso(user, store_uuid, e2e_form_id);
      const newRoutingPackage: E2eRoutingLogicI = {
        ...routingPackage,
        aasoContext: { aaso: generatedAASO },
      };
      const routingArgs: E2eRoutingArgsI = {
        currentStage: ApplicationStage.STARTED,
        currentE2eRoute: E2ERoutes.Welcome,
        aaso: generatedAASO,
        continueRoute: returnTo,
      };
      handleE2eLoginLogicRoute(newRoutingPackage, routingArgs, e2e_form_id);
      return;
    }
    // default if nothing works - will redirect them to dashboard
    return window.location.replace(returnTo);
  };

  const handleContinueExistingApp = (continueRoute: any | undefined, continueAASO: any) => {
    const newRoutingPackage: E2eRoutingLogicI = {
      ...routingPackage,
      aasoContext: { aaso: continueAASO },
    };
    setAaso(continueAASO);
    const routingArgs: E2eRoutingArgsI = {
      currentStage: continueAASO.current_stage,
      currentE2eRoute: E2ERoutes.Welcome, // this doesn't matter
      aaso: continueAASO,
      continueRoute,
      continue: true,
    };
    handleE2eRoutingLogic(newRoutingPackage, routingArgs, continueAASO);
  };

  const handleAASORedirect = async () => {
    if (user) {
      let AASO;
      try {
        if (store_uuid) {
          // check to see if an existing aaso already exists
          AASO = await apiRequest(API_E2E_GET_AASO_BY_APPLICANT, {
            params: { applicant_id: user?.id, store_uuid, e2e_form_id },
          });
        }
      } catch (e) {
      } finally {
        // aaso does not exists, create new
        if (!AASO?._id && returnTo) return handleReturnTo(returnTo);

        if (AASO?._id) {
          // aaso already exists
          const routeFromStage = routeTransitionMap[AASO.current_stage as keyof typeof routeTransitionMap];
          if (routeFromStage) {
            return handleShowContinue(routeFromStage, { ...AASO });
          }

          if (returnTo) return handleReturnTo(returnTo, AASO);
        } else {
          routingPackage.navigate('/dashboard');
        }
      }
    }
  };

  const handleCreateNewApp = async (returnTo: string) => {
    if (store_uuid && e2e_form_id) {
      const generatedAASO = await createAaso(user, store_uuid, e2e_form_id);
      const routingArgs: E2eRoutingArgsI = {
        currentStage: ApplicationStage.STARTED,
        currentE2eRoute: E2ERoutes.Welcome,
        aaso: generatedAASO,
        continueRoute: returnTo,
      };
      handleE2eLoginLogicRoute(routingPackage, routingArgs, e2e_form_id);
    }
  };

  return {
    fn: {
      handleContinueExistingApp,
      handleAASORedirect,
      handleCreateNewApp,
    },
    v: {
      continueAASO,
      continueRoute,
      openContinueApp,
      returnTo,
    },
  };
};
