import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  e2eConfigLogic_buildProviderResponseLogicObject,
  ProviderOfferLogicObjectI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import { ApplicationSubmissionStateDto, AppSubLenderResponseE } from 'src/interfaces/submissions.interfaces';
import { parseHtml } from 'src/utils/general';

interface ResponseCardProps {
  // details: NotOffersI;
  groupedOffer: GroupedOfferI;
}

export default function NewNotOfferCard(props: ResponseCardProps) {
  const { groupedOffer } = props;
  const details = groupedOffer.notOffers[0];
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  const e2eStore = routingPackage.e2eStore;
  const { params } = routingPackage;
  const { e2e_form_id, store_uuid, aaso_id } = params;
  const formDocs = e2eStore.form_values;
  const form_values = formDocs?.data;
  const submissionDetails: ApplicationSubmissionStateDto = details.sub_details;
  const [offerLogicObj, setOfferLogicObj] = useState<ProviderOfferLogicObjectI>();
  const [showEcoa, setShowEcoa] = useState<boolean>(false);
  const l: LendersI = details.lender;
  const lenderDisclosures = details.lender_disclosures;
  const theme = useTheme();
  if (!e2e_form_id || !store_uuid || !aaso_id) throw new Error('No key params');

  const EcoaMessage =
    'In accordance with the provisions of the Equal Credit Opportunity Act (ECOA) and the Fair Credit Reporting Act (FCRA), the provider(s) your information was submitted to will notify you within thirty (30) days of a denial of credit decisions and the reasons why that decision was made.';

  useEffect(() => {
    const showEcoaStatuses = [AppSubLenderResponseE.rejected, AppSubLenderResponseE.soft_decline];
    if (showEcoaStatuses.includes(submissionDetails.lender_response)) {
      setShowEcoa(true);
    }
  }, [submissionDetails]);

  useEffect(() => {
    if (details) {
      const obj = e2eConfigLogic_buildProviderResponseLogicObject(details);
      if (obj) {
        setOfferLogicObj(obj);
      }
    }
  }, [details]);

  const renderDefinitionItem = (term: string, definition: string | number | undefined) => {
    if (definition === undefined || definition === '') return null;
    return (
      <>
        <Typography component="dt" variant="body1" style={{ color: theme.palette.text.primary }}>
          {term}
        </Typography>
        <Typography component="dd" variant="body1" style={{ color: theme.palette.primary.main }}>
          {definition}
        </Typography>
      </>
    );
  };

  const getLenderLogo = () => {
    if (l.logo_url) {
      // R:TODO E2E P3 - see other note about how to handle lender logos
      // Go based on the lender object logo url
      return {
        src: l.logo_url,
        alt: l.name,
      };
    } else {
      // if no logo exists in lender object, use disclosures
      if (lenderDisclosures && lenderDisclosures?.length > 0) {
        const disc = lenderDisclosures.find((ld) => ld.logo.length > 0);
        if (disc) {
          return {
            src: disc.logo,
            alt: l.name,
          };
        }
      }
    }
    return false;
  };
  if (!offerLogicObj) return null;

  const lenderLogoDetails = getLenderLogo();
  const updateWording = (word: string) => {
    if (word === AppSubLenderResponseE.rejected) {
      return 'Declined';
    }
    if (word === AppSubLenderResponseE.nr) {
      return 'Pending';
    }
    return word;
  };

  const getCorrectDeclinedMessage = () => {
    let message = EcoaMessage;
    if (submissionDetails.lender_declined_message && submissionDetails.lender_declined_message.length > 0) {
      message = submissionDetails.lender_declined_message;
    }
    return (
      <Typography variant="body1" style={{ margin: '20px' }} paragraph>
        {parseHtml(message)}
      </Typography>
    );
  };

  return (
    <ThemedCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '52px' }}>
        {lenderLogoDetails && <SingleLogo image={lenderLogoDetails} />}
      </Box>
      <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px', marginBottom: '20px' }}>
        {renderDefinitionItem('Applicant Name', `${form_values?.first_name} ${form_values?.last_name}`)}
        {renderDefinitionItem('Provider Response', updateWording(submissionDetails.lender_response))}
      </dl>
      {/* TODO: Add response.message conditionally here */}
      {submissionDetails && submissionDetails?.lender_approved_message && (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {parseHtml(submissionDetails?.lender_approved_message)}
        </Typography>
      )}
      {submissionDetails && submissionDetails?.lender_pending_message && (
        <Typography variant="body1" style={{ margin: '20px' }} paragraph>
          {parseHtml(submissionDetails?.lender_pending_message)}
        </Typography>
      )}
      {showEcoa && getCorrectDeclinedMessage()}
    </ThemedCard>
  );
}
