import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogProps, Button } from '@mui/material';
import styles from './ThemedDialog.module.css';

interface ThemedDialogProps extends DialogProps {
  onClose: () => void;
  content: string;
}

const ThemedDialog: React.FC<ThemedDialogProps> = ({ onClose, content, ...props }) => {
  return (
    <Dialog {...props} onClose={onClose}>
      <DialogContent>
        <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThemedDialog;

