import React from 'react';
import { Box, Typography } from '@mui/material';

export default function WelcomeHeader() {
  return (
    <Box
      component="header"
      sx={{
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <Box
        component="img"
        src="/assets/e2e-redesign/images/white-logo-large.png"
        alt="FormPiper Logo"
        sx={{ width: '196px', marginBottom: '20px', marginTop: '20px' }} 
      />
      <Typography variant="h1" color="primary">
        Welcome!
      </Typography>
      <Typography variant="body1" color="white" sx={{ marginTop: '20px', marginBottom: '20px' }}>
        Professional software developed to securely connect you with numerous third-party payment providers.
      </Typography>
    </Box>
  );
}