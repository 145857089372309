export interface Disclosure {
  _id: string;
  name: string;
  sequence: number;
  document: string;
  document_header: string;
  lender_id?: number;
  dynamic_link?: string;
  protected_document?: boolean;
  logo: string;
  accepted_users: object[];
  disclosure_type: DisclosureTypeE;
  processor_id?: number;
  user_acceptance_required_on_update?: boolean;
  version: string;
  is_archived: boolean;
  archived_at: Date;
  is_active?: boolean;
  integration_id?: string;
  opt_in_marketing?: boolean;
  internal_name?: string;
}

export enum DisclosureTypeE {
  'lender' = 'lender',
  'fp' = 'fp',
  'fp_merchant' = 'fp_merchant',
  'processor' = 'processor',
  'fp_myfp_tc' = 'fp_myfp_tc',
  'integration' = 'integration',
  'other' = 'other',
  'fp_privacy' = 'fp_privacy',
  'fp_sms_consent' = 'fp_sms_consent',
  'fp_additional_communications' = 'fp_additional_communications',
  'fp_customer_notice' = 'fp_customer_notice',
  'fp_bullets' = 'fp_bullets',
}

export interface DisclosureAasoI {
  name: string;
  is_accepted: boolean;
  date_accepted?: Date;
  lender_id?: any;
  ip_address?: any;
  opt_in_marketing?: boolean; // NEW one added for lenders who have opt-in
  _id?: string;
  internal_name?: string;
}
