import { FC, ReactNode } from 'react';
import { Box, styled, ThemeProvider } from '@mui/material';
import DashboardHeader from 'src/e2e-redesign/e2e-dashboard/components/dashboard-header';
import theme from 'src/e2e-redesign/theme';

import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

interface LayoutProps {
  children: ReactNode;
}

const LayoutContainer = styled(Box)<{}>(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
}));

const HeaderContainer = styled(Box)({
  backgroundColor: '#ffffff',
});

const E2eDashboardLayout: FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutContainer>
        <HeaderContainer>
          <DashboardHeader />
        </HeaderContainer>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </LayoutContainer>
    </ThemeProvider>
  );
};

export default E2eDashboardLayout;
