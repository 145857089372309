/* eslint-disable */
import React from 'react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyDecimalInput = ({
  maskOptions,
  inputRef,
  ...inputProps
}: {
  maskOptions: any;
  inputRef: any;
  inputProps: any;
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput
      ref={(ref) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={currencyMask}
      {...inputProps}
    />
  );
};

// ToDo - fix this Had to remove this for lender-closeout-form
// CurrencyInput.defaultProps = {
//   inputMode: 'numeric',
//   maskOptions: {},
// }

interface CurrencyDecimalInputI {
  inputmode: string;
  maskOptions: any;
  // maskOptions: PropTypes.shape({
  //   prefix: PropTypes.string,
  //   suffix: PropTypes.string,
  //   includeThousandsSeparator: PropTypes.boolean,
  //   thousandsSeparatorSymbol: PropTypes.string,
  //   allowDecimal: PropTypes.boolean,
  //   decimalSymbol: PropTypes.string,
  //   decimalLimit: PropTypes.string,
  //   requireDecimal: PropTypes.boolean,
  //   allowNegative: PropTypes.boolean,
  //   allowLeadingZeroes: PropTypes.boolean,
  //   integerLimit: PropTypes.number,
  // }),
}

export default CurrencyDecimalInput;
