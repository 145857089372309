import { useEffect, useState } from 'react';
import { Box, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { apiRequest } from 'src/api/request-handler';
import { API_GET_DASHBOARD_OPEN_OFFERS } from 'src/api/request-objects';
import { Seo } from 'src/components/seo';
import SingleLogo from 'src/e2e-redesign/components/SingleLogo';
import ThemedContainer from 'src/e2e-redesign/components/ThemedContainer';
import {
  DashboardApplicationInfoI,
  DashboardLenderLogo,
} from 'src/e2e-redesign/e2e-dashboard/views/dashboard.interface';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { ApplicationSubmissionStateDto, LenderOffersI } from 'src/interfaces/submissions.interfaces';
import type { Page as PageType } from 'src/types/page';

import DashboardIntro from '../components/dashboard-intro';
import OfferDefinitionItem from '../components/definition-list';

const E2eClientIndex: PageType = () => {
  const [applicantInfo, setApplicantInfo] = useState<DashboardApplicationInfoI[]>([]);
  const theme = useTheme();

  const handleGetOffers = async () => {
    const res: DashboardApplicationInfoI[] = await apiRequest(API_GET_DASHBOARD_OPEN_OFFERS);
    console.log({ res });
    if (!res) return;
    setApplicantInfo(res);
  };

  useEffect(() => {
    handleGetOffers();
  }, []);

  const CheckmarkSVG = () => (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#4CAF50" />
    </svg>
  );

  const renderOfferCard = (
    offer: LenderOffersI,
    submission: ApplicationSubmissionStateDto,
    lenderLogo: DashboardLenderLogo[],
    aaso: AASO,
  ) => {
    const isSelectedOffer = aaso?.user_selection?.selections?.find((selection) => {
      if (selection.fp_unique_id === offer.fp_unique_id) return true;
      return false;
    });
    const logo = lenderLogo.find((logo) => {
      if (logo.lender_id?.toString() == submission.lender_id?.toString()) {
        return logo;
      }
    })?.logo;
    return (
      <Grid key={offer?.fp_unique_id} xs={12} sm={6} md={4}>
        <Box
          sx={{
            marginBottom: '20px',
            border: '1px solid #E0E8F5',
            borderRadius: '15px',
            minHeight: '100%',
            padding: '15px',
            overflow: 'hidden',
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={6}>
              <SingleLogo image={{ src: logo || '/logo_full.png', alt: 'logo' }} />
            </Grid>
            <Grid xs={6} display="flex" justifyContent="flex-end">
              {isSelectedOffer && <CheckmarkSVG />}
            </Grid>
          </Grid>
          <dl style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
            {submission.lender_response &&
              OfferDefinitionItem({
                term: 'Provider Response',
                definition: submission.lender_response?.toUpperCase(),
              })}
            {offer?.approved_amount &&
              OfferDefinitionItem({
                term: 'Approved Amount',
                definition: `$${offer?.approved_amount}`,
              })}
            {offer?.offer_name &&
              OfferDefinitionItem({
                term: 'Offer Name',
                definition: offer?.offer_name,
              })}
            {offer?.rate &&
              OfferDefinitionItem({
                term: 'Rate',
                definition: offer?.rate,
              })}
            {offer?.apr &&
              OfferDefinitionItem({
                term: 'APR',
                definition: `${offer?.apr}%`,
              })}
            {offer?.term &&
              OfferDefinitionItem({
                term: 'Term',
                definition: offer?.term,
              })}
            {offer?.monthly_payment &&
              OfferDefinitionItem({
                term: 'Monthly Payment',
                definition: `$${offer?.monthly_payment}`,
              })}
            {offer?.application_id &&
              OfferDefinitionItem({
                term: 'Application ID',
                definition: offer?.application_id,
              })}
            {offer?.deferred_interest_period &&
              OfferDefinitionItem({
                term: 'Deferred Interest Period',
                definition: offer?.deferred_interest_period,
              })}
            {offer?.minimum_spend &&
              OfferDefinitionItem({
                term: 'Minimum Spend',
                definition: offer?.minimum_spend,
              })}
          </dl>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Seo title="Dashboard" />
      <ThemedContainer>
        <DashboardIntro />

        <Grid container spacing={2}>
          {applicantInfo.length > 0 ? (
            applicantInfo.map((application) => (
              <Grid container key={application?.aaso?.store_id} xs={12}>
                <Grid container key={`${application?.aaso?.store_id}-form-${application?.form?.sql_id}`} xs={12}>
                  <Grid xs={12}>
                    <Box
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                        padding: '15px 25px',
                        borderRadius: 1,
                        mb: 2,
                        width: '100%',
                      }}
                    >
                      <Typography variant="h6" component="p" sx={{ fontWeight: 'bold' }}>
                        {application?.aaso?.store_name} - To finalize your transaction or for more assistance, contact
                        your store associate. Reference ID: {application?.form?.sql_id}
                      </Typography>
                    </Box>
                  </Grid>
                  {application.form?.api_pipe_submissions &&
                    application.form.api_pipe_submissions.map((submission, formIndex) => (
                      <>
                        {submission?.lender_offers &&
                          submission.lender_offers.map((offer) =>
                            renderOfferCard(offer, submission, application.lenderLogos, application.aaso),
                          )}
                      </>
                    ))}
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography>No offers available.</Typography>
          )}
        </Grid>
      </ThemedContainer>
    </>
  );
};

export default E2eClientIndex;
