import { FC, ReactNode } from 'react';
import { Divider, Stack } from '@mui/material';

import { fpAuthColors } from '../../theme/fp-auth-colors';

interface FPAuthHelperLinksWrapperProps {
  children: ReactNode;
}

const FPAuthHelperLinksWrapper: FC<FPAuthHelperLinksWrapperProps> = ({ children }: FPAuthHelperLinksWrapperProps) => (
  <Stack
    direction={{ xs: 'column', md: 'row' }}
    justifyContent="center"
    spacing={{ xs: 2, md: 4 }}
    divider={<Divider orientation="vertical" flexItem sx={{ borderColor: fpAuthColors.fpBirdBlue }} />}
  >
    {children}
  </Stack>
);

export default FPAuthHelperLinksWrapper;
