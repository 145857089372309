import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { E2eBaseAasoCallsI } from 'src/api/query-logic/e2e-query-base-calls';
import {
  E2eChunkedDisclosuresI,
  e2eDiscLogic_chunkDisclosures,
  e2eDiscLogic_hasAcceptedAllDisclosures,
} from 'src/e2e-redesign/business-logic/e2e-disclosure-logic';
import {
  e2eRouting_routeTo404,
  E2eRoutingArgsI,
  E2eRoutingLogicI,
  handleE2eRoutingLogic,
  handleLenderDisclosureRouting,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes, ErrorRoutes, NewE2ERoutes } from 'src/enums/routes.enums';

export const e2eValidationLogic_applicationPage = (routingPackage: E2eRoutingLogicI): boolean => {
  // R:TODO E2E - handle verification of hte application page... is there anything else, YES - need to check FP disclosures were signed
  // have they signed all disclosures?
  const canAccess = false;
  const e2eStore = routingPackage.e2eStore;
  const aaso = e2eStore.aaso;
  const e2eForm = routingPackage?.e2eStore?.application_settings?.e2e?.e2e_forms?.find(
    (form) => form.id === aaso.e2e_form_id,
  );
  const e2ePath = e2eForm?.paths.find((p) => p.id === aaso.current_path_id);
  if (e2ePath) {
    const cd: E2eChunkedDisclosuresI = e2eDiscLogic_chunkDisclosures(
      routingPackage?.e2eStore?.disclosures,
      e2eStore.lenders,
      e2ePath,
    );

    const aasoAcceptedDisclosures = e2eStore.aaso?.disclosures;
    const hasAccepted = e2eDiscLogic_hasAcceptedAllDisclosures(cd, aasoAcceptedDisclosures);
    if (!hasAccepted) {
      // ensure the user has signed all of their disclosures
      const args: E2eRoutingArgsI = {
        currentStage: ApplicationStage.DISCLOSURE_LENDERS,
        currentE2eRoute: NewE2ERoutes.ProviderDisclosures,
      };
      handleLenderDisclosureRouting(routingPackage, args);
      return canAccess;
    }
    // R:TODO E2E P0 - VERY IMPORTANT add validation logic for here and the api backend
    /**
     * - if the user has already submitted an application to a lender, then they can't change their form
     * - so really we should just never all them to change their form
     * - This gets tricky on second flow paths, we'll need to:
     *    - we'll need to only provide the fields that need to be edited based on the lenders of the new flow
     */
    const cantEditFormAtTheseStages = [ApplicationStage.SUBMITTED_TO_LENDER, ApplicationStage.INITIAL_SELECTION];

    if (cantEditFormAtTheseStages.includes(aaso.current_stage)) {
      if (ApplicationStage.SUBMITTED_TO_LENDER === aaso.current_stage) {
        // Once an application has been submitted to a lender, they cannot go back to edit form
        const args: E2eRoutingArgsI = {
          currentStage: ApplicationStage.SUBMITTED_TO_LENDER,
          currentE2eRoute: NewE2ERoutes.ProviderResponses,
        };
        handleE2eRoutingLogic(routingPackage, args);
        return canAccess;
      } else {
        const args: E2eRoutingArgsI = {
          currentStage: ApplicationStage.INITIAL_SELECTION,
          currentE2eRoute: E2ERoutes.Dashboard,
        };
        handleE2eRoutingLogic(routingPackage, args);
        return canAccess;
      }
    }
    return true;
  }
  return false;
};

export const e2eValidationLogic_lenderResponsePage = (routingPackage: E2eRoutingLogicI, allData: E2eBaseAasoCallsI) => {
  // R:TODO E2E P0 - IMPORTANT - handle verification of the lender response page
  const aaso = routingPackage.e2eStore.aaso;
  const formValues = routingPackage.e2eStore.form_values;
  const navigate = routingPackage.navigate;

  useEffect(() => {
    if (allData.aasoIsFetched && allData.formValuesIsFetched) {
      if (!aaso || !aaso._id || isEmpty(formValues)) {
        // navigate(ErrorRoutes.NotFound);
        e2eRouting_routeTo404(navigate);
      }
    }
  }, [allData.aasoIsFetched, allData.formValuesIsFetched, allData.aasoData, allData.formValues]);

  /**
   * - verify form has been submitted
   * - verify all disclosures for fp and the lenders they are about to be submitted to have been completed
   *    - we need to check disclosure_signatures and NOT aaso.disclosures
   */
  // e2eValidationLogic_applicationPage(routingPackage);
  // handleLenderDisclosureRouting(routingPackage, args);
};
