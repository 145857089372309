import { e2eLogic_getValidE2eLenders } from 'src/e2e-redesign/business-logic/e2e-business-logic';
import { E2eLogicObjPathConditionsI } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_addUniquePastPath,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { e2eConfigLogic_updateAppConfigurationObject } from 'src/e2e-redesign/business-logic/e2e-path-logic';
import { E2eRoutingArgsI, handleE2eRoutingLogic } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ePathSubmissionTypesE } from 'src/enums/e2e.enums';
import { NewE2ERoutes } from 'src/enums/routes.enums';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';
import { E2eAppConfigurationsI, FormDocI, FormValuesI } from 'src/interfaces/e2e-state.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
  E2eFlowStageE,
} from 'src/interfaces/submissions.interfaces';

export const e2ePathCondLogic_routeToNewPath = (routingPackage: E2eLogicObjectsWithAasoParams, e2e_form_id: string) => {
  const updateAaso = routingPackage.e2eStore.updateAASO;
  routingPackage.e2eStore;
  const objs: E2eLogicObjPathConditionsI | undefined = routingPackage.e2eStore.app_configurations?.path_conditions;
  const aaso = routingPackage.e2eStore.aaso;
  if (objs && aaso) {
    const { paths, current_path_conditions, e2e_form } = objs;
    const newPath = paths.find((path) => path.id === current_path_conditions.if_path);
    const formPaths = e2e_form.paths;
    if (newPath && formPaths) {
      if (aaso.past_path_ids && aaso.current_path_id) {
        const updatedPaths = e2eLogicUtils_addUniquePastPath(
          aaso.past_path_ids,
          aaso.past_path_index,
          formPaths,
          aaso.current_path_id,
        );
        const newAaso = {
          current_stage: ApplicationStage.FLOW_STARTED,
          current_path_id: current_path_conditions.if_path,
          path_index: updatedPaths.new_path_index,
          past_path_ids: updatedPaths.past_path_ids,
          past_path_index: updatedPaths.past_path_index,
        };

        updateAaso({
          data: {
            ...newAaso,
          },
          sync: true,
        });
        const newRoutingPackage = {
          ...routingPackage,
          aasoContext: {
            aaso: {
              ...aaso,
              ...newAaso,
            },
          },
        };
        e2eConfigLogic_updateAppConfigurationObject(newRoutingPackage);
        const routingArgs: E2eRoutingArgsI = {
          currentStage: ApplicationStage.SELECTED_PATH,
          currentE2eRoute: NewE2ERoutes.ProviderResponses, // this doesn't matter
        };
        handleE2eRoutingLogic(newRoutingPackage, routingArgs);
      }
    }
  }
};

export const e2ePathCondLogic_routeBackToSelectApp = (
  routingPackage: E2eLogicObjectsWithAasoParams,
  e2e_form_id: string,
) => {
  const updateAaso = routingPackage.e2eStore.updateAASO;
  routingPackage.e2eStore;
  const objs: E2eLogicObjPathConditionsI | undefined = routingPackage.e2eStore.app_configurations?.path_conditions;
  const aaso = routingPackage.e2eStore.aaso;
  if (objs && aaso) {
    const newAaso = {
      current_stage: ApplicationStage.FLOW_STARTED,
      current_path_id: undefined,
    };

    updateAaso({
      data: {
        ...newAaso,
      },
      sync: true,
    });
    const newRoutingPackage = {
      ...routingPackage,
      aasoContext: {
        aaso: {
          ...aaso,
          ...newAaso,
        },
      },
    };

    const routingArgs: E2eRoutingArgsI = {
      currentStage: ApplicationStage.STARTED,
      currentE2eRoute: NewE2ERoutes.ProviderResponses, // this doesn't matter
    };
    if (routingPackage.e2eStore.aaso) {
      handleE2eRoutingLogic(newRoutingPackage, routingArgs, routingPackage.aasoContext.aaso);
    }
  }
};

export const e2ePathCondLogic_AllFailed = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
) => {
  // only check lenders of the current path
  const currentPath = appConfig.current_path;
  const pathConditions = appConfig?.path_conditions?.current_path_conditions;
  const validProviderResponses = [
    AppSubLenderResponseE.hold,
    AppSubLenderResponseE.soft_decline,
    AppSubLenderResponseE.invalid,
    AppSubLenderResponseE.pending,
    AppSubLenderResponseE.rejected,
    AppSubLenderResponseE.timed_out,
  ];
  const failedSubmissions: any[] = [];
  if (currentPath && pathConditions) {
    const currentPathLenders = currentPath.lenders;
    for (const sub of submissions) {
      const lenderId = Number(sub.lender_id);
      if (currentPathLenders.includes(lenderId)) {
        const subResponse = sub.lender_response;
        if (validProviderResponses.includes(subResponse)) {
          failedSubmissions.push(sub);
        }
      }
    }
    return failedSubmissions.length === currentPath.lenders.length;
  }
  return false;
};

export const e2ePathCondLogic_xFailed = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
): boolean => {
  // only check lenders of the current path
  const currentPath = appConfig.current_path;
  const pathConditions = appConfig?.path_conditions?.current_path_conditions;
  const validProviderResponses = [
    AppSubLenderResponseE.hold,
    AppSubLenderResponseE.soft_decline,
    AppSubLenderResponseE.invalid,
    AppSubLenderResponseE.pending,
    AppSubLenderResponseE.rejected,
    AppSubLenderResponseE.timed_out,
  ];
  const failedSubmissions: any[] = [];
  if (currentPath && pathConditions) {
    const percent = pathConditions.percent;
    if (percent) {
      const currentPathLenders = currentPath.lenders;
      for (const sub of submissions) {
        const lenderId = sub.lender_id;
        if (currentPathLenders.includes(lenderId)) {
          const subResponse = sub.lender_response;
          if (validProviderResponses.includes(subResponse)) {
            failedSubmissions.push(sub);
          }
        }
      }
      const p = Number(percent) / 100;
      const failedCount = failedSubmissions.length;
      const validCount = currentPathLenders.length;
      const failedPercent = failedCount / validCount;
      return failedPercent >= p;
    }
  }
  return false;
};

export const e2ePathCondLogic_fullAmountFirstLender = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
  formValues: FormValuesI,
): boolean => {
  // only check lenders of the current path
  const currentPath = appConfig.current_path;
  const pathConditions = appConfig?.path_conditions?.current_path_conditions;
  const estimatedPurchaseAmount = formValues.estimated_purchase_amount;
  if (currentPath && pathConditions && estimatedPurchaseAmount) {
    const num = Number(estimatedPurchaseAmount);
    const currentPathLenders = currentPath.lenders;
    for (const sub of submissions) {
      const offers = sub.lender_offers;
      const lenderId = sub.lender_id;
      if (currentPathLenders.includes(Number(lenderId))) {
        if (offers) {
          for (const offer of offers) {
            const offerValue = offer.approved_amount;
            if (offerValue) {
              const amount = Number(offerValue);
              if (amount >= num) {
                return false;
              }
            }
          }
        }
      }
    }
  }
  return true;
};

export const e2ePathCondLogic_stackFullAmountReached = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
  formValues: FormValuesI,
): boolean => {
  // only check lenders of the current path
  const currentPath = appConfig.current_path;
  const pathConditions = appConfig?.path_conditions?.current_path_conditions;
  const estimatedPurchaseAmount = formValues.estimated_purchase_amount;
  if (currentPath && pathConditions && estimatedPurchaseAmount) {
    const num = Number(estimatedPurchaseAmount);
    const currentPathLenders = currentPath.lenders;
    let count = 0;
    for (const sub of submissions) {
      const lenderId = sub.lender_id;
      if (currentPathLenders.includes(Number(lenderId))) {
        const offers = sub.lender_offers;
        if (offers) {
          for (const offer of offers) {
            const offerValue = offer.approved_amount;
            if (offerValue) {
              count += Number(offerValue);
            }
          }
        }
      }
    }
    const res = count >= num;
    return !res;
  }
  return true;
};

export const e2ePathCondLogic_areThereOtherPathConditionsAvailable = (
  appConfg: E2eAppConfigurationsI,
  aaso: AASO,
): boolean => {
  const e2eForm = appConfg.current_form;
  const currentPath = appConfg.current_path;
  const newPaths: E2eAppSettingsFormPathsI[] = [];
  if (e2eForm && e2eForm?.paths?.length > 0 && aaso) {
    const pastPathIds = aaso?.past_path_ids;
    if (pastPathIds && currentPath && !pastPathIds.includes(currentPath.id)) {
      pastPathIds.push(currentPath.id);
    }
    const paths = e2eForm.paths;
    if (pastPathIds && pastPathIds.length > 0) {
      for (const p of paths) {
        if (!pastPathIds?.includes(p.id)) {
          newPaths.push(p);
        }
      }
    }
  }
  return newPaths.length > 0;
};

export const e2ePathCondLogic_shouldShowContinueWaterfallPipe = (
  appConfg: E2eAppConfigurationsI,
  aaso: AASO,
  submissions: ApplicationSubmissionStateDto[],
): boolean => {
  const currentPath = appConfg.current_path;
  const kickOffDetails = aaso.kick_off_details;
  const submissionType = currentPath?.submission_type;
  if (submissionType === E2ePathSubmissionTypesE.waterfall) {
    const lenders = appConfg.lenders;
    const validE2eLenders = e2eLogic_getValidE2eLenders(lenders, currentPath, true);
    // first see if all lenders have a submission
    const lenderWithFinishedSubmissions = [];
    const finishedResponses = [AppSubStatusE.error, AppSubStatusE.lender_responded, AppSubStatusE.completed];
    for (const sub of submissions) {
      if (!validE2eLenders.includes(sub.lender_id)) {
        if (finishedResponses.includes(sub.submission_state)) {
          lenderWithFinishedSubmissions.push(Number(sub.lender_id));
        }
      }
    }
    if (lenderWithFinishedSubmissions.length === validE2eLenders.length) {
      return false;
    }
    const allLendersWhoHaveBeenRan = [];
    for (const ko of kickOffDetails) {
      if (ko.path_id === currentPath?.id) {
        ko.e2e_bull_jobs.forEach((j) => {
          allLendersWhoHaveBeenRan.push(j.lender_id);
        });
      }
    }
    return validE2eLenders.length !== allLendersWhoHaveBeenRan.length;
  }
  // not a waterfall
  return false;
};

const haveAllE2eLendersRan = (appConfg: E2eAppConfigurationsI, submissions: ApplicationSubmissionStateDto[]) => {
  const currentPath = appConfg.current_path;
  const lenders = appConfg.lenders;
  const validE2eLenders = e2eLogic_getValidE2eLenders(lenders, currentPath, true);
  // first see if all lenders have a submission
  const lenderWithFinishedSubmissions = [];
  for (const sub of submissions) {
    if (!validE2eLenders.includes(sub.lender_id)) {
      const foundLender = lenders.find((l) => Number(l.lender_id) === Number(sub.lender_id));
      // if init is finished
      if (foundLender) {
        const lenderFlow = foundLender.api_pipe_settings?.e2e_config?.flow;
        const e2eSub = sub?.e2e_flow_stages?.find((s) => s.stage === E2eFlowStageE.init);
        if (e2eSub && e2eSub.completed && sub.submission_state === AppSubStatusE.lender_responded) {
          lenderWithFinishedSubmissions.push(Number(sub.lender_id));
        }
      }
    }
  }
  return lenderWithFinishedSubmissions.length === validE2eLenders.length;
};
export const e2ePathCondLogic_shouldShowContinueWaterfallPipeConditional = (
  appConfg: E2eAppConfigurationsI,
  aaso: AASO,
  submissions: ApplicationSubmissionStateDto[],
  lenderResponses: AppSubLenderResponseE[],
  submsisionResponses: AppSubStatusE[],
): boolean => {
  const currentPath = appConfg.current_path;
  const kickOffDetails = aaso.kick_off_details;
  const submissionType = currentPath?.submission_type;
  if (submissionType === E2ePathSubmissionTypesE.waterfall) {
    const lenders = appConfg.lenders;
    const validE2eLenders = e2eLogic_getValidE2eLenders(lenders, currentPath, true);
    // first see if all lenders have a submission
    const haveAllE2eLendersFinished = haveAllE2eLendersRan(appConfg, submissions);
    if (haveAllE2eLendersFinished) {
      console.log('Call E2E lenders have finished');
      return false;
    }
    const lenderWithFinishedSubmissions = [];
    for (const sub of submissions) {
      if (!validE2eLenders.includes(sub.lender_id)) {
        if (submsisionResponses.includes(sub.submission_state) && lenderResponses.includes(sub.lender_response)) {
          lenderWithFinishedSubmissions.push(Number(sub.lender_id));
        }
      }
    }
    if (lenderWithFinishedSubmissions.length === validE2eLenders.length) {
      return false;
    }
    const allLendersWhoHaveBeenRan: any[] = [];
    for (const ko of kickOffDetails) {
      if (ko.path_id === currentPath?.id) {
        ko.e2e_bull_jobs.forEach((j) => {
          allLendersWhoHaveBeenRan.push(j.lender_id);
        });
      }
    }

    return validE2eLenders.length !== allLendersWhoHaveBeenRan.length;
  }
  // not a waterfall
  return false;
};
