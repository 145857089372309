import React from 'react';
import { Box } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

export default function MainHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  let backgroundImage;
  if (isMobile) {
    backgroundImage = `url('/assets/e2e-redesign/images/mobile-header.png')`;
  } else if (isTablet) {
    backgroundImage = `url('/assets/e2e-redesign/images/tablet-header.png')`;
  } else if (isDesktop) {
    backgroundImage = `url('/assets/e2e-redesign/images/desktop-header.png')`;
  }

  return (
    <Box
      component="header"
      sx={{
        backgroundColor: '#ffffff',
        backgroundImage: backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: { xs: '150px', sm: '200px' },
        display: 'flex',
        alignItems: 'flex-start', // Align items to the top
        justifyContent: 'flex-end', // Align items to the right
      }}
    >
      <Box
        component="img"
        src="/assets/e2e-redesign/images/white-logo.png"
        alt="FormPiper Logo"
        sx={{ 
          marginTop: '20px',
          marginRight: '20px',
          paddingBottom: '0',
          width: '121px', 
          height: 'auto',
          alignSelf: 'flex-start', // Ensure the logo is aligned to the top
        }}
      />
    </Box>
  );
}