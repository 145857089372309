import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { LenderDetails } from 'src/e2e-redesign/mock-data/MockLenderDetails';

interface ProviderDetailsAccordionProps {
  lender: LenderDetails;
  index: number;
  expanded: string | false;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const ProviderDetailsAccordion = ({ lender, index, expanded, handleChange }: ProviderDetailsAccordionProps) => {
  return (
    // Added return statement here
    <Accordion
      key={index}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        border: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100px',
              height: '100%',
            }}
          >
            <Box
              component="img"
              src={lender.image_path}
              alt={lender.name}
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: '100px',
                objectFit: 'contain',
              }}
            />
          </Box>
          <Typography sx={{ textDecoration: 'underline' }}>
            {expanded === `panel${index}` ? 'Hide details' : 'Expand details'}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {lender.product_details.map((detail) => (
          <div dangerouslySetInnerHTML={{ __html: detail.html }} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ProviderDetailsAccordion;
