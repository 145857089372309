import React from 'react';
import { FormHelperText, TextField } from '@mui/material';
import useE2eStore from 'src/e2e-store';

const StateSelector: React.FC<StateSelectorI> = (props: StateSelectorI): JSX.Element => {
  const locations = useE2eStore((state: any) => state.locations);
  const updatedStates = [...locations];
  updatedStates.unshift({ id: 0, state_name: '' });

  return (
    <div key={props.id}>
      <TextField
        key={props.id}
        fullWidth
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        select
        SelectProps={{ native: true }}
        value={props.value}
        variant="outlined"
        onBlur={props.onBlur}
        disabled={props.disabled}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
          },
        }}
      >
        {updatedStates.map((l) => (
          <option
            key={`${props.name}_${l.id}`}
            value={l.state_name}
            // selected={l.state_name === props.value ? true : false}
          >
            {l.state_name}
          </option>
        ))}
      </TextField>
      <FormHelperText style={{ color: 'red' }}>{props.helperText}</FormHelperText>
    </div>
  );
};

export interface StateSelectorI {
  value: string;
  onChange: any;
  label: string;
  id: string;
  name: string;
  helperText: any;
  variant: string;
  onBlur: any;
  placeholder?: string;
  error: any;
  disabled?: boolean;
}

export default StateSelector;
