/* eslint-disable */
import React from 'react';
import MaskedInput from 'react-text-mask';

const SsnMask = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      // placeholderChar={'\u2000'}
      // showMask
      guide={false}
    />
  );
};

export default SsnMask;
