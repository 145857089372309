import 'simplebar-react/dist/simplebar.min.css';

import React, { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Userback from '@userback/widget';
import { Helmet } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import ErrorBoundary from 'src/components/error-boundary';
import { WebSocketProvider } from 'src/hooks/use-socket';
import Navigation from 'src/layouts/e2e/navigation';
import ModalManager from 'src/modal-manager/modal-manager';

import { FPToaster } from './components/fp-toaster';
import { RTL } from './components/rtl';
import { SplashScreen } from './components/splash-screen';
import { AuthConsumer, AuthProvider } from './contexts/auth/jwt-context';
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context';
import { useNprogress } from './hooks/use-nprogress';
import { routes } from './routes';
import { createTheme } from './theme';

// Initialize Userback widget in specific environments (development or local) for user feedback collection
const ShowUserbackInTheseEnvs = ['development', 'local'];
if (ShowUserbackInTheseEnvs.includes(process.env.REACT_APP_ENVIRONMENT || '') && process.env.REACT_APP_USERBACK_TOKEN) {
  Userback(process.env.REACT_APP_USERBACK_TOKEN).then(() => {});
}

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     staleTime: 0,
  //     gcTime: 0, // does nothing as long as a query is still in use. It only kicks in as soon as the query becomes unused. After the time has passed, data will be "garbage collected" to avoid the cache from growing
  //   },
  // },
});

export const App: FC = () => {
  useNprogress();

  const element = useRoutes(routes);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <WebSocketProvider>
              <AuthConsumer>
                {(auth) => (
                  <SettingsProvider>
                    {auth?.user && auth?.user?.email === 'admin@snmd.com' && <Navigation />}
                    <SettingsConsumer>
                      {(settings) => {
                        const theme = createTheme({
                          colorPreset: settings.colorPreset,
                          contrast: settings.contrast,
                          direction: settings.direction,
                          paletteMode: settings.paletteMode,
                          responsiveFontSizes: settings.responsiveFontSizes,
                        });

                        // Prevent guards from redirecting
                        const showSlashScreen = !auth.isInitialized;

                        return (
                          <ThemeProvider theme={theme}>
                            <Helmet>
                              <meta name="color-scheme" content={settings.paletteMode} />
                              <meta name="theme-color" content={theme.palette.neutral[900]} />
                            </Helmet>
                            <RTL direction={settings.direction}>
                              <CssBaseline />
                              {showSlashScreen ? <SplashScreen /> : <>{element}</>}
                              <FPToaster />
                              <ModalManager />
                            </RTL>
                          </ThemeProvider>
                        );
                      }}
                    </SettingsConsumer>
                  </SettingsProvider>
                )}
              </AuthConsumer>
            </WebSocketProvider>
          </AuthProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
