/* eslint-disable */
import React from 'react';
import MaskedInput from 'react-text-mask';

const TimeInMonths = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={[/\d/, /1?([0-1])/]}
      // placeholderChar={'\u2000'}
      // showMask
      placeholder="0"
      guide={false}
    />
  );
};

export default TimeInMonths;
