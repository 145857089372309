/* eslint-disable */
import React from 'react';
import { IMaskInput } from 'react-imask';
import MaskedInput from 'react-text-mask';

const PhoneMask = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      // placeholderChar={'\u2000'}
      // showMask
      // placeholder="MM/DD/YYYY"
      guide={false}
    />
  );
};

export default PhoneMask;
