import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CleanButton } from 'src/components/clean/button';

interface AddressVerificationDialogProps {
  open: boolean;
  onClose: () => void;
  onProceed: () => void;
}

const AddressValidationModal: React.FC<AddressVerificationDialogProps> = ({ open, onClose, onProceed }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="address-verification-dialog"
    fullWidth
    sx={{
      backgroundColor: 'rgba(0, 0, 0, 0.1)', 
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
      borderRadius: '8px', 
    }}
  >
    <DialogTitle>Address Verification Failed</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The address you have entered cannot be verified. Would you like to proceed with the unverified address, or go
        back to edit it?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <CleanButton onClick={onClose} color="primary" variant="text">
        Edit Address
      </CleanButton>
      <CleanButton onClick={onProceed} color="primary" variant="contained">
        Proceed with Unverified Address
      </CleanButton>
    </DialogActions>
  </Dialog>
);

export default AddressValidationModal;