/*
  Created a custom checkbox component to fix an error with Formik's CheckboxWithLabel when used with arrays of checkboxes
  This component is used in the MultiProviderDisclosures.tsx file
*/

import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FieldProps } from 'formik';

const CustomCheckbox = ({ field, form, label }: FieldProps & { label: string }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          checked={Boolean(field.value)}
          onChange={() => form.setFieldValue(field.name, !field.value)}
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
