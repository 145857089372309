import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { API_E2E_GET_INIT_APP_APPROVAL_OFFERS } from 'src/api/e2e-request-objects';
import { apiRequest } from 'src/api/request-handler';
import ThankYouComponent from 'src/e2e-redesign/views/thank-you-page/thank-you-component';
import useE2eStore from 'src/e2e-store';

export default function ApprovalThankYouPage() {
  const { fp_unique_id, aaso_id, form_id } = useParams();
  const setThankyouPage = useE2eStore((state) => state.setThankYouPage);
  const typd = useE2eStore((state) => state.thank_you_page_details);
  const getOffers = async () => {
    if (!typd) {
      const res = await apiRequest(API_E2E_GET_INIT_APP_APPROVAL_OFFERS, {
        params: { fp_unique_id, aaso_id, form_id },
      });
      if (res.success) {
        setThankyouPage(res.data.thank_you_page_details);
      } else {
        // R:TODO E2E P1 - redirect them to 404
        toast.error(res.error);
      }
    }
  };
  useEffect(() => {
    getOffers();
  }, []);

  return <Container maxWidth="sm">{typd && <ThankYouComponent typd={typd} />}</Container>;
}
