// react
import React, { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import MainLayout from 'src/e2e-redesign/layout';
import NewE2eApplication from 'src/e2e-redesign/views/application/new-e2e-application';
import ApproveNewOffer from 'src/e2e-redesign/views/ApproveNewOffer/approve-new-offer';
// TODO: This is temporary for FOR-2751 E2E Redesign
import CommunicationNotice from 'src/e2e-redesign/views/CommunicationNotice/CommunicationNotice';
import CreateAccountNewCustomer from 'src/e2e-redesign/views/CreateAccountNewCustomer/CreateAccountNewCustomer';
import CurrentCustomerAcceptChanges from 'src/e2e-redesign/views/CurrentCustomerAcceptChanges/CurrentCustomerAcceptChanges';
import LetsGetStarted from 'src/e2e-redesign/views/get-started/LetsGetStarted';
import LenderResponse from 'src/e2e-redesign/views/LenderResponses/LenderResponse';
import NewLenderResponse from 'src/e2e-redesign/views/LenderResponses/new-lender-response';
import NewLogin from 'src/e2e-redesign/views/NewLogin/NewLogin';
import ProviderDisclosures from 'src/e2e-redesign/views/ProviderDisclosures/ProviderDisclosures';
import SelectApplicationType from 'src/e2e-redesign/views/SelectedApplicationType/SelectApplicationType';
import ApprovalThankYouPage from 'src/e2e-redesign/views/thank-you-page/approval-thank-you-page';
import E2eThankYouPage from 'src/e2e-redesign/views/thank-you-page/e2e-thank-you-page';
import VerifyCurrentCustomers from 'src/e2e-redesign/views/VerifyCurrentCustomers/VerifyCurrentCustomers';
import Welcome from 'src/e2e-redesign/views/Welcome/Welcome';
import { AuthRoutes, E2eApprovalRoutes, E2ERoutes, ErrorRoutes, NewE2ERoutes } from 'src/enums/routes.enums';
import { AuthGuard } from 'src/guards/auth-guard';
import { DashboardAuthGuard } from 'src/guards/dashboard-auth-guard';
// guards
import { GuestGuard } from 'src/guards/guest-guard';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { AASOProvider } from 'src/hooks/use-aaso';
// layouts
import FPAuthLayout from 'src/layouts/auth/fp-auth-layout';
// pages
import ErrorPage from 'src/pages/error';
import { Issuer } from 'src/types/auth';

// E2E Dashboard
import E2eDashboardLayout from './e2e-redesign/e2e-dashboard/layout/e2e-dashboard-layout';
import E2eClientIndex from './e2e-redesign/e2e-dashboard/views/e2e-client-index';
import E2EOffersAccepted from './e2e-redesign/e2e-dashboard/views/e2e-offers-accepted';

// const FPAccountPage = lazy(() => import('src/pages/dashboard/fp-account'));
const FPAccountPage = lazy(() => import('./e2e-redesign/e2e-dashboard/views/e2e-client-settings'));
const FPEnrollView = lazy(() => import('src/pages/auth/jwt/fp-enroll'));
const FPAuthVerifyCode = lazy(() => import('src/pages/auth/jwt/fp-verify-phone-code'));
const FPContactMe = lazy(() => import('src/pages/auth/jwt/fp-contact-me'));
const FPAuthVerifyAddress = lazy(() => import('src/pages/auth/jwt/fp-verify-address'));
const FPCreatePassword = lazy(() => import('src/pages/auth/jwt/fp-create-password'));
const FPEnrollmentSuccess = lazy(() => import('src/pages/auth/jwt/fp-enrollment-success'));
const FPNewPasswordSuccess = lazy(() => import('src/pages/auth/jwt/fp-new-password-success'));
const FPResetPassword = lazy(() => import('src/pages/auth/jwt/fp-reset-password'));
const FPAuthResetPassword = lazy(() => import('src/pages/auth/jwt/fp-auth-reset-password'));

const newPublicE2ERoutes: RouteObject[] = [
  {
    path: '/:store_name/:store_uuid/:e2e_form_id',
    element: (
      <MainLayout>
        <Suspense>
          <AASOProvider>
            <Outlet />
          </AASOProvider>
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: NewE2ERoutes.LandingPage, element: <Welcome /> },
      { path: NewE2ERoutes.CreateAccount, element: <CreateAccountNewCustomer /> },
      { path: NewE2ERoutes.CommunicationNotices, element: <CommunicationNotice /> },
      { path: NewE2ERoutes.OTPVerification, element: <VerifyCurrentCustomers /> },
      { path: NewE2ERoutes.CustomerAcceptNewFpDisclosures, element: <CurrentCustomerAcceptChanges /> },
    ],
  },
];

const otpPublicRoute: RouteObject[] = [
  {
    path: '/applicant-approve-offers/:store_name/:fp_unique_id/:aaso_id/:store_uuid/:e2e_form_id/:form_id',
    // path: '/applicant-approve-offers/:store_name/:fp_unique_id',
    element: (
      <MainLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { index: true, element: <ApproveNewOffer /> },
      { path: E2eApprovalRoutes.ThankYou, element: <ApprovalThankYouPage /> },
    ],
  },
];

const newE2eRoutes: RouteObject[] = [
  {
    path: '/:store_name/:store_uuid/:e2e_form_id/:aaso_id',
    element: (
      <MainLayout>
        <AuthGuard>
          <AASOProvider>
            <Outlet />
          </AASOProvider>
        </AuthGuard>
      </MainLayout>
    ),
    children: [
      { path: NewE2ERoutes.CustomerAcceptNewFpDisclosures, element: <CurrentCustomerAcceptChanges /> },
      { path: NewE2ERoutes.SelectApplicationType, element: <SelectApplicationType /> },
      { path: NewE2ERoutes.GetStarted, element: <LetsGetStarted /> },
      { path: NewE2ERoutes.ProviderDisclosures, element: <ProviderDisclosures /> },
      { path: NewE2ERoutes.Application, element: <NewE2eApplication /> },
      { path: NewE2ERoutes.ProviderResponses, element: <NewLenderResponse /> },
      { path: NewE2ERoutes.ThankYou, element: <E2eThankYouPage /> },
    ],
  },
];

export const routes: RouteObject[] = [
  {
    element: (
      <IssuerGuard issuer={Issuer.JWT}>
        <GuestGuard>
          <FPAuthLayout>
            <AASOProvider>
              <Outlet />
            </AASOProvider>
          </FPAuthLayout>
        </GuestGuard>
      </IssuerGuard>
    ),
    children: [
      { index: true, element: <NewLogin /> },
      { path: AuthRoutes.Enroll, element: <FPEnrollView /> },
      { path: AuthRoutes.ContactMe, element: <FPContactMe /> },
      { path: AuthRoutes.VerifyCode, element: <FPAuthVerifyCode /> },
      { path: AuthRoutes.VerifyAddress, element: <FPAuthVerifyAddress /> },
      { path: AuthRoutes.CreatePassword, element: <FPCreatePassword /> },
      { path: AuthRoutes.EnrollmentSuccess, element: <FPEnrollmentSuccess /> },
      { path: AuthRoutes.ResetPassword, element: <FPResetPassword /> },
      { path: AuthRoutes.AuthResetPassword, element: <FPAuthResetPassword /> },
      { path: AuthRoutes.NewPasswordSuccess, element: <FPNewPasswordSuccess /> },
    ],
  },

  {
    path: '/dashboard',
    element: (
      <DashboardAuthGuard>
        <E2eDashboardLayout>
          <AASOProvider>
            <Outlet />
          </AASOProvider>
        </E2eDashboardLayout>
      </DashboardAuthGuard>
    ),
    children: [
      { index: true, element: <E2eClientIndex /> },
      { path: E2ERoutes.Account, element: <FPAccountPage /> },
      // { path: E2ERoutes.OffersAccepted, element: <E2EOffersAccepted /> },
    ],
  },
  {
    path: ':store_name/:store_uuid/:aaso_id/dashboard',
    element: (
      <DashboardAuthGuard>
        <E2eDashboardLayout>
          <AASOProvider>
            <E2eClientIndex />
          </AASOProvider>
        </E2eDashboardLayout>
      </DashboardAuthGuard>
    ),
  },
  ...otpPublicRoute,
  { path: ErrorRoutes.Unauthorized, element: <ErrorPage errorCode="401" /> },
  { path: ErrorRoutes.NotFound, element: <ErrorPage errorCode="404" /> },
  { path: ErrorRoutes.ServerError, element: <ErrorPage errorCode="500" /> },
  { path: ErrorRoutes.CatchAll, element: <ErrorPage errorCode="404" /> },
  ...newPublicE2ERoutes,
  ...newE2eRoutes,
];
