import * as Yup from 'yup';

const validateEmail = (email: any) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  return regex.test(email);
};
export const validationSchema = Yup.object({
  first_name: Yup.string().required('First Name is required').min(2).max(50),
  last_name: Yup.string().required('Last Name is required').min(2).max(50),
  email: Yup.string()
    .email('Invalid email format')
    .required('Mail is required')
    .test(
      'is-valid',
      (message) => `${message.path} is invalid`,
      (value) => (value ? validateEmail(value) : new Yup.ValidationError('Invalid value')),
    ),
  mobile_phone: Yup.string()
    .matches(/^\(\d{3}\)\s?\d{3}-\d{4}$/, 'Must be only digits')
    .min(10)
    .max(15)
    .required('Mobile Phone is required'),
  password: Yup.string()
    .max(255)
    .required('Password is required')
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{13,}$/,
      'Must Contain 13 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirm_password: Yup.string()
    .max(255)
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .test('password-match', 'Passwords must match', function (value) {
      return value === this.parent.password;
    }),
  fp_myfp_tc: Yup.object().shape({
    accepted: Yup.boolean().oneOf([true], 'You must accept the Terms & Conditions'),
  }),
  fp_customer_notice: Yup.object().shape({
    accepted: Yup.boolean().oneOf([true], 'You must accept the Customer Notice'),
  }),
  fp_privacy: Yup.object().shape({
    accepted: Yup.boolean().oneOf([true], 'You must accept the Privacy Policy'),
  }),
});
