import { Box, Container, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { RouterLink } from 'src/components/router-link';
import { Seo } from 'src/components/seo';
import { fpAuthColors } from 'src/theme/fp-auth-colors';
import type { Page as PageType } from 'src/types/page';

const styles = {
  mainBox: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    py: '80px',
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'center',
    mb: 6,
  },
  imgBox: {
    height: 'auto',
    maxWidth: '100%',
    width: 400,
  },
  linkBox: {
    display: 'flex',
    justifyContent: 'center',
    mt: 6,
  },
};

interface ErrorPageProps {
  errorCode: '401' | '404' | '500';
  title: string;
  description: string;
  imageUrl: string;
}

const errorPageContent = {
  '400': {
    title: 'Bad Request',
    description: "We're sorry, but the request you made was invalid. Please try again.",
    imageUrl: '/assets/errors/error-404.png',
  },
  '401': {
    title: 'Authorization Required',
    description:
      "It seems like you've reached a restricted area that requires authentication. Please double-check your login credentials and ensure that you have proper authorization to access this page.",
    imageUrl: '/assets/errors/error-401.png',
  },
  '404': {
    title: 'Page Not Found',
    description: "Looks like you've arrived here by mistake. Let's get you back on track.",
    imageUrl: '/assets/errors/error-404.png',
  },
  '500': {
    title: 'Internal Server Error',
    description:
      "Something went wrong on our end. Don't worry, it's not you — it's us. Please try refreshing the page or returning back home.",
    imageUrl: '/assets/errors/error-500.png',
  },
};

const ErrorPage: PageType = ({ errorCode }: ErrorPageProps) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { state } = useLocation();

  const { title, description, imageUrl } = errorPageContent[errorCode];
  return (
    <>
      <Seo title={`Error: ${title}`} />
      <Box component="main" sx={styles.mainBox}>
        <Container maxWidth="lg">
          <Box sx={styles.containerBox}>
            <Box alt={title} component="img" src={imageUrl} sx={styles.imgBox} />
          </Box>
          <Typography align="center" variant={mdUp ? 'h1' : 'h4'} color={fpAuthColors.fpDarkBlue}>
            {errorCode}: {title}
          </Typography>
          <Typography align="center" color="text.secondary" variant={'subtitle1'} sx={{ mt: 1 }}>
            {description}
          </Typography>
          {state?.error && (
            <Typography align="center" color="text.secondary" variant={'subtitle1'} sx={{ mt: 1 }}>
              {state.error}
            </Typography>
          )}
          <Box sx={styles.linkBox}>
            <Link component={RouterLink} href={'/'} underline="hover" color={fpAuthColors.fpBirdBlue}>
              Back to Home
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ErrorPage;
