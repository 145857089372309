/* eslint-disable */
import React from 'react';
import { IMaskInput } from 'react-imask';
import MaskedInput from 'react-text-mask';
import { alphaNumericWithSpacesOnlyRegex } from 'src/pages/application/dynamic-form/validation-creator';

const AlphaNumeric = (props: any) => {
  const { inputRef, ...other } = props;
  const mask = [...Array(500)].map((i) => alphaNumericWithSpacesOnlyRegex);

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (inputRef) {
          inputRef(ref ? ref.inputElement : null);
        }
      }}
      mask={mask}
      // placeholderChar={'\u2000'}
      // showMask
      guide={false}
    />
  );
};

export default AlphaNumeric;
