import {
  API_E2E_GET_CONTINUE_WATERFALL_PIPING,
  API_E2E_GET_KICKOFF,
  API_E2E_GET_PROVIDER_STATUS,
  API_E2E_PATCH_AASO_OFFER_SELECTION,
  API_E2E_PATCH_NEW_OFFER_SELECTED,
} from 'src/api/e2e-request-objects';
import { apiRequest } from 'src/api/request-handler';
import { DevSubmissionEnums } from 'src/components/e2e/lender-status-dev-menu/lender-status-dev-menu';
import { e2eLogic_getValidE2eLenders } from 'src/e2e-redesign/business-logic/e2e-business-logic';
import { e2eHandleErrors } from 'src/e2e-redesign/business-logic/e2e-handle-errors';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  e2ePathCondLogic_AllFailed,
  e2ePathCondLogic_areThereOtherPathConditionsAvailable,
  e2ePathCondLogic_fullAmountFirstLender,
  e2ePathCondLogic_shouldShowContinueWaterfallPipe,
  e2ePathCondLogic_shouldShowContinueWaterfallPipeConditional,
  e2ePathCondLogic_stackFullAmountReached,
  e2ePathCondLogic_xFailed,
} from 'src/e2e-redesign/business-logic/e2e-path-conditions-logic';
import { FranchiseSettingsI } from 'src/e2e-redesign/interfaces/franchise-settings.interface';
import { LaaSettingsI } from 'src/e2e-redesign/interfaces/laa-settings.interface';
import { LenderSubmissionStatusE } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ePathSubmissionTypesE } from 'src/enums/e2e.enums';
import { E2eClientErrorAggregate } from 'src/errors/error.interfaces';
import { AASO, AasoKickOffDetailsI, AasoUserSelectionDetails } from 'src/interfaces/aaso.interfaces';
import { E2ePathConditionsE, E2eWaterfallConditionsE } from 'src/interfaces/application-settings.interfaces';
import {
  E2eAppConfigurationsI,
  E2eProcessApplicationJobI,
  FormDocI,
  FormValuesI,
} from 'src/interfaces/e2e-state.interfaces';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import { E2eOnConditionsI, E2eRedirectLendersI } from 'src/interfaces/store-settings.interfaces';
import {
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  AppSubStatusE,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';
import { ApiError } from 'src/utils/error';

export const e2eSubLogic_createOfferSelected = (
  obj: Partial<AasoUserSelectionDetails>,
): Partial<AasoUserSelectionDetails> => {
  // R:TODO E2E P1 - make sure anywhere we're capturing IP address that it's coming from server
  /**
   * NOTE:
   * - ip and time will ALWAYS be gotten from the api
   */
  return {
    // lender_id,
    // time: new Date(),// We should not be doing this here
    // time_unix: new Date().getTime(), // We should not be doing this here - time should come from server
    // offer_id,
    ...obj,
    // ip: - IP
  };
};

export const e2eSubLogic_isLenderRedirect = (appConfig: E2eAppConfigurationsI): E2eRedirectLendersI[] => {
  const lenderE2e = appConfig?.lender_e2e_settings;
  const currentPathLenders = appConfig?.current_path?.lenders;
  // R:TODO E2E REVISE - verify that multiple lender redirects work (we need more redirects to test)
  const redirectLenders: E2eRedirectLendersI[] = [];
  if (lenderE2e && currentPathLenders) {
    for (const l of currentPathLenders) {
      if (lenderE2e[l]) {
        const lenderConfig = lenderE2e[l];
        // R:TODO E2E REVISE - we need to handle lender_updated_details, which replaces the name of hte lender
        /**
         * Users can chance the name of their lenders in the acl store settings object
         * and we need to account for that, but later
         */
        // const franchiseConfig = lenderConfig?.franchise_e2e_configuration;
        // const rolesConfig = lenderConfig?.roles_e2e_configuration;
        const defaultConfigs = lenderConfig?.default_configs;
        if (defaultConfigs?.e2e_config?.after_form_submission_redirect?.enabled) {
          if (defaultConfigs.lender_object_e2e_config.after_form_submission_redirect) {
            redirectLenders.push({
              lender_id: Number(l),
              // this comes user store_settings
              e2e_config: defaultConfigs.e2e_config.after_form_submission_redirect,
              // this comes from the lender object which - not sure if needed
              lender_config: defaultConfigs.lender_object_e2e_config.after_form_submission_redirect,
            });
          }
        }
      }
    }
  }
  return redirectLenders;
};

export const e2eSubLogic_handleKickoff = async (
  pollForSubmission: any,
  handleError: any,
  params: any,
  dev_submission?: DevSubmissionEnums,
): Promise<Partial<AasoKickOffDetailsI> | void> => {
  const { aaso_id, e2e_form_id, store_uuid } = params;
  const obj: any = { params: { aaso_id, e2e_form_id, store_uuid } };
  if (dev_submission) {
    obj.query = { dev_submission };
  }
  try {
    // await apiRequest(API_GET_E2E_KICKOFF, obj);
    try {
      const res: Partial<AasoKickOffDetailsI> = await apiRequest(API_E2E_GET_KICKOFF, obj);

      // R:TODO E2E P0 - add this for prod only - need to improve the function to check that all e2eLenders are done
      pollForSubmission(res);
      return res;
    } catch (e) {
      if (e instanceof Error && e.message === 'API_ERROR') {
        const apiError = e as ApiError;
        const body = apiError.body as E2eClientErrorAggregate;
        if (body?.errors) {
          e2eHandleErrors(body);
        }
      }
      throw e;
    }
  } catch (e) {
    // handleError(e);
    // throw new Error('Failed to kick off');
  }
};
export const e2eSubLogic_continuePipingWaterfall = async (
  pollForSubmission: any,
  handleError: any,
  params: any,
  dev_submission?: DevSubmissionEnums,
): Promise<Partial<AasoKickOffDetailsI>> => {
  const { aaso_id, e2e_form_id, store_uuid } = params;
  const obj: any = { params: { aaso_id, e2e_form_id, store_uuid } };
  if (dev_submission) {
    obj.query = { dev_submission };
  }
  try {
    // await apiRequest(API_GET_E2E_KICKOFF, obj);
    try {
      const res: Partial<AasoKickOffDetailsI> = await apiRequest(API_E2E_GET_CONTINUE_WATERFALL_PIPING, obj);

      // R:TODO E2E P0 - add this for prod only - need to improve the function to check that all e2eLenders are done
      pollForSubmission(res);
      return res;
    } catch (e) {
      if (e instanceof Error && e.message === 'API_ERROR') {
        const apiError = e as ApiError;
        const body = apiError.body as E2eClientErrorAggregate;
        if (body?.errors) {
          e2eHandleErrors(body);
        }
      }
      throw e;
    }
  } catch (e) {
    handleError(e);
    throw new Error('Failed to kick off');
  }
};

export const e2eSubLogic_handleAcceptAndProceed = async (
  offerDetails: LenderOffersI,
  l: LendersI,
  params: any,
  updateAaso: any,
) => {
  const { aaso_id, e2e_form_id, store_uuid } = params;
  // so we need to save to the db that they selected this lender
  if (offerDetails) {
    const selectionObj = e2eSubLogic_createOfferSelected({
      offer_id: offerDetails.offer_id,
      application_id: offerDetails.application_id,
      pre_qualification_id: offerDetails.pre_qualification_id,
      token: offerDetails.token,
      fp_unique_id: offerDetails.fp_unique_id,
      lender_id: l.lender_id,
      offer_object: offerDetails,
    });
    const res = await apiRequest(API_E2E_PATCH_AASO_OFFER_SELECTION, {
      params: { store_uuid, aaso_id, e2e_form_id },
      body: selectionObj,
    });
    if (res) {
      updateAaso({
        data: {
          current_stage: ApplicationStage.INITIAL_SELECTION,
        },
        sync: true,
      });
    } else {
      throw new Error('Failed to save user selection');
    }
  }
  // redirect them to the dashboard
};

export const e2eSubLogic_handleAcceptNewOffer = async (offerDetails: LenderOffersI, l: LendersI, params: any) => {
  // so we need to save to the db that they selected this lender
  if (offerDetails) {
    const selectionObj = e2eSubLogic_createOfferSelected({
      offer_id: offerDetails.offer_id,
      application_id: offerDetails.application_id,
      pre_qualification_id: offerDetails.pre_qualification_id,
      token: offerDetails.token,
      fp_unique_id: offerDetails.fp_unique_id,
      lender_id: l.lender_id,
      offer_object: offerDetails,
    });
    const { fp_unique_id, form_id, aaso_id } = params;
    return await apiRequest(API_E2E_PATCH_NEW_OFFER_SELECTED, {
      params: { fp_unique_id, form_id, aaso_id },
      body: selectionObj,
    });
  }
  // redirect them to the dashboard
};
export const e2eSubLogic_checkIfAutoApprovalLenderExists = (lenders: LendersI[]): boolean => {
  let autoApprovalLenderExists = false;
  for (const l of lenders) {
    if (l?.api_pipe_settings?.e2e_config?.auto_approval?.enabled) {
      autoApprovalLenderExists = true;
      break;
    }
  }
  return autoApprovalLenderExists;
};
export const e2eSubLogic_haveReceivedOneOrMultipleOffers = (
  submissions: ApplicationSubmissionStateDto[],
  lenders: LendersI[],
): LenderSubmissionStatusE => {
  /**
   * - check to see if they received one or multiple offers
   *    - this will change Congratulations on your offer or offers
   */
  let count = 0;
  const autoApprovalsExist = e2eSubLogic_checkIfAutoApprovalLenderExists(lenders);
  if (autoApprovalsExist) {
    count += 1;
  }
  if (submissions) {
    for (const sub of submissions) {
      const validProviderResponses = [
        AppSubLenderResponseE.pre_approved,
        AppSubLenderResponseE.approved,
        AppSubLenderResponseE.e2e_pre_approved,
      ];
      const providerResponse = sub.lender_response;
      if (validProviderResponses.includes(providerResponse)) {
        count++;
      }
    }
  }
  if (count === 1) {
    return LenderSubmissionStatusE.single_offer;
  } else if (count > 1) {
    return LenderSubmissionStatusE.multiple_offers;
  } else {
    return LenderSubmissionStatusE.no_offers;
  }
};

export const e2eSubLogic_haveBeenDeclinedByAllE2eLenders = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
): boolean => {
  /**
   * - Check to see if they were declined for all offers for all lenders (all paths exhausted)
   * - this checks to see if all valid e2e lenders have declined or erroed (across all paths)
   */
  // get list of all lenders
  const currentForm = appConfig.current_form;
  const allValidE2eLenders: number[] = [];
  if (currentForm) {
    for (const path of currentForm.paths) {
      const validE2eLenders = e2eLogic_getValidE2eLenders(appConfig.lenders, path, true);
      allValidE2eLenders.push(...validE2eLenders);
    }
    // get list of all valid e2e lenders
    // check if submissions have been had for all lenders
    let submittedCount = 0;
    for (const lenderId of allValidE2eLenders) {
      const subFound = submissions.find((sub) => sub.lender_id === lenderId);
      if (subFound) {
        const validProviderResponses = [
          AppSubLenderResponseE.hold,
          AppSubLenderResponseE.soft_decline,
          AppSubLenderResponseE.invalid,
          AppSubLenderResponseE.pending,
          AppSubLenderResponseE.rejected,
          AppSubLenderResponseE.timed_out,
        ];
        const providerResponse = subFound.lender_response;
        if (validProviderResponses.includes(providerResponse)) {
          submittedCount++;
        }
      }
    }
    return submittedCount === allValidE2eLenders.length;
  }
  return false;
};

export const e2eSubLogic_shouldShowAlternativePath = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
  formDoc: FormDocI,
  aaso: AASO,
): boolean => {
  /**
   * - Check to see if we should show NotHappyPath based on conditions:
   *    - 'all_failed'
   *    - 'x_failed'
   *    - 'full_amount_of_money_first_lender'
   *    - 'stack_full_amount_of_money_reached'
   */
  const formValues = formDoc.data;
  const currentPathCondition = appConfig?.path_conditions?.current_path_conditions;
  if (currentPathCondition) {
    const condition = currentPathCondition.condition;
    switch (condition) {
      case E2ePathConditionsE.all_failed:
        return e2ePathCondLogic_AllFailed(submissions, appConfig);
      case E2ePathConditionsE.x_failed:
        return e2ePathCondLogic_xFailed(submissions, appConfig);
      case E2ePathConditionsE.full_amount_of_money_first_lender:
        return e2ePathCondLogic_fullAmountFirstLender(submissions, appConfig, formValues);
      case E2ePathConditionsE.stack_full_amount_of_money_reached:
        return e2ePathCondLogic_stackFullAmountReached(submissions, appConfig, formValues);
      case E2ePathConditionsE.always_show:
        return true;
    }
  } else {
    // no conditions exist
    return false;
  }
};
export const e2eSubLogic_haveResponsesComeBackFromAllCurrentPathE2eLenders = (
  submissions: ApplicationSubmissionStateDto[],
  appConfig: E2eAppConfigurationsI,
  form_values: FormDocI,
  forceSubmission: boolean,
): boolean => {
  // R:TODO E2E P1 - update this to account for waterfall
  /**
   * - this does not take into account waterfalls where it might stop after one lender assuming conditions are met
   */
  const fv = form_values.data;
  const estimatedPurchaseAmount = fv.estimated_purchase_amount;
  const currentPath = appConfig.current_path;
  const finishedLenders = [];
  const validE2eLenders = e2eLogic_getValidE2eLenders(appConfig.lenders, currentPath, true);
  try {
    if (currentPath) {
      if (!forceSubmission) {
        const waterFallCondition = currentPath.waterfall_conditions;
        // const localCheck = checkIfCurrentPathWaterfallConditionsHaveBeenMet(
        //   appConfig,
        //   submissions,
        //   estimatedPurchaseAmount,
        // );
        const waterfallConditionObj = getWaterfallAppResultConditions(appConfig, submissions, estimatedPurchaseAmount);
        let localCheck = false;
        if (waterFallCondition) {
          if (Array.isArray(waterFallCondition)) {
            waterFallCondition.forEach((wc) => {
              if (waterfallConditionObj[wc]) {
                localCheck = true;
              }
            });
          } else {
            localCheck = waterfallConditionObj[waterFallCondition];
          }
        }

        if (localCheck) return true;
      }
      const currentPathLenders = currentPath.lenders;
      for (const sub of submissions) {
        const lenderId = sub.lender_id;
        if (currentPathLenders.includes(Number(lenderId))) {
          if (sub.lender_response !== AppSubLenderResponseE.nr) {
            finishedLenders.push(lenderId);
          }
        }
      }
      return finishedLenders.length === validE2eLenders.length;
    }
  } catch (e) {
    return true;
  }

  return false;
};

export interface AppResultsWaterfallConditionsI {
  // all_failed: boolean;
  // x_failed: boolean;
  // full_amount_of_money_first_lender: boolean;
  // stack_full_amount_of_money_reached: boolean;
  stop_at_first: boolean;
  stop_in_aggregate: boolean;
  stop_on_pending_w_option_to_continue: boolean;
  stop_on_pending: boolean;
  stop_on_pending_w_option_to_continue_next_path: boolean;
}

const getWaterfallAppResultConditions = (
  appConfig: E2eAppConfigurationsI,
  submissions: ApplicationSubmissionStateDto[],
  estimated_purchase_amount: number,
): AppResultsWaterfallConditionsI => {
  const currentPath = appConfig.current_path;
  const allSubmissions = submissions;
  const conditions: AppResultsWaterfallConditionsI = {
    stop_at_first: false,
    stop_in_aggregate: false,
    stop_on_pending_w_option_to_continue: false,
    stop_on_pending: false,
    stop_on_pending_w_option_to_continue_next_path: false,
  };
  if (currentPath) {
    const submissionType = currentPath.submission_type;

    let estimatedPurchaseAmount = estimated_purchase_amount;
    if (estimatedPurchaseAmount) {
      estimatedPurchaseAmount = Number(estimatedPurchaseAmount);
    }
    if (
      estimatedPurchaseAmount &&
      estimatedPurchaseAmount > 0 &&
      // waterFallCondition &&
      currentPath &&
      allSubmissions &&
      allSubmissions.length > 0 &&
      submissionType === E2ePathSubmissionTypesE.waterfall
    ) {
      const validE2eLenders = e2eLogic_getValidE2eLenders(appConfig.lenders, currentPath, true);
      let aggregateCount = 0;

      for (const sub of allSubmissions) {
        if (sub.lender_offers && sub.lender_offers.length > 0) {
          const found = validE2eLenders.find((l) => Number(l) === Number(sub.lender_id));
          if (found) {
            conditions.stop_at_first = handleWaterfallConditionStopAtFirst2(sub.lender_offers, estimatedPurchaseAmount);
            const highest = handleWaterfallConditionStopInAggregate2(
              sub.lender_offers,
              estimatedPurchaseAmount,
              aggregateCount,
            );
            aggregateCount += highest;
            if (aggregateCount + highest >= estimatedPurchaseAmount) {
              conditions.stop_in_aggregate = true;
            }
            conditions.stop_on_pending_w_option_to_continue = handleStopOnPending2(sub);
          }
        }
      }
    }
  }
  return conditions;
};
const checkIfCurrentPathWaterfallConditionsHaveBeenMet = (
  appConfig: E2eAppConfigurationsI,
  submissions: ApplicationSubmissionStateDto[],
  estimated_purchase_amount: number,
) => {
  const currentPath = appConfig.current_path;
  const allSubmissions = submissions;
  if (currentPath) {
    const waterFallCondition = currentPath.waterfall_conditions;
    const submissionType = currentPath.submission_type;

    let estimatedPurchaseAmount = estimated_purchase_amount;
    if (estimatedPurchaseAmount) {
      estimatedPurchaseAmount = Number(estimatedPurchaseAmount);
    }
    if (
      estimatedPurchaseAmount &&
      estimatedPurchaseAmount > 0 &&
      waterFallCondition &&
      currentPath &&
      allSubmissions &&
      allSubmissions.length > 0 &&
      submissionType === E2ePathSubmissionTypesE.waterfall
    ) {
      try {
        const validE2eLenders = e2eLogic_getValidE2eLenders(appConfig.lenders, currentPath, true);
        let aggregateCount = 0;

        for (const sub of allSubmissions) {
          if (sub.lender_offers && sub.lender_offers.length > 0) {
            const found = validE2eLenders.find((l) => Number(l) === Number(sub.lender_id));
            if (found) {
              switch (waterFallCondition) {
                case E2eWaterfallConditionsE.stop_at_first:
                  handleWaterfallConditionStopAtFirst(sub.lender_offers, estimatedPurchaseAmount);
                  break;
                case E2eWaterfallConditionsE.stop_in_aggregate:
                  const highest = handleWaterfallConditionStopInAggregate(
                    sub.lender_offers,
                    estimatedPurchaseAmount,
                    aggregateCount,
                  );
                  aggregateCount += highest;
                  break;
                case E2eWaterfallConditionsE.stop_on_pending_w_option_to_continue:
                  // this will include stop_at_first condition as well - for synchrony
                  handleWaterfallConditionStopAtFirst(sub.lender_offers, estimatedPurchaseAmount);
                  handleStopOnPending(sub);
                  break;
              }
            }
          }
        }
      } catch (e) {
        const msg = e.message;
        console.log(msg);
        if (msg === 'StopAtFirstMet') {
          return true;
        }
        if (msg === 'AggregateMet') {
          return true;
        }
        if (msg === 'stop_on_pending_w_option_to_continue') {
          return true;
        }
      }
    }
    return false;
  }
};

const handleWaterfallConditionStopAtFirst = (lenderOffers: LenderOffersI[], estimatedPurchaseAmount: number) => {
  for (const offer of lenderOffers) {
    const approvedAmt = offer.approved_amount;
    if (approvedAmt) {
      if (Number(approvedAmt) >= Number(estimatedPurchaseAmount)) {
        throw new Error('StopAtFirstMet');
      }
    }
  }
};

const handleWaterfallConditionStopAtFirst2 = (lenderOffers: LenderOffersI[], estimatedPurchaseAmount: number) => {
  let cond = false;
  for (const offer of lenderOffers) {
    const approvedAmt = offer.approved_amount;
    if (approvedAmt) {
      if (Number(approvedAmt) >= Number(estimatedPurchaseAmount)) {
        cond = true;
        break;
      }
    }
  }
  return cond;
};

const handleStopOnPending = (sub: ApplicationSubmissionStateDto) => {
  if (sub.lender_response === AppSubLenderResponseE.pending) {
    throw new Error('stop_on_pending_w_option_to_continue');
  }
};

const handleStopOnPending2 = (sub: ApplicationSubmissionStateDto) => {
  if (sub.lender_response === AppSubLenderResponseE.pending) {
    return true;
  }
  return false;
};

const handleStopOnPendingWithOptionToContinue = (
  sub: ApplicationSubmissionStateDto,
  estimatedPurchaseAmount: number,
) => {
  try {
    if (sub.lender_offers && estimatedPurchaseAmount) {
      handleWaterfallConditionStopAtFirst(sub.lender_offers, estimatedPurchaseAmount);
      handleStopOnPending(sub);
    }
  } catch (e) {}
};

const handleWaterfallConditionStopInAggregate = (
  lenderOffers: LenderOffersI[],
  estimatedPurchaseAmount: number,
  aggregateCount: number,
): number => {
  let highest = Number(lenderOffers[0]?.approved_amount);
  if (highest && highest >= 0) {
    for (let i = 1; i < lenderOffers.length; i++) {
      const lf = lenderOffers[i];
      if (lf && lf?.approved_amount && Number(lf.approved_amount) > highest) {
        const approvedAmt = lenderOffers[i].approved_amount;
        if (approvedAmt) {
          highest = Number(approvedAmt); // Update highest if current element has a higher approved_amount
        }
      }
    }
    if (aggregateCount + highest >= estimatedPurchaseAmount) {
      throw new Error('AggregateMet');
    } else {
      return Number(highest);
    }
  }
  return highest;
};

const handleWaterfallConditionStopInAggregate2 = (
  lenderOffers: LenderOffersI[],
  estimatedPurchaseAmount: number,
  aggregateCount: number,
): number => {
  let highest = Number(lenderOffers[0]?.approved_amount);
  if (highest && highest >= 0) {
    for (let i = 1; i < lenderOffers.length; i++) {
      const lf = lenderOffers[i];
      if (lf && lf?.approved_amount && Number(lf.approved_amount) > highest) {
        const approvedAmt = lenderOffers[i].approved_amount;
        if (approvedAmt) {
          highest = Number(approvedAmt); // Update highest if current element has a higher approved_amount
        }
      }
    }
    return Number(highest);
  }
  return aggregateCount;
};

export const e2eSubLogic_checkSubmissionsWhereErrorOrDeclined = (
  submissions: ApplicationSubmissionStateDto[],
): number[] => {
  /**
   *  - We do not show declines or system errors, only approved offers
   *  - check for all system errors - show "please get with your rep"
   */
  const failedLenderIds: number[] = [];
  const validResponses = [
    AppSubLenderResponseE.hold,
    AppSubLenderResponseE.soft_decline,
    AppSubLenderResponseE.invalid,
    AppSubLenderResponseE.rejected,
    AppSubLenderResponseE.pending,
    AppSubLenderResponseE.timed_out,
  ];
  for (const sub of submissions) {
    const lenderResponse = sub.lender_response;
    if (validResponses.includes(lenderResponse)) {
      failedLenderIds.push(Number(sub.lender_id));
    }
  }
  return failedLenderIds;
};

export interface MoveForwardLogicParams {
  haveE2eLendersResponded: boolean;
  submissions: ApplicationSubmissionStateDto[];
  appConfg: E2eAppConfigurationsI;
  aaso: AASO;
  franchise_settings?: FranchiseSettingsI;
  laa_settings?: LaaSettingsI;
  form: any;
}
enum SpecialMoveForwardLogicE {
  'laa_settings' = 'laa_settings',
  'franchise_settings' = 'franchise_settings',
  'na' = 'na',
}
export interface MoveForwardLogicOutputI {
  continue_waterfall: boolean;
  continue_next_path: boolean;
  special_type: SpecialMoveForwardLogicE;
  description: string;
  object?: BaseConditionsI;
}

interface SpecialForwardI {
  special: boolean;
  special_type: SpecialMoveForwardLogicE;
}
export const e2eSubLogic_defaultMoveForwardLogic = (obj: MoveForwardLogicParams) => {
  const { haveE2eLendersResponded, submissions, appConfg, aaso } = obj;
  const moveForwardObj: MoveForwardLogicOutputI = {
    continue_waterfall: false,
    continue_next_path: false,
    special_type: SpecialMoveForwardLogicE.na,
    description: 'Default logic not met',
  };
  if (haveE2eLendersResponded) {
    // always show not happy path route
    const otherPathsExist = e2ePathCondLogic_areThereOtherPathConditionsAvailable(appConfg, aaso);
    // check if showContinueWaterfallPipe - only 1 card can show (NotHappy or Continue)
    const showContinueWaterfall = e2ePathCondLogic_shouldShowContinueWaterfallPipe(appConfg, aaso, submissions);
    if (showContinueWaterfall) {
      moveForwardObj.continue_waterfall = true;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'Default - continue water fall piping';
      return moveForwardObj;
    } else {
      // need to check that there are path conditions for current path
      const currentPathPathConditions = appConfg.path_conditions?.current_path_conditions;

      // E2E NOTE - we can avoid this if we add path conditions to every path
      const countOfAllPaths = appConfg.current_form?.paths;
      const pathsRan = aaso.past_path_ids;
      const currentPathId = appConfg?.current_path?.id;
      // if (currentPathId && countOfAllPaths) {
      //   const completedPaths = [...new Set([...pathsRan, currentPathId])];
      //   if (completedPaths.length < countOfAllPaths.length) {
      //     // we're doing this because we always want to give them the optin to move forward
      //     moveForwardObj.continue_waterfall = false;
      //     moveForwardObj.continue_next_path = true;
      //     moveForwardObj.description = `Default - checking if paths exists only - continue next path ${otherPathsExist}`;
      //     return moveForwardObj;
      //   }
      // }
      if (currentPathPathConditions) {
        // const countOfAllPaths = appConfg.current_form?.paths;
        const pathsRan = aaso.past_path_ids;
        const currentPathId = appConfg?.current_path?.id;
        const currentPathIndex = appConfg.current_form?.paths.findIndex((p) => p.id === currentPathId);
        if (currentPathIndex) {
          // const nextPath = currentPathPathConditions.path_conditions[currentPathIndex];
          const nextPathId = currentPathPathConditions.if_path;
          // check to see if it's been ran
          const hasNextPathBeenRan = pathsRan.includes(nextPathId);

          if (!hasNextPathBeenRan) {
            moveForwardObj.continue_waterfall = false;
            moveForwardObj.continue_next_path = true;
            moveForwardObj.description = `Default - next path has not been ran - continue next path ${otherPathsExist}`;
            return moveForwardObj;
          } else {
            moveForwardObj.continue_waterfall = false;
            moveForwardObj.continue_next_path = false;
            moveForwardObj.description = `Default - Next path has already been ran`;
            return moveForwardObj;
          }
        }
        // check to see if path has already been ran
        moveForwardObj.continue_waterfall = false;
        moveForwardObj.continue_next_path = otherPathsExist;
        moveForwardObj.description = `Default - continue next path ${otherPathsExist}`;
        return moveForwardObj;
      } else {
        moveForwardObj.continue_waterfall = false;
        moveForwardObj.continue_next_path = false;
        moveForwardObj.description = `Default - No path conditions exist ${otherPathsExist}`;
        return moveForwardObj;
      }
    }
  }
  return moveForwardObj;
};

export const e2eSubLogic_moveForwardShowDefaultOrSpecial = (obj: MoveForwardLogicParams): SpecialForwardI => {
  const { laa_settings, submissions, appConfg, aaso, franchise_settings } = obj;
  // check to see if franchise_settings or laa_settings exist
  if (laa_settings) {
    // check to see if current_path even applies to the laa_lender
    const currentPath = appConfg?.current_path;
    const laaLenderId = laa_settings.lender_id;
    if (currentPath && laaLenderId) {
      if (currentPath?.lenders.includes(laaLenderId)) {
        // current path includes the laaLenderId - so this will always return true
        return { special: true, special_type: SpecialMoveForwardLogicE.laa_settings };
      } else {
        return { special: false, special_type: SpecialMoveForwardLogicE.na };
      }
    } else {
      return { special: false, special_type: SpecialMoveForwardLogicE.na };
    }
  }
  if (franchise_settings) {
    // come back and add this
    return { special: true, special_type: SpecialMoveForwardLogicE.franchise_settings };
  }
  return { special: false, special_type: SpecialMoveForwardLogicE.na };
};

export const e2eSubLogic_specialMoveForwardLogic = (
  obj: MoveForwardLogicParams,
  specialObj: SpecialForwardI,
): MoveForwardLogicOutputI => {
  const moveForwardObj: MoveForwardLogicOutputI = {
    continue_waterfall: false,
    continue_next_path: false,
    special_type: specialObj.special_type,
    description: 'No special move forward condition met',
  };
  const { laa_settings, submissions, appConfg, aaso } = obj;
  if (specialObj.special_type === SpecialMoveForwardLogicE.laa_settings) {
    // return e2eSubLogic_laaSettingsMoveForwardLogic(obj);
    return e2eSubLogic_laaSettingsMoveForwardLogic2(obj);
  } else if (specialObj.special_type === SpecialMoveForwardLogicE.franchise_settings) {
    return e2eSubLogic_franchiseSettingsMoveForwardLogic(obj);
  }
  return moveForwardObj;
};

export interface BaseConditionsI {
  /**
   * Says whether or not this current path is even eligible to continue piping in a waterfall:
   * - All E2E lenders in path have been ran = false
   * - Lenders must include valid submission states (possibly dictcated by laa_settings obj)
   * - Only valid if not all E2E lenders have been ran in the current path
   * - Only valid if path is a waterfall
   */
  is_eligible_waterfall_continue: boolean;
  is_waterfall: boolean;
  is_laa: boolean;
  laa_settings_included: boolean;
  laa_lender_id?: number;
  current_path_has_laa_lender: boolean;
  laa_lender_submission?: ApplicationSubmissionStateDto;
  /**
   * If there are other paths that have not been ran yet
   */
  other_paths_exist: boolean;
  laaMoveForward?: E2eOnConditionsI;
  waterfall_conditions?: E2eWaterfallConditionsE[] | E2eWaterfallConditionsE;
  waterfallConditionsObj?: AppResultsWaterfallConditionsI;
}

const getBaseConditions = (obj: MoveForwardLogicParams) => {
  const { laa_settings, submissions, appConfg, aaso, form } = obj;
  appConfg.store_details.is_laa;

  const baseConditions: BaseConditionsI = {
    is_eligible_waterfall_continue: false,
    is_waterfall: false,
    is_laa: !!appConfg.store_details.is_laa,
    laa_settings_included: false,
    laa_lender_id: undefined,
    current_path_has_laa_lender: false,
    laaMoveForward: undefined,
    other_paths_exist: e2ePathCondLogic_areThereOtherPathConditionsAvailable(appConfg, aaso),
  };

  const currentPath = appConfg?.current_path;
  const pathWaterfallConditions = currentPath?.waterfall_conditions;
  const submissionType = currentPath?.submission_type;
  if (pathWaterfallConditions && submissionType === E2ePathSubmissionTypesE.waterfall) {
    baseConditions.is_waterfall = true;
    baseConditions.waterfall_conditions = pathWaterfallConditions;
  }
  if (laa_settings && laa_settings.lender_id) {
    baseConditions.laa_settings_included = true;
    baseConditions.laa_lender_id = laa_settings.lender_id;
    if (currentPath?.lenders.includes(laa_settings.lender_id)) {
      baseConditions.current_path_has_laa_lender = true;
    }
    const laaLenderSubmission = submissions.find((s) => Number(s.lender_id) === Number(laa_settings.lender_id));
    if (laaLenderSubmission) {
      baseConditions.laa_lender_submission = laaLenderSubmission;
    }
    const laaE2eConfig = laa_settings.e2e_configs;
    const continueWaterfallLenderResponses =
      laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_waterfall?.lender_responses;
    const validSubmissionStates =
      laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_waterfall?.submission_statuses;
    if (continueWaterfallLenderResponses && validSubmissionStates) {
      baseConditions.laaMoveForward = {
        lender_responses: continueWaterfallLenderResponses,
        submission_statuses: validSubmissionStates,
      };
      baseConditions.is_eligible_waterfall_continue = e2ePathCondLogic_shouldShowContinueWaterfallPipeConditional(
        appConfg,
        aaso,
        submissions,
        continueWaterfallLenderResponses,
        validSubmissionStates,
      );
    }
  }

  baseConditions.waterfallConditionsObj = getWaterfallAppResultConditions(
    appConfg,
    submissions,
    form.estimated_purchase_amount,
  );

  return baseConditions;
};

const checkWaterfallConditions = (moveForwardObj: MoveForwardLogicOutputI, baseConditions: BaseConditionsI) => {
  const { waterfall_conditions, waterfallConditionsObj } = baseConditions;
  if (Array.isArray(waterfall_conditions) && waterfallConditionsObj) {
    const waterfallMapped: any = waterfall_conditions.reduce((acc, condition) => {
      if (condition in waterfallConditionsObj) {
        //@ts-ignore
        acc[condition] = waterfallConditionsObj[condition];
      }
      return acc;
    }, {});
    if (waterfallMapped.stop_at_first) {
      moveForwardObj.continue_waterfall = false;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings - stop at first';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    if (waterfallMapped.stop_on_pending_w_option_to_continue) {
      moveForwardObj.continue_waterfall = true;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings - stop on pending';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    moveForwardObj.continue_waterfall = true;
    moveForwardObj.continue_next_path = false;
    moveForwardObj.description = 'LAA Settings - continue eligible - conditions not met ';
    moveForwardObj.object = baseConditions;
    return moveForwardObj;
  } else {
    if (waterfall_conditions === E2eWaterfallConditionsE.stop_at_first) {
      moveForwardObj.continue_waterfall = false;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings - stop at first';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    if (waterfall_conditions === E2eWaterfallConditionsE.stop_on_pending_w_option_to_continue) {
      moveForwardObj.continue_waterfall = true;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings - stop on pending';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    moveForwardObj.continue_waterfall = true;
    moveForwardObj.continue_next_path = false;
    moveForwardObj.description = 'LAA Settings - continue eligible - conditions not met ';
    moveForwardObj.object = baseConditions;
    return moveForwardObj;
  }
  return moveForwardObj;
};
const checkNextPathConditions = (moveForwardObj: MoveForwardLogicOutputI, baseConditions: BaseConditionsI) => {
  const { waterfall_conditions, waterfallConditionsObj } = baseConditions;
  if (Array.isArray(waterfall_conditions) && waterfallConditionsObj) {
    const waterfallMapped: any = waterfall_conditions.reduce((acc, condition) => {
      if (condition in waterfallConditionsObj) {
        //@ts-ignore
        acc[condition] = waterfallConditionsObj[condition];
      }
      return acc;
    }, {});
    if (waterfallMapped.stop_at_first) {
      moveForwardObj.continue_waterfall = false;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings next path - stop at first';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    if (waterfallMapped.stop_on_pending_w_option_to_continue) {
      moveForwardObj.continue_waterfall = false;
      moveForwardObj.continue_next_path = true;
      moveForwardObj.description = 'LAA Settings next path - stop on pending';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    moveForwardObj.continue_waterfall = false;
    moveForwardObj.continue_next_path = true;
    moveForwardObj.description = 'LAA Settings - continue eligible - conditions not met ';
    moveForwardObj.object = baseConditions;
    return moveForwardObj;
  } else {
    if (waterfall_conditions === E2eWaterfallConditionsE.stop_at_first) {
      moveForwardObj.continue_waterfall = false;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings - next path - stop at first';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    if (waterfall_conditions === E2eWaterfallConditionsE.stop_on_pending_w_option_to_continue) {
      moveForwardObj.continue_waterfall = true;
      moveForwardObj.continue_next_path = false;
      moveForwardObj.description = 'LAA Settings - stop on pending';
      moveForwardObj.object = baseConditions;
      return moveForwardObj;
    }
    moveForwardObj.continue_waterfall = false;
    moveForwardObj.continue_next_path = true;
    moveForwardObj.description = 'LAA Settings - next path - continue eligible - conditions not met ';
    moveForwardObj.object = baseConditions;
    return moveForwardObj;
  }
  return moveForwardObj;
};
export const e2eSubLogic_laaSettingsMoveForwardLogic2 = (obj: MoveForwardLogicParams): MoveForwardLogicOutputI => {
  // this is already broken up by LAA, Franchise or default settings
  const { laa_settings, submissions, appConfg, aaso, form } = obj;
  const moveForwardObj: MoveForwardLogicOutputI = {
    continue_waterfall: false,
    continue_next_path: false,
    special_type: SpecialMoveForwardLogicE.laa_settings,
    description: 'No special conditions met',
  };
  const baseConditions: BaseConditionsI = getBaseConditions(obj);
  const {
    is_eligible_waterfall_continue,
    is_waterfall,
    waterfall_conditions,
    laa_settings_included,
    is_laa,
    laa_lender_id,
    current_path_has_laa_lender,
    laaMoveForward,
    laa_lender_submission,
    other_paths_exist,
    waterfallConditionsObj,
  } = baseConditions;
  // const jobDetails = aaso.e2e_job_details;
  // const currentPath = appConfg?.current_path;

  /**
   * What really matter
   * - is_eligible_waterfall_continue
   * - is_waterfall
   * - current_path_has_laa_lender
   * - other_paths_exist
   * - waterfallConditionsObj
   */
  if (is_eligible_waterfall_continue && waterfall_conditions) {
    // continue waterfall is possible, now dow they meet waterfall conditions
    return checkWaterfallConditions(moveForwardObj, baseConditions);
  } else {
    // cannot move forward with waterfall
    if (other_paths_exist) {
      // other paths exists
      return checkNextPathConditions(moveForwardObj, baseConditions);
    } else {
      // no other paths or waterfall exists - they are done
      moveForwardObj.description = 'LAA Settings - completed';
      return moveForwardObj;
    }
  }
};
export const e2eSubLogic_laaSettingsMoveForwardLogic = (obj: MoveForwardLogicParams): MoveForwardLogicOutputI => {
  const { laa_settings, submissions, appConfg, aaso, form } = obj;
  /**
   * Need to handle:
   * - Either:
   *  - setContinueWaterfallPiping
   *      - baseChecks:
   *        - is current path a waterfall
   *        - is laa lender in the current path
   *      - if baseChecks pass:
   *        - continueWaterfall === lender_responses
   *
   *  - setShowNotHappyCard
   *    - if setContinueWaterfallPiping = false
   *    - baseChecks:
   *      - there is a next path that hasn't been completed
   *    - if baseChecks pass:
   *      -
   *
   */
  const moveForwardObj: MoveForwardLogicOutputI = {
    continue_waterfall: false,
    continue_next_path: false,
    special_type: SpecialMoveForwardLogicE.laa_settings,
    description: 'No special conditions met',
  };
  if (laa_settings) {
    // !!! need to grab the current jobs... it's being returned on kickoff
    const laaE2eConfig = laa_settings.e2e_configs;
    const continueWaterfall: E2eOnConditionsI | undefined =
      laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_waterfall;
    const continueNextPath: E2eOnConditionsI | undefined =
      laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_next_path;

    const laaLenderId = laa_settings.lender_id;
    const jobDetails = aaso.e2e_job_details;
    // basically, we need to make sure we're only applying these settings when laa_lender is being piped
    const currentPath = appConfg?.current_path;
    const pathWaterfallConditions = currentPath?.waterfall_conditions;
    if (currentPath?.lenders.includes(laaLenderId)) {
      // if laa lender is in the current path, special rules applie
      // need to check if they have been completed
      const laaLenderSubmission = submissions.find((s) => Number(s.lender_id) === Number(laaLenderId));
      const otherPathsExist = e2ePathCondLogic_areThereOtherPathConditionsAvailable(appConfg, aaso);
      // const lenderJobDetails = jobDetails.find((jd) => jd.lender_id == laaLenderId);
      const continueWaterfallLenderResponses =
        laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_waterfall?.lender_responses;
      const validSubmissionStates =
        laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_waterfall?.submission_statuses;
      // TODO E2E REVISE - this doesn't handle cases where there are no submission_statuses... both must exist
      if (continueWaterfallLenderResponses && validSubmissionStates) {
        const showContinueWaterfall = e2ePathCondLogic_shouldShowContinueWaterfallPipeConditional(
          appConfg,
          aaso,
          submissions,
          continueWaterfallLenderResponses,
          validSubmissionStates,
        );

        if (showContinueWaterfall) {
          if (otherPathsExist && pathWaterfallConditions && form?.estimated_purchase_amount) {
            // check for waterfall conditions
            // TODO E2E P0 - removing until synchrony is added, but then we have to add special logic to only apply to synchrony
            const waterfallConditinsMet = checkIfCurrentPathWaterfallConditionsHaveBeenMet(
              appConfg,
              submissions,
              form.estimated_purchase_amount,
            );
            if (waterfallConditinsMet) {
              // NOW we need to see if waterfallCondition = option_to_continue
              if (pathWaterfallConditions === E2eWaterfallConditionsE.stop_on_pending_w_option_to_continue) {
                // if it's this waterfall condition, we need to check if they have another lender in current path
                moveForwardObj.continue_waterfall = true;
                moveForwardObj.continue_next_path = false;
                moveForwardObj.description = 'LAA settings - stop waterfall with option to continue';
              } else {
                moveForwardObj.continue_waterfall = false;
                moveForwardObj.continue_next_path = false;
                moveForwardObj.description =
                  'LAA settings - showContinueWaterfall - do not continue waterfall - local conditions met';
              }
            } else {
              moveForwardObj.continue_waterfall = true;
              moveForwardObj.continue_next_path = false;
              moveForwardObj.description = `LAA settings - continue waterfall NOT met ${otherPathsExist}`;
            }
          } else {
            moveForwardObj.continue_waterfall = true;
            moveForwardObj.continue_next_path = false;
            moveForwardObj.description = 'LAA settings - continue waterfall met';
            return moveForwardObj;
          }
        } else {
          moveForwardObj.continue_waterfall = false;
          if (otherPathsExist && pathWaterfallConditions && form?.estimated_purchase_amount) {
            // check for waterfall conditions
            // TODO E2E P0 - removing until synchrony is added, but then we have to add special logic to only apply to synchrony
            const waterfallConditinsMet = checkIfCurrentPathWaterfallConditionsHaveBeenMet(
              appConfg,
              submissions,
              form.estimated_purchase_amount,
            );
            if (waterfallConditinsMet) {
              moveForwardObj.continue_next_path = false;
              moveForwardObj.description = 'LAA settings - do not continue waterfall - local conditions met';
            } else {
              moveForwardObj.continue_next_path = otherPathsExist;
              moveForwardObj.description = `LAA settings - continue waterfall NOT met ${otherPathsExist}`;
            }
          } else {
            moveForwardObj.continue_next_path = otherPathsExist;
            moveForwardObj.description = 'LAA settings - continue waterfall NOT met';
          }

          return moveForwardObj;
        }
      } else {
        if (otherPathsExist) {
          // now we have to apply the
          const nextPathLenderResponses =
            laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_next_path?.lender_responses;
          const subLenderResponse = laaLenderSubmission?.lender_response;
          if (
            nextPathLenderResponses &&
            laaLenderSubmission &&
            subLenderResponse &&
            nextPathLenderResponses.includes(subLenderResponse)
          ) {
            // setShowNotHappyCard(true);
            moveForwardObj.continue_waterfall = false;
            moveForwardObj.continue_next_path = true;
            moveForwardObj.description = 'LAA settings - continue next path met';
            return moveForwardObj;
          } else {
            moveForwardObj.continue_waterfall = false;
            moveForwardObj.continue_next_path = true;
            moveForwardObj.description = 'LAA settings - continue next path NOT met';
            return moveForwardObj;
          }
        } else {
          moveForwardObj.continue_waterfall = false;
          moveForwardObj.continue_next_path = false;
          moveForwardObj.description = 'LAA settings - Waterfall not met and no other paths exists after this';
          return moveForwardObj;
        }
      }
    }
  }
  return moveForwardObj;
};

export const e2eSubLogic_franchiseSettingsMoveForwardLogic = (obj: MoveForwardLogicParams): MoveForwardLogicOutputI => {
  const { laa_settings, submissions, appConfg, aaso } = obj;
  const moveForwardObj: MoveForwardLogicOutputI = {
    continue_waterfall: false,
    continue_next_path: false,
    special_type: SpecialMoveForwardLogicE.laa_settings,
    description: 'No special conditions met',
  };
  if (laa_settings) {
    // !!! need to grab the current jobs... it's being returned on kickoff
    const laaE2eConfig = laa_settings.e2e_configs;
    const continueWaterfall: E2eOnConditionsI | undefined =
      laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_waterfall;
    const continueNextPath: E2eOnConditionsI | undefined =
      laaE2eConfig?.applicant_flow?.move_forward_conditions?.continue_next_path;

    const laaLenderId = laa_settings.lender_id;
    const jobDetails = aaso.e2e_job_details;
    const lenderJobDetails = jobDetails.find((jd) => jd.lender_id == laaLenderId);
    // basically, we need to make sure we're only applying these settings when laa_lender is being piped
    const currentPath = appConfg?.current_path;
    if (currentPath?.lenders.includes(laaLenderId)) {
      // if laa lender is in the current path, special rules applie
      // need to check if they have been completed
      const laaLenderSubmission = submissions.find((s) => Number(s.lender_id) === Number(laaLenderId));
      const lenderResponse: AppSubLenderResponseE | undefined = laaLenderSubmission?.lender_response;
      const submissionState: AppSubStatusE | undefined = laaLenderSubmission?.submission_state;
      const validSubmissionStates = [AppSubStatusE.processing, AppSubStatusE.redirected];
      if (!laaLenderSubmission || validSubmissionStates.includes(laaLenderSubmission.submission_state)) {
        // laaSettingsApply to this
      }
    }
  }
  return moveForwardObj;
};
