import React from 'react';
import ThemedDialog from 'src/e2e-redesign/components/ThemedDialog/ThemedDialog';

interface SynchronyRedirectModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  paragraph: string;
  imageUrl: string;
}

const RedirectModal: React.FC<SynchronyRedirectModalProps> = ({ open, onClose, title, paragraph, imageUrl }) => {
  const content = `
    <div style="text-align: center;">
      <h2>${title}</h2>
      <p>${paragraph}</p>
      <img src="${imageUrl}" alt="Redirecting" style="max-width: 100%; margin-top: 20px;" />
    </div>
  `;

  return <ThemedDialog open={open} onClose={onClose} content={content} />;
};

export default RedirectModal;
