import { useEffect } from 'react';
import { queryGetAasoById } from 'src/api/query-logic/query-aaso';
import {
  queryGetE2eApplicationSettings,
  queryGetE2eLimitedApplicationSettings,
} from 'src/api/query-logic/query-e2e-application-settings';
import { queryGetE2eDisclosures } from 'src/api/query-logic/query-e2e-disclosures';
import { queryE2eForm } from 'src/api/query-logic/query-e2e-form';
import { queryE2eLocations } from 'src/api/query-logic/query-e2e-locations';
import { e2eConfigLogic_buildAppConfigurationObject } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';

export interface E2eBaseAasoCallsI {
  appSettingsData: any;
  appSettingsIsLoading: boolean;
  appSettingsIsError: any;
  discData: any;
  discIsLoading: boolean;
  discIsError: any;
  aasoData: any;
  aasoIsLoading: boolean;
  aasoIsError: any;
  locationsData: any;
  locationsIsLoading: any;
  locationsIsError: any;
  formValues: any;
  formValuesIsLoading: boolean;
  formValuesIsError: any;
  formValuesIsFetched: boolean;
  aasoIsFetched: boolean;
}

export interface E2eBaseAuthenticatedCallsI {
  appSettingsData: any;
  appSettingsIsLoading: boolean;
  appSettingsIsError: any;
}

export interface E2eBasePublicCallsI {
  appConfigData: any;
  appConfigIsLoading: boolean;
  appConfigIsError: any;
  discData: any;
  discIsLoading: boolean;
  discIsError: any;
}
export interface E2eBaseCallDetailsI {
  store_uuid: string;
  e2e_form_id: string;
  store_name?: string;
  aaso_id?: string;
}
export const e2eGetBasePublicRequests = (
  e2eStore: E2eGlobalStore,
  baseCallDetails: E2eBaseCallDetailsI,
  isAuthenticated?: boolean,
): E2eBasePublicCallsI => {
  const { store_uuid, e2e_form_id } = baseCallDetails;
  const {
    data: appConfigData,
    isError: appConfigIsLoading,
    isLoading: appConfigIsError,
  } = queryGetE2eLimitedApplicationSettings({
    reqData: { params: { store_uuid, e2e_form_id } },
    queryKey: [store_uuid, e2e_form_id],
    setState: e2eStore.setAppConfigurations,
    urlParams: { store_uuid, e2e_form_id },
    queryOpt: {
      enabled: !isAuthenticated && e2e_form_id?.length > 0 && store_uuid?.length > 0,
    },
  });
  const {
    data: discData,
    isLoading: discIsLoading,
    isError: discIsError,
  } = queryGetE2eDisclosures({
    reqData: { params: { store_uuid, e2e_form_id } }, // RequestDataI
    queryKey: [store_uuid, e2e_form_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setDisclosures, // how we store it to the store
    queryOpt: {
      enabled: true,
    },
  });
  return {
    appConfigData,
    appConfigIsLoading,
    appConfigIsError,
    discData,
    discIsLoading,
    discIsError,
  };
};

export const e2eGetBaseAuthenticatedRequests = (
  baseDetails: E2eBaseCallDetailsI,
  e2eStore: E2eGlobalStore,
  enabled?: boolean,
): E2eBaseAuthenticatedCallsI => {
  const { store_uuid, e2e_form_id } = baseDetails;
  const {
    data: appSettingsData,
    isLoading: appSettingsIsLoading,
    isError: appSettingsIsError,
  } = queryGetE2eApplicationSettings({
    reqData: { params: { store_uuid, e2e_form_id } }, // RequestDataI
    queryKey: [store_uuid, e2e_form_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setLogicObjects, // how we store it to the store
    urlParams: { store_uuid, e2e_form_id },
    queryOpt: {
      enabled, // this prevents the query from running until the storeUuid is set
    },
  });

  return {
    appSettingsData,
    appSettingsIsLoading,
    appSettingsIsError,
  };
};

export const e2eGetBaseAasoRequests = (
  baseDetails: E2eBaseCallDetailsI,
  e2eStore: E2eGlobalStore,
  enabled?: boolean,
): E2eBaseAasoCallsI => {
  const { store_uuid, e2e_form_id, aaso_id } = baseDetails;
  const { app_configurations } = e2eStore;
  const setAppConfigurations = e2eStore.setAppConfigurations;

  const {
    data: appSettingsData,
    isLoading: appSettingsIsLoading,
    isError: appSettingsIsError,
  } = queryGetE2eApplicationSettings({
    reqData: { params: { store_uuid, e2e_form_id } }, // RequestDataI
    queryKey: [store_uuid, e2e_form_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setLogicObjects, // how we store it to the store
    urlParams: { store_uuid, e2e_form_id },
    queryOpt: {
      enabled, // this prevents the query from running until the storeUuid is set
    },
  });

  const {
    data: formValues,
    isLoading: formValuesIsLoading,
    isError: formValuesIsError,
    isFetched: formValuesIsFetched,
  } = queryE2eForm({
    reqData: { params: { aaso_id } }, // RequestDataI
    queryKey: [aaso_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setFormValues, // how we store it to the store
    queryOpt: {
      enabled: true, // this prevents the query from running until the storeUuid is set
    },
  });

  const {
    data: discData,
    isLoading: discIsLoading,
    isError: discIsError,
  } = queryGetE2eDisclosures({
    reqData: { params: { store_uuid, e2e_form_id } }, // RequestDataI
    queryKey: [store_uuid, e2e_form_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setDisclosures, // how we store it to the store
    queryOpt: {
      enabled, // this prevents the query from running until the storeUuid is set
    },
  });

  const {
    data: locationsData,
    isLoading: locationsIsLoading,
    isError: locationsIsError,
  } = queryE2eLocations({
    reqData: {},
    queryKey: ['locations'], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setLocations, // how we store it to the store
    queryOpt: {
      enabled, // this prevents the query from running until the storeUuid is set
    },
  });
  const aaso = e2eStore.aaso;
  const {
    data: aasoData,
    isLoading: aasoIsLoading,
    isError: aasoIsError,
    isFetched: aasoIsFetched,
  } = queryGetAasoById({
    reqData: { params: { aaso_id } }, // RequestDataI
    queryKey: [aaso_id], // this is a unique key for tstack, if it changes, it will refetch
    setState: e2eStore.setAASO, // how we store it to the store
    queryOpt: {
      enabled: !aaso?._id, // if aaso._id does not exist, we need to pull it from the api
    },
  });

  useEffect(() => {
    // this makes sure the app configuration is updated every time they go to a ne wpage
    if (
      aaso &&
      aaso._id &&
      appSettingsData &&
      app_configurations &&
      (!app_configurations.current_form || !app_configurations.current_path)
    ) {
      const appSettings = e2eConfigLogic_buildAppConfigurationObject(appSettingsData, aaso, e2e_form_id);
      setAppConfigurations(appSettings);
    }
  }, [appSettingsData, aaso]);
  return {
    appSettingsData,
    appSettingsIsLoading,
    appSettingsIsError,
    discData,
    discIsLoading,
    discIsError,
    aasoData,
    aasoIsLoading,
    aasoIsError,
    locationsData,
    locationsIsLoading,
    locationsIsError,
    formValues,
    formValuesIsLoading,
    formValuesIsError,
    formValuesIsFetched,
    aasoIsFetched,
  };
};
