/* eslint-disable */
import { CSSProperties } from 'react';

export enum MessageModalSizeEnum {
  Medium,
  Large,
}

export enum ForDialogTypes {
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning',
}

// ToDo - when created dynamic dialog, this deprecated
export enum ForModalNames {
  'SignMissingDisclosures' = 'SignMissingDisclosures',
}

export interface MessageModalI {
  modal_name: ForModalNames; // name of the modal to use
  type?: ForDialogTypes;
  message?: string;
  data?: any;
  subMessage?: string | JSX.Element[];
  onAccept?: (args: any) => void;
  onCancel?: () => void;
  acceptButtonLabel?: string;
  closeButtonLabel?: string;
  buttonComponent?: JSX.Element;
  header?: string;
  size?: MessageModalSizeEnum;
  dialogStyle?: CSSProperties;
}
