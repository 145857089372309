/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FormHelperText, TextField, Theme } from '@mui/material';

const styles = {
  root: {},
};

const YearSelector: React.FC<YearSelectorI> = (props: YearSelectorI): JSX.Element => {
  const classes = styles;
  const [years, setYears] = useState([]);

  useEffect(() => {
    let thisYear = new Date().getFullYear();
    let minOffset = props.minOffset,
      maxOffset = props.maxOffset;
    let allYears: any[] = [];
    const yearsAhead = (allYears: any) => {
      for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear + x);
      }
      return allYears.map((x: any) => x);
    };

    const yearsBehind = (allYears: any) => {
      for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x);
      }
      return allYears.map((x: any) => x);
    };

    let y = [];
    if (props.spread) {
      const aheadList = yearsAhead(allYears);
      const beforeList = yearsBehind(allYears);
      y = [...aheadList, ...beforeList];
    } else if (props.aheadOrBehind === 'ahead') {
      y = yearsAhead(allYears);
    } else if (props.aheadOrBehind === 'behind') {
      y = yearsBehind(allYears);
    } else {
      y = yearsBehind(allYears);
    }
    // ToDo - update mask-selector.selector with this, just need to add helper text
    if (props.sort && props.sort === 'asc') {
      y = y.sort();
    } else {
      y = y.sort().reverse();
    }
    y.unshift('');
    setYears(y);
  }, [props]);

  return (
    <div>
      <TextField
        fullWidth
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        select
        SelectProps={{ native: true }}
        value={props.value}
        variant="outlined"
        disabled={props.disabled}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
          },
        }}
      >
        {years.map((l: any, i) => (
          <option
            key={`${l.id}-${i}-${l}`}
            value={l}
            // selected={l.state_name === props.value ? true : false}
          >
            {l}
          </option>
        ))}
      </TextField>
      <FormHelperText>{props.helperText}</FormHelperText>
    </div>
  );
};

export interface YearSelectorI {
  value: string;
  onChange: any;
  label: string;
  id: string;
  name: string;
  helperText: any;
  variant: string;
  onBlur: any;
  placeholder: string;
  error: any;
  aheadOrBehind: string;
  maxOffset: number;
  minOffset: number;
  spread: boolean;
  sort: string;
  disabled?: boolean;
}

export default YearSelector;
