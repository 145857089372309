import toast from 'react-hot-toast';
import { E2eClientErrorAggregate, E2eClientErrorType } from 'src/errors/error.interfaces';
import { showForDialog } from 'src/modal-manager/for-dialog';
import { ForDialogTypes, ForModalNames } from 'src/modal-manager/for-dialog.types';

export const e2eHandleErrors = (obj: E2eClientErrorAggregate): void => {
  // R:TODO E2E P0 - handle error handling for for submission errors
  const type = obj.type;
  switch (type) {
    case E2eClientErrorType.E2eFormSubmissionError:
      return handleSubmissionFormErrors(obj);
    case E2eClientErrorType.E2eFormValidationError:
    case E2eClientErrorType.E2eFormGeneralError:
    default:
      return handleFormValidationError(obj);
  }
};

const handleFormValidationError = (obj: E2eClientErrorAggregate) => {
  const message = obj.message;
  if (message && message.length > 0) {
    toast.error(message);
  }
};
const handleSubmissionFormErrors = (obj: E2eClientErrorAggregate) => {
  const errors = obj.errors;
  for (const error of errors) {
    if (error.disclosures) {
      // we want a popup modal for them to sign the disclosures
      // handleDisclosureErrors(error.disclosures);
      showForDialog({
        type: ForDialogTypes.Error,
        modal_name: ForModalNames.SignMissingDisclosures,
        data: error.disclosures,
        onAccept: async (disclosures: any) => {
          // save disclsoures
        },
      });
    }
    if (error.form) {
      // we want to highlight the fields that are missing
      // TODO E2E P0 - handle form errors
      handleFormErrors(error);
    }
  }
};

const handleFormErrors = (error: any) => {
  const form = error.form;
  toast.error(`You have issues with the following fields: ${form.missing_fields.join(', ')}`);
};
