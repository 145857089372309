import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  API_E2E_GET_INIT_APP_APPROVAL,
  API_E2E_GET_INIT_APP_APPROVAL_OFFERS,
  API_E2E_GET_INIT_APP_APPROVAL_VERIFY,
} from 'src/api/e2e-request-objects';
import { apiRequest } from 'src/api/request-handler';
import { API_VALIDATE_APPROVAL } from 'src/api/request-objects';
import { Seo } from 'src/components/seo';
import ApprovalSubmissionCard from 'src/e2e-redesign/views/ApproveNewOffer/approval-submission-card';
import OtpVerification from 'src/e2e-redesign/views/CreateAccountNewCustomer/otp-verification';
import useE2eStore from 'src/e2e-store';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';

export default function ApproveNewOffer() {
  console.info('KEEP - New Offer Page');
  const [hasVerified, setHasVerified] = React.useState<boolean | undefined>(undefined);
  const [offer, setOffer] = React.useState();
  const setThankyouPage = useE2eStore((state) => state.setThankYouPage);
  const { fp_unique_id, aaso_id, form_id } = useParams();
  /**
   * LEFTOFF:
   * - wasn't able to quite finish the approval flow, but got close
   * - need to pull all relevant data so I can use the submission card, which is where I was struggling
   * - Save the users selection and then make sure merchant gets updated so they see the latest
   * - Once this is done, I've finished all the core functionality
   * - create bypass for dev so it's not so annoying
   * - remove validate
   * OPTIMIZE:
   * - pass temp cookie once user passes verification check, rather than the nonsense i'm doing
   * - Create a cookie and then an authguard for that
   * - add throttle guard
   * - add ability to resent verification
   * @param values
   */

  useEffect(() => {
    checkIfVerified();
  }, []);
  const verify = async (values: any) => {
    const res = await apiRequest(API_E2E_GET_INIT_APP_APPROVAL_VERIFY, {
      params: { fp_unique_id, aaso_id, form_id },
      body: { code: values },
    });
    if (res?.success) {
      setHasVerified(true);
    }
    if (res.success) {
      toast.success('A verification code has been sent to your mobile number and email');
    } else {
      // R:TODO E2E P1 - redirect them to 404
      toast.error(res.error);
    }
  };
  const sendForApproval = async () => {
    const res = await apiRequest(API_E2E_GET_INIT_APP_APPROVAL, {
      params: { fp_unique_id, aaso_id, form_id },
    });
    if (res.success) {
      toast.success('A verification code has been sent to your mobile number and email');
    } else {
      // R:TODO E2E P1 - redirect them to 404
      toast.error(res.error);
    }
  };
  const checkIfVerified = async () => {
    try {
      const res = await apiRequest(API_VALIDATE_APPROVAL);
      if (res) {
        setHasVerified(true);
      } else {
        setHasVerified(false);
      }
    } catch (e) {
      setHasVerified(false);
    }
  };

  const getOffers = async () => {
    const res = await apiRequest(API_E2E_GET_INIT_APP_APPROVAL_OFFERS, {
      params: { fp_unique_id, aaso_id, form_id },
    });
    if (res.success) {
      // R:TODO E2E P0 - lots to do here see below
      /**
       * - need to format this correctly to match figma
       * - pull in relevant data, I'm just pulling in what I need to accept the offer
       */
      setThankyouPage(res.data.thank_you_page_details);
      setOffer(res.data);
    } else {
      // R:TODO E2E P1 - redirect them to 404
      toast.error(res.error);
    }
  };

  useEffect(() => {
    if (hasVerified) {
      getOffers();
    }
  }, [hasVerified]);
  useEffect(() => {
    if (hasVerified !== undefined) {
      if (!hasVerified) {
        sendForApproval();
      }
    }
  }, [hasVerified]);

  return (
    <>
      <Seo title="Offers" />
      <ThemedContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <PageIntro
              title="Welcome!"
              paragraph="Professional software developed to securely connect you with numerous third-party payment providers."
            />
            {hasVerified ? <></> : <OtpVerification onSubmit={verify} />}
            {offer && (
              <Grid item xs={12} md={6} key={'sdf'}>
                <ApprovalSubmissionCard card_details={offer} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </ThemedContainer>
    </>
  );
}
