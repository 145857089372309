/* eslint-disable */
import * as Yup from 'yup';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneRegExp = /^(\([0-9]{3}\)\s? |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/; // with parenthesis
const ssnRegExp = /^(?!000|666)[0-9][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
const onlyNumbersRegEx = /^[0-9]+$/;
export const alphaNumericOnlyRegex = /[^a-z0-9]/gi;
export const alphaNumericWithSpacesOnlyRegex: any = /^[\w\s]+$/;

export const isValidDate = (s: any) => {
  // Assumes s is "mm/dd/yyyy"
  if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
    return false;
  }
  const parts = s.split('/').map((p: any) => parseInt(p, 10));
  parts[0] -= 1;
  const d = new Date(parts[2], parts[0], parts[1]);
  return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
};

const yupTransform = (type: any, yup: any) => {
  if (type === 'string') {
    return yup.string();
  }
  if (type.includes('max')) {
    const s = type.split(':');
    // s[2] = message
    return yup.max(s[1], s[2]);
  }
  if (type.includes('number')) {
    return yup.number();
  }
  if (type === 'bool') {
    return yup.bool();
  }
  if (type === 'required') {
    return yup.required('Required');
  }
  if (type.includes('min')) {
    const s = type.split(':');
    return yup.min(s[1], s[2]);
  }
  if (type === 'email') {
    const s = type.split(':');
    return yup.email(s[1]); // message
  }
  if (type === 'oneOf') {
    const s = type.split(':');
    const ref = s[1];
    const msg = s[2];
    return yup.oneOf([yup.ref(ref), null], msg);
  }
  if (type === 'phone') {
    return yup.string().matches(phoneRegExp, 'Phone number is not valid');
  }
  if (type === 'ssn') {
    return yup.string().matches(ssnRegExp, 'Social Security is not valid');
  }
  if (type.includes('onlyNumbers')) {
    return yup.string().matches(onlyNumbersRegEx, 'Must only contain numbers');
  }
  if (type.includes('alphaNumericOnly')) {
    return yup.string().matches(alphaNumericOnlyRegex, 'Must be only letters or numbers');
  }
  if (type.includes('alphaNumericWithSpacesOnly')) {
    return yup.string().matches(alphaNumericWithSpacesOnlyRegex, 'Must be only letters or numbers');
  }
  if (type === 'date:null') {
    // ToDo - add this later (after mvp)
    // return yup.date().default(function (date) {
    //   if(date) {
    //     return new Date();
    //   }
    // })
    // return yup.date().nullable().default(null)
    return yup
      .date()
      .nullable()
      .transform((curr: any, orig: any) => {
        if (orig) {
          let b = orig.replace(/\s+/g, '');
          if (b === '//') {
            return null;
          } else {
            return curr;
          }
        } else {
          return null;
        }
      });
    // .required('Mandatory field message')
  }
  if (type === 'date:required') {
    return yup
      .date()
      .required('Date is required in MM/DD/YYYY format')
      .nullable()
      .transform((curr: any, orig: any) => {
        let b = orig.replace(/\s+/g, '');
        // makes the user type out the full year
        const check = isValidDate(orig);
        if (!check) return null;
        if (b === '//') {
          return null;
        } else {
          return curr;
        }
      }, 'Must be in MM/DD/YYYY format');
  }
  if (type === 'nullable') {
    return yup.nullable();
  }
};

const yupLoop = (vs: any, index: any, obj: any): any => {
  const type = vs[index];
  const maxLength = vs.length;
  obj = yupTransform(type, obj);
  if (index >= maxLength - 1) {
    return obj;
  } else {
    return yupLoop(vs, index + 1, obj);
  }
};

const createValidationSchema = (vs: any) => {
  const obj = Yup;
  return yupLoop(vs, 0, obj);
};

export const getValidationSchemas = (schema: any, keys: any) => {
  if (schema) {
    const vSchema = {};
    // create validation schema
    keys?.forEach((k: any) => {
      const validationSchema = schema[k].validationSchema;
      if (validationSchema) {
        //@ts-ignore
        vSchema[k] = createValidationSchema(validationSchema);
      }
    });
    return vSchema;
  } else {
    // No validation
    return {};
  }
};

export const formatForValidationSchemas = (arr: any[]) => {
  // Used for lender-details because this was created after the fact - need to deprecate
  let schema = {};
  arr?.forEach((r) => {
    //@ts-ignore
    schema[r.value] = {
      validationSchema: r.validationSchema,
    };
  });
  return schema;
};
