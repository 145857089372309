import { Box, Card, CardContent } from '@mui/material';
import { LenderDetails } from 'src/e2e-redesign/mock-data/MockLenderDetails';

import SingleLogo from '../SingleLogo';

interface CardProps {
  lender: LenderDetails;
}

const ProviderDetailsCard = ({ lender }: CardProps) => (
  <Card sx={{ height: '100%', padding: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <SingleLogo image={{ src: lender.image_path, alt: lender.name }} />
    </Box>
    <CardContent>
      {lender.product_details.map((detail) => (
        <div dangerouslySetInnerHTML={{ __html: detail.html }} />
      ))}
    </CardContent>
  </Card>
);

export default ProviderDetailsCard;
