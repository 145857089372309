import { Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { fpAuthColors } from '../../theme/fp-auth-colors';

const StyledDivider = styled(Divider)(() => ({
  borderColor: fpAuthColors.fpBirdBlue,
  width: '50%',
}));

const AuthDivider = () => (
  <Stack direction="row" justifyContent="center" sx={{ mt: 6, mb: 6 }}>
    <StyledDivider />
  </Stack>
);

export default AuthDivider;
