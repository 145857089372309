import toast from 'react-hot-toast';

export interface ApiError extends Error {
  statusCode?: number;
  statusMessage?: string;
  body?: any;
}

const toastStyle = {
  borderRadius: '10px',
  background: '#444',
  color: '#fff',
  fontSize: '16px',
};

export const handleError = (error: unknown) => {
  if (error instanceof Error) {
    if (error.message === 'API_ERROR') {
      const apiError = error as ApiError;
      toast.error(`Error ${apiError.statusCode}:\n ${apiError.statusMessage}`, {
        duration: 10000,
        style: toastStyle,
      });
    } else {
      toast.error('An unexpected error occurred', {
        duration: 10000,
        style: toastStyle,
      });
    }
  } else {
    toast.error('An unknown error occurred', {
      duration: 10000,
      style: toastStyle,
    });
  }
};

export function silenceIrrelevantErrors() {
  const originalConsoleError = console.error;

  window.onerror = function (message, source, lineno, colno, error) {
    if (
      (typeof message === 'string' && message.includes('caveat-with-refs-guide')) ||
      (typeof source === 'string' && source.includes('this ref will fail'))
    ) {
      return true;
    }

    originalConsoleError.call(console, message, source, lineno, colno, error);
    return false;
  };

  console.error = function (...args) {
    const shouldSilence = args.some(
      (arg) =>
        typeof arg === 'string' && (arg.includes('caveat-with-refs-guide') || arg.includes('this ref will fail')),
    );

    if (shouldSilence) {
      return;
    }

    originalConsoleError.apply(console, args);
  };
}
