export enum ApplicationStage {
  STARTED = 'Started', // application has been started ()
  DISCLOSURE_PRIVACY = 'DisclosurePrivacy', // applicant is reviewing and accepting privacy policy (welcome page)
  DISCLOSURE_TERMS = 'DisclosureTerms', // applicant is reviewing and accepting terms and conditions
  DISCLOSURE_LENDERS = 'DisclosureLenders', // applicant is reviewing and accepting lenders disclosures
  FORM_STARTED = 'FormStarted', // applicant is filling out the form
  FORM_SUBMITTED = 'FormSubmitted', // form has been submitted by the applicant
  LENDER_SELECTED = 'LenderSelected', // lender has been selected by the applicant
  COMPLETED = 'Completed', // process is completed,
  // NEW E2E stages
  FLOW_STARTED = 'FlowStarted', // applicant has started the flow - aaso object is created at this point
  INITIAL_SELECTION = 'InitialSelection', // applicant has made their initial selection
  SUBMITTED_TO_LENDER = 'SubmittedToLender', // At this point, the user has submitted their application to the lender so they can't do things like edit their form
  SELECTED_PATH = 'SelectedPath', // At this point, the user has selected their path
  THANK_YOU_PAGE = 'ThankYouPage', // user has hit the end of the flow and has hit a thank you page
}

export enum PathType {
  GoodCredit = 'Good Credit',
  ChallengedCredit = 'Challenged Credit',
}
