import React, { useRef } from 'react';
import { Box, Button, FormControl } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Seo } from 'src/components/seo';
import * as Yup from 'yup';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';

const digitFields = Array.from({ length: 6 }, (_, i) => `digit${i + 1}`);

const initialValues = digitFields.reduce((acc: Record<string, string>, field: string) => {
  acc[field] = '';
  return acc;
}, {});

const validationSchema = Yup.object(
  digitFields.reduce((acc: Record<string, Yup.StringSchema>, field: string) => {
    acc[field] = Yup.string()
      .matches(/^[0-9]$/, 'Numbers only')
      .required('Required')
      .test('is-moving', '', function (value, context) {
        const { path, options } = context;
        const nextField = digitFields[digitFields.indexOf(path) + 1];
        if (nextField && options?.context && !options.context.isSubmitting) {
          return true;
        }
        return Yup.string().required('Required').isValidSync(value);
      });
    return acc;
  }, {}),
);

type ValuesType = {
  [key: string]: string;
};

const handleKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  index: number,
  values: ValuesType,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  nextFocus: React.MutableRefObject<number>,
) => {
  if (e.key === 'Backspace' && values[`digit${index + 1}`] === '') {
    if (index > 0) {
      document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  } else if (/^[0-9]$/.test(e.key)) {
    setFieldValue(`digit${index + 1}`, e.key);
    if (index < digitFields.length - 1) {
      nextFocus.current = index + 1;
      document.getElementById(`code-input-${index + 1}`)?.focus();
    }
    e.preventDefault();
  }
};

export default function VerifyCurrentCustomers() {
  console.log('New OTP Page');

  return (
    <>
      <Seo title="Verification" />
      <ThemedContainer>
        <PageIntro title="Verification" paragraph="Enter 6 digit verification numbers" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const verificationCode = Object.values(values).join('');
            setSubmitting(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
          context={{ isSubmitting: false }}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            const nextFocus = useRef(0);
            return (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  gap={2}
                  mb={3}
                >
                  <Box display="flex" justifyContent="center" gap={2}>
                    {digitFields.map((name, index) => (
                      <FormControl key={index}>
                        <Field
                          component={TextField}
                          name={name}
                          type="text"
                          inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center', height: '71px' },
                            id: `code-input-${index}`,
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                            handleKeyDown(e, index, values, setFieldValue, nextFocus)
                          }
                          sx={{
                            width: { xs: '50px', md: '83px' },
                            '& .MuiInputBase-root': {
                              height: { xs: '71px', md: '83px' },
                              display: 'flex',
                              alignItems: 'center',
                            },
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                              padding: '10px',
                              height: '100%',
                              fontSize: '1.25rem', // 1.25rem is approximately 20px
                            },
                          }}
                        />
                      </FormControl>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    color="warning"
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ alignSelf: { xs: 'center', md: 'flex-start' }, marginTop: { xs: '20px', md: '30px' } }}
                  >
                    Verify
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </ThemedContainer>
    </>
  );
}
