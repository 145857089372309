import { CheckboxWithLabel } from "formik-mui";
import { useTheme } from "@mui/material";
import { Field } from "formik";

interface CustomCheckboxProps {
    name: string;
    label: string;
    onClick: () => void;
  }
  
  const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ name, label, onClick }) => {
    const theme = useTheme();
    return (
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name={name}
        Label={{
          label: (
            <button
              type="button"
              onClick={onClick}
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                textDecoration: 'underline',
                color: theme.palette.info.main,
                cursor: 'pointer',
                fontSize: 'inherit',
                fontFamily: 'inherit',
              }}
            >
              {label}
            </button>
          ),
        }}
      />
    );
  };

  export default CustomCheckbox;

