import React, { FC, ReactNode } from 'react';
import { Box, styled, BoxProps } from '@mui/material';

interface CardProps extends BoxProps {
  children: ReactNode;
  backgroundColor?: 'white' | 'border';
}

const StyledBox = styled(Box)<{ backgroundColor: 'white' | 'border' }>(({ theme, backgroundColor }) => ({
  border: backgroundColor === 'white' ? '1px solid #E0E8F5' : 'none',
  borderRadius: '15px',
  padding: theme.spacing(2),
  backgroundColor: backgroundColor === 'white' ? '#FFFFFF' : '#F3F6FB',
}));

const ThemedCard: FC<CardProps> = ({ children, backgroundColor = 'white', ...rest }) => {
  return (
    <StyledBox backgroundColor={backgroundColor} {...rest}>
      {children}
    </StyledBox>
  );
};

export default ThemedCard;

