import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  getStartedLogic_functionsAndVals,
  getStartedLogic_init,
} from 'src/e2e-redesign/views/get-started/logic/get-started-logic';

import LogoGrid from '../../components/LogoGrid';
import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import theme from '../../theme';

export default function LetsGetStarted() {
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  getStartedLogic_init(routingPackage);
  const fnv = getStartedLogic_functionsAndVals(routingPackage);
  const { handleRouting, handleGoBack } = fnv.fn;
  const { multi_paths_exist, disclosureLogos } = fnv.v;

  /**
   * - When they hit "go to provider disclosures"
   *      - update AASO object
   *          - capture that they accepted... even though they aren't accepting
   *          - update the page they should be on
   *      - call routeUser function
   *          - we need to route them to the provider disclosures page
   *              - src/e2e-redesign/views/ProviderDisclosures/ProviderDisclosures.tsx
   *
   * - Resources:
   *    - this page is probably the most similar to the "Welcome Aboard" (src/pages/welcome.tsx)
   *      but it's not the same, because they aren't really agreeing there's just additional legal stuff
   *    - See figma Meineke Flow -> Let's Get Started (multiple mocks)
   */

  return (
    <>
      <Seo title="Let's Get Started" />
      <ThemedContainer>
        <PageIntro title="Let's Get Started" paragraph="OUR VALUED PROVIDERS" />
        <LogoGrid logos={disclosureLogos} />
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px', marginTop: '20px' }}>
          Your security is important to us. The FormPiper platform is designed to securely connect you with our
          third-party payment partners by piping your information directly into our partners' applications for
          processing.
        </Typography>
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px' }}>
          The decision to grant or deny an application is solely determined by each individual provider.
        </Typography>
        <Typography component="p" variant="body1" sx={{ paddingBottom: '20px' }}>
          FormPiper is not a lender or a financial institution, and the decision to grant or deny an application for
          FINANCING OR LEASING is entirely determined by each individual payment provider above after your information
          is directed to such payment providers. FormPiper in no way has any influence on any provider decisions, and
          only serves to more easily facilitate your FINANCING OR LEASING for credit to such third-party payment
          providers.
        </Typography>
        <Box
          sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button variant="contained" color="warning" size="large" onClick={handleRouting}>
            Go to provider disclosures
          </Button>
          {multi_paths_exist && (
            <Button
              type="button"
              onClick={handleGoBack}
              sx={{
                color: theme.palette.text.primary,
                textDecoration: 'underline',
              }}
            >
              Back
            </Button>
          )}
        </Box>
      </ThemedContainer>
    </>
  );
}
