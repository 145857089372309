import { useQuery } from '@tanstack/react-query';
import { API_E2E_GET_DISCLOSURES, API_E2E_GET_FP_DISCLOSURES } from 'src/api/e2e-request-objects';
import { TsQueryI } from 'src/api/query-logic/query-interfaces';
import { createQueryKey } from 'src/api/query-logic/query-utils';
import { apiRequest } from 'src/api/request-handler';

export const queryGetE2eDisclosures = (obj: TsQueryI) => {
  return useQuery({
    queryKey: createQueryKey('get-e2e-disclosures', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_E2E_GET_DISCLOSURES, obj.reqData);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    refetchInterval: 0,
    gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};

export const queryGetFpDisclosures = (obj: TsQueryI) => {
  return useQuery({
    queryKey: createQueryKey('get-fp-e2e-disclosures', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_E2E_GET_FP_DISCLOSURES, obj.reqData);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    refetchInterval: 0,
    gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
