/* eslint-disable */
import differenceInYears from 'date-fns/differenceInYears';
import moment from 'moment';
import {
  FieldConditionActionTypes,
  FieldConditionAllOperatorTypes,
  FieldConditionNumberOperatorTypes,
  FieldConditionRuleInputTypes,
  FieldConditionRulesI,
  FieldConditionRuleTypeTypes,
  FieldConditionsI,
  FieldConditionStringOperatorTypes,
  FieldConditionTypes,
  FieldDetailI,
  FieldDetails,
  FieldDetailTypes,
  RootFieldDetailsI,
} from 'src/interfaces/application.interfaces';
import * as Yup from 'yup';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneRegExp = /^(\([0-9]{3}\)\s? |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/; // with parenthesis
const ssnRegExp = /^(?!000|666)[0-9][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
const onlyNumbersRegEx = /^[0-9]+$/;
export const alphaNumericOnlyRegex = /[^a-z0-9]/gi;
export const alphaNumericWithSpacesOnlyRegex = /^[\w\s]+$/;

export const convertFieldsToEmptyObjects = (fields: string[], convertTo: any = '') => {
  return fields.reduce((a, v) => ({ ...a, [v]: convertTo }), {});
};

export const isValidDate = (s: any) => {
  // Assumes s is "mm/dd/yyyy"
  if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
    return false;
  }
  const parts = s.split('/').map((p: any) => parseInt(p, 10));
  parts[0] -= 1;
  const d = new Date(parts[2], parts[0], parts[1]);
  return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
};

const yupStringFuncs = {
  email: (yupObj: any, params: any, name: any) => {
    const msg = params[1] || 'Must be a valid email';
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .email(msg)
      .max(255);
  },
  max: (yupObj: any, params: any, name: any) => {
    const max = params[1];
    const msg = params[2];
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .max(max, msg);
  },
  min: (yupObj: any, params: any, name: any) => {
    const min = params[1];
    const msg = params[2];
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .min(min, msg);
  },
  oneOf: (yupObj: any, params: any, name: any) => {
    const ref = params[1];
    const msg = params[2] || 'Must be one of';
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .oneOf([yupObj.ref(ref), null], msg);
  },
  phone: (yupObj: any, params: any, name: any) => {
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .matches(phoneRegExp, 'Phone number is not valid');
  },
  ssn: (yupObj: any, params: any, name: any) => {
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .matches(ssnRegExp, 'Social Security is not valid');
  },
  onlyNumbers: (yupObj: any, params: any, name: any) => {
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .matches(onlyNumbersRegEx, 'Must only contain numbers');
  },
  alphaNumericOnly: (yupObj: any, params: any, name: any) => {
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .matches(alphaNumericOnlyRegex, 'Must be only letters or numbers');
  },
  alphaNumericWithSpacesOnly: (yupObj: any, params: any, name: any) => {
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .matches(alphaNumericWithSpacesOnlyRegex, 'Must be only letters or numbers');
  },
  nullable: (yupObj: any, params: any, name: any) => {
    return yupObj.nullable(true);
  },
  required: (yupObj: any, params: any, name: any) => {
    // const labelName = createLabelFromSnakeCaseText(fieldName);
    return yupObj.required('Field is required');
  },
  passWdLetterNumberRestrictSpecialChar: (yupObj: any, params: any, name: any) => {
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%]{8,30}$/,
        'Password must be 8-30 characters with at least one letter and one number, and no special characters except !, @, #, $ or %',
      );
  },
  isCurrencyGreaterThan: (yupObj: any, params: any, name: any) => {
    const min = params[1];
    return yupObj
      .transform((value: any) => value.replace(/[$,]/g, '')) // Strip out $ and ,
      .test('is-valid-number', `Amount must be a number greater than $${min}`, (value: any) => {
        return !isNaN(value) && Number(value) > min;
      })
      .required('Amount is required');
  },
};

const yupNumberFuncs = {
  nullable: (yupObj: any, params: any, name: any) => {
    return (
      yupObj
        .nullable(true)
        // checking self-equality works for NaN, transforming it to null
        .transform((_: any, val: any) => (val === Number(val) ? val : null))
    );
  },
  required: (yupObj: any, params: any, name: any) => {
    // const labelName = createLabelFromSnakeCaseText(fieldName);
    return yupObj.required().typeError('Field is required');
  },
  max: (yupObj: any, params: any, name: any) => {
    const max = params[1];
    const msg = params[2];
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .max(max, msg);
  },
  min: (yupObj: any, params: any, name: any) => {
    const min = params[1];
    const msg = params[2];
    return yupObj
      .transform((currentValue: any, originalValue: any) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable(true)
      .min(min, msg);
  },
};

const yupDateFuncs = {
  nullable: (yupObj: any, params: any, name: any) => {
    return yupObj.nullable(true).transform((curr: any, orig: any) => {
      if (orig) {
        let b = orig.replace(/\s+/g, '');
        if (b === '//') {
          return null;
        } else {
          return curr;
        }
      } else {
        return null;
      }
    });
  },
  required: (yupObj: any, params: any, name: any) => {
    return yupObj
      .required('Date is required in MM/DD/YYYY format')
      .nullable()
      .transform((curr: any, orig: any) => {
        let b = orig.replace(/\s+/g, '');
        // makes the user type out the full year
        const check = isValidDate(orig);
        if (!check) return undefined;
        if (b === '//') {
          return null;
        } else {
          return curr;
        }
      }, 'Must be in MM/DD/YYYY format');
  },
  minAge: (yupObj: any, params: any, name: any) => {
    const value = params[1];
    return yupObj.test(name, `Must be older than ${value}`, (v: any) => {
      return differenceInYears(new Date(), new Date(v)) >= value;
    });
  },
};

const yupObjectFuncs = {
  nullable: (yupObj: any, params: any, name: any) => {
    return yupObj.nullable();
  },
  required: (yupObj: any, params: any, name: any) => {
    // const labelName = createLabelFromSnakeCaseText(fieldName);
    return yupObj.required().typeError('Field is required');
  },
};

const getRootDetailObject = (f: FieldDetailI, fieldDetails: FieldDetails = FieldDetails.final_details) => {
  switch (fieldDetails) {
    case FieldDetails.final_details:
      return f.final_details;
    default:
      return f.default_details;
  }
};

const handleValidation = (validation: any, yupTypeFunctions: any, yupObj: any, keyName: any) => {
  const doesIncludeSplitParams = validation.includes(':');
  let validationName = validation;
  const splitParams = validation.split(':');
  if (doesIncludeSplitParams) {
    // validation name will always be the first
    validationName = splitParams[0];
  }
  if (yupTypeFunctions[validationName]) {
    yupObj = yupTypeFunctions[validationName](yupObj, splitParams, keyName);
  }
  return yupObj;
};

const handleIsRequired = (
  yupObj: any,
  detailObject: RootFieldDetailsI,
  yupTypeFunctions: any,
  required_fields: string[],
) => {
  const { required, type, name } = detailObject;
  if (required_fields && required_fields.length > 0) {
    if (required_fields.includes(name)) {
      // user specifically wanted it to be required
      yupObj = yupTypeFunctions['required'](yupObj, null, name);
    } else {
      // make it nullable by default
      yupObj = yupTypeFunctions['nullable'](yupObj, null, name);
    }
  } else {
    if (required) {
      yupObj = yupTypeFunctions['required'](yupObj, null, name);
    }
  }

  return yupObj;
};

const convertFieldValue = (value: any, type: FieldConditionRuleTypeTypes) => {
  try {
    switch (type) {
      case FieldConditionRuleTypeTypes.string:
        return String(value);
      case FieldConditionRuleTypeTypes.integer:
        return parseInt(value);
      case FieldConditionRuleTypeTypes.double:
        return parseFloat(value);
      case FieldConditionRuleTypeTypes.date:
      case FieldConditionRuleTypeTypes.time:
      case FieldConditionRuleTypeTypes.datetime:
        return moment(value); // this should not be used
      case FieldConditionRuleTypeTypes.boolean:
        if (typeof value === 'string') {
          if (value === 'true') {
            return true;
          } else if (value === 'false') {
            return false;
          } else {
            return Boolean(value);
          }
        } else {
          return Boolean(value);
        }
    }
  } catch (e) {
    // console.log('Failed to convert field value', e);
    return value;
  }
};
const operatorFunction = (
  operator: FieldConditionStringOperatorTypes | FieldConditionNumberOperatorTypes | FieldConditionAllOperatorTypes,
  fieldValue: any,
  checkValue: any,
  ruleType: FieldConditionRuleTypeTypes,
) => {
  // convertFieldValue(args[index], rule.type);
  switch (operator) {
    // Any
    case FieldConditionAllOperatorTypes.is_null:
      return convertFieldValue(fieldValue, ruleType) == null;
    case FieldConditionAllOperatorTypes.is_not_null:
      return convertFieldValue(fieldValue, ruleType) !== null;
    case FieldConditionAllOperatorTypes.equal:
      return convertFieldValue(fieldValue, ruleType) == convertFieldValue(checkValue, ruleType);
    case FieldConditionAllOperatorTypes.not_equal:
      return convertFieldValue(fieldValue, ruleType) !== convertFieldValue(checkValue, ruleType);
    case FieldConditionAllOperatorTypes.in:
      return convertFieldValue(fieldValue, ruleType).includes(convertFieldValue(checkValue, ruleType));
    case FieldConditionAllOperatorTypes.not_in:
      return !convertFieldValue(fieldValue, ruleType).includes(convertFieldValue(checkValue, ruleType));

    // Strings
    case FieldConditionStringOperatorTypes.contains:
      return convertFieldValue(fieldValue, ruleType).includes(convertFieldValue(checkValue, ruleType));
    case FieldConditionStringOperatorTypes.not_contains:
      return !convertFieldValue(fieldValue, ruleType).includes(convertFieldValue(checkValue, ruleType));
    case FieldConditionStringOperatorTypes.begins_with:
      return convertFieldValue(fieldValue, ruleType).startsWith(convertFieldValue(checkValue, ruleType));
    case FieldConditionStringOperatorTypes.not_begins_with:
      return !convertFieldValue(fieldValue, ruleType).startsWith(convertFieldValue(checkValue, ruleType));
    case FieldConditionStringOperatorTypes.ends_with:
      return convertFieldValue(fieldValue, ruleType).endsWith(convertFieldValue(checkValue, ruleType));
    case FieldConditionStringOperatorTypes.not_ends_with:
      return !convertFieldValue(fieldValue, ruleType).endsWith(convertFieldValue(checkValue, ruleType));
    case FieldConditionStringOperatorTypes.is_empty:
      return convertFieldValue(fieldValue, ruleType).length <= 0;
    case FieldConditionStringOperatorTypes.is_not_empty:
      return convertFieldValue(fieldValue, ruleType).length > 0;
    // Numbers
    case FieldConditionNumberOperatorTypes.less:
      return convertFieldValue(fieldValue, ruleType) < convertFieldValue(checkValue, ruleType);
    case FieldConditionNumberOperatorTypes.less_or_equal:
      return convertFieldValue(fieldValue, ruleType) <= convertFieldValue(checkValue, ruleType);
    case FieldConditionNumberOperatorTypes.greater:
      return convertFieldValue(fieldValue, ruleType) > convertFieldValue(checkValue, ruleType);
    case FieldConditionNumberOperatorTypes.greater_or_equal:
      return convertFieldValue(fieldValue, ruleType) >= convertFieldValue(checkValue, ruleType);
    case FieldConditionNumberOperatorTypes.between:
      const firstValue = convertFieldValue(checkValue[0], ruleType);
      const secondValue = convertFieldValue(checkValue[1], ruleType);
      const f = convertFieldValue(fieldValue, ruleType);
      return f >= firstValue && f <= secondValue;
    case FieldConditionNumberOperatorTypes.not_between:
      const fValue = convertFieldValue(checkValue[0], ruleType);
      const sValue = convertFieldValue(checkValue[1], ruleType);
      const fi = convertFieldValue(fieldValue, ruleType);
      return fi < fValue || fi > sValue;
  }
};
const checkRule = (fieldValue: any, checkValue: any, rule: FieldConditionRulesI) => {
  const operator:
    | FieldConditionStringOperatorTypes
    | FieldConditionNumberOperatorTypes
    | FieldConditionAllOperatorTypes = rule.operator;
  const input: FieldConditionRuleInputTypes = rule.input;
  const type: FieldConditionRuleTypeTypes = rule.type;
  return operatorFunction(operator, fieldValue, checkValue, type);
};

const checkFinalRuling = (rulings: { [k: string]: boolean }, ruleIds: string[], condition: FieldConditionTypes) => {
  if (condition === FieldConditionTypes.AND) {
    let isTrue = true;
    for (const ruleId of ruleIds) {
      const ruling = rulings[ruleId];
      if (!ruling) {
        isTrue = false;
        break;
      }
    }
    return isTrue;
  } else if (condition === FieldConditionTypes.OR) {
    let isTrue = false;
    for (const ruleId of ruleIds) {
      const ruling = rulings[ruleId];
      if (ruling) {
        isTrue = true;
        break;
      }
    }
    return isTrue;
  }
};

export const validateRules = (c: FieldConditionsI, ruleIds: string[], args: string[]) => {
  // As of 10/31/22 this has only been tested for single rules
  let index = 0;
  const condition: FieldConditionTypes = c.condition;
  const rules: FieldConditionRulesI[] = c.rules;
  const rulings = convertFieldsToEmptyObjects(ruleIds, false);

  for (const rule of rules) {
    const ruleId = ruleIds[index];
    const fieldValue = args[index]; // the value of the field being tested
    const checkValue = rule.value; // the rule value
    //@ts-ignore
    rulings[ruleId] = checkRule(fieldValue, checkValue, rule);
    index++;
  }

  return checkFinalRuling(rulings, ruleIds, condition);
};

export const checkValidateRules = (c: FieldConditionsI, ruleIds: string[], formValue: any, name: string) => {
  // As of 10/31/22 this has only been tested for single rules
  let index = 0;
  const condition: FieldConditionTypes = c.condition;
  const rules: FieldConditionRulesI[] = c.rules;
  const rulings = convertFieldsToEmptyObjects(ruleIds, false);
  // const rulings = {
  //   // [name]: false,
  // };
  for (const rule of rules) {
    const ruleId = ruleIds[index];
    const checkValue = rule.value; // the rule value
    //@ts-ignore
    rulings[ruleId] = checkRule(formValue, checkValue, rule);
    index++;
  }

  return checkFinalRuling(rulings, ruleIds, condition);
};

const getThenAction = (actionType: FieldConditionActionTypes, schema: any) => {
  try {
    switch (actionType) {
      case FieldConditionActionTypes.require:
        return schema.required();
      case FieldConditionActionTypes.show:
      case FieldConditionActionTypes.remove:
      default:
        return schema.notRequired();
    }
  } catch (e) {
    console.log('then failed', e);
  }
};

const createValidationBasedOnConditions = (yupObj: any, detailObject: RootFieldDetailsI, yupTypeFunctions: any) => {
  /**
   * This DOES not account for groupings - so if we ever implement jquery query builder, we will need to take that out
   */
  const { name } = detailObject;
  const conditions: FieldConditionsI = detailObject.condition;
  const ruleIds = conditions.rules.map((r) => r.id);
  const actionType: FieldConditionActionTypes = conditions.type;
  // const ruleIds = ['time_at_employment_years', 'time_at_employment_months'];
  yupObj = yupObj.when([...ruleIds], {
    //@ts-ignore
    is: (formValue) => {
      return checkValidateRules(conditions, ruleIds, formValue, name);
    },
    then: (schema: any) => getThenAction(actionType, schema),
    // then: () => yupObj.required('Type of Investigation'),
    // maybe default to - not sure yet handleLoopValidations(yupObj, detailObject, yupTypeFunctions);
    otherwise: (schema: any) => schema.notRequired(),
  });
  return yupObj;
};

const handleLoopValidations = (yupObj: any, detailObject: RootFieldDetailsI, yupTypeFunctions: any) => {
  const { validations, required, condition, name } = detailObject;
  for (const validation of validations) {
    yupObj = handleValidation(validation, yupTypeFunctions, yupObj, name);
  }
  return yupObj;
};
const createYupShapeLoop = (
  yupObj: any,
  detailObject: RootFieldDetailsI,
  yupTypeFunctions: any,
  required_fields: string[],
) => {
  try {
    const { validations, required, condition, name } = detailObject;
    // Handle conditions
    if (condition && !required) {
      // conditions are applied ONLY if 'required != true' OR no lender has this as a requirement
      yupObj = createValidationBasedOnConditions(yupObj, detailObject, yupTypeFunctions);
    } else {
      // if no conditions, handle validations normally
      yupObj = handleLoopValidations(yupObj, detailObject, yupTypeFunctions);
      // do examples
      // yupObj = handleValidation('max:2', yupTypeFunctions, yupObj);
      yupObj = handleIsRequired(yupObj, detailObject, yupTypeFunctions, required_fields);
    }
    return yupObj;
  } catch (e) {
    // console.log(detailObject);
    // console.log(e);
  }
};

const getFieldValidationShape = (detailObject: RootFieldDetailsI, required_fields: string[]) => {
  const yup = Yup;
  switch (detailObject.type) {
    case FieldDetailTypes.number:
      return createYupShapeLoop(
        yup.number().typeError('Must be a number'),
        detailObject,
        yupNumberFuncs,
        required_fields,
      );
    case FieldDetailTypes.date:
      return createYupShapeLoop(yup.date(), detailObject, yupDateFuncs, required_fields);
    case FieldDetailTypes.object:
      return createYupShapeLoop(yup.object(), detailObject, yupObjectFuncs, required_fields);
    case FieldDetailTypes.string:
    default:
      return createYupShapeLoop(yup.string(), detailObject, yupStringFuncs, required_fields);
  }
};

export const newGetFormValidationSchema = (
  formFieldDetails: FieldDetailI[],
  fieldDetails: FieldDetails = FieldDetails.final_details,
  required_fields: string[] = [],
) => {
  let yupShape = {};
  formFieldDetails?.forEach((f: FieldDetailI) => {
    const detailObject: any = getRootDetailObject(f, fieldDetails);
    const fieldShape = getFieldValidationShape(detailObject, required_fields);
    yupShape = { ...yupShape, [f.id]: fieldShape };
  });
  return Yup.object().shape(yupShape);
};
