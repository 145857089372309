import type { User } from 'src/types/user';
import { decode } from 'src/utils/jwt';

import { SignInResponseI } from '../../contexts/auth/jwt-context';
import { apiRequest } from '../request-handler';
import { API_LOGIN, API_REGISTER, API_SIGN_OUT, API_VALIDATE_USER } from '../request-objects';

const STORAGE_KEY = 'users';

const getPersistedUsers = (): User[] => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data) as User[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

type SignInRequest = {
  email: string;
  password: string;
};

type SignUpRequest = {
  email: string;
  name: string;
  password: string;
};

type MeRequest = {
  accessToken: string;
};

type MeResponse = Promise<User>;

class AuthApi {
  async signIn(request: SignInRequest): Promise<SignInResponseI> {
    const { email, password } = request;
    const b = await apiRequest(API_LOGIN, {
      body: { email, password },
    });
    return b;
  }

  async signUp(request: SignUpRequest): Promise<User> {
    const { email, name, password } = request;
    return await apiRequest(API_REGISTER, {
      body: { email, password, name },
    });
  }

  async signOut(): Promise<User> {
    return await apiRequest(API_SIGN_OUT);
  }

  me(request: MeRequest): MeResponse {
    const { accessToken } = request;

    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const decodedToken = decode(accessToken) as any;

        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...getPersistedUsers()];

        // Find the user
        const { userId } = decodedToken;
        const user = mergedUsers.find((user) => user.id === userId);

        if (!user) {
          reject(new Error('Invalid authorization token'));
          return;
        }

        resolve({
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          plan: user.plan,
        });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  // R:TODO E2E P2 - can we put this somewhere else?
  /**
   * the issue is that it runs on every page, but we don't need to run it on every page
   */
  async validate(): Promise<User | undefined> {
    const url = window.location.href;
    if (!url.includes('applicant-approve-offers')) {
      return apiRequest(API_VALIDATE_USER);
    }
  }
}

export const authApi = new AuthApi();
