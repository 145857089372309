import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';

interface Context {
  socket: Socket | null;
  status: string;
  sendMessage: (event: string, data: any) => void;
  onCallback: (event: string, callback: any) => void;
  off: (event: string) => void;
}

export enum SocketEvents {
  'WS_EVENT_UPDATE_API_STATUS_PAGE' = 'update-api-status-page',
}

interface WebSocketProviderProps {
  children: ReactNode;
}

const WebSocketContext = createContext<Context | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a WebSocketProvider');
  }
  return context;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [status, setStatus] = useState('disconnected');

  console.log('socket status', status);
  useEffect(() => {
    setStatus('connecting');
    const query: any = {};
    // console.log('REACT_APP_WS_URL', process.env.REACT_APP_WS_URL);
    const newSocket = io(process.env.REACT_APP_WS_URL!, {
      reconnectionDelayMax: 10000,
      transports: ['websocket'],
      // upgrade: false,
      query: query,
    });

    newSocket.on('connect', () => {
      setStatus('connected');
    });
    newSocket.on('disconnect', () => {
      setStatus('disconnected');
    });
    newSocket.on('connect_error', () => {
      setStatus('error');
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  const sendMessage = useCallback(
    (event: string, data: any) => {
      if (!socket) return;
      socket.emit(event, data);
    },
    [socket],
  );

  const onCallback = async (eventName: string, callback: any) => {
    console.log('[[onCallback]]');
    if (socket) {
      // console.log('[[INSTALL: event]]', eventName);
      console.log('on Call back');
      socket.on(eventName, (payload) => {
        console.log('event name', eventName);
        // console.log('[[LISTENER: event/payload]]', eventName, payload);
        if (callback) {
          // console.log('[[⚡⚡ callback ⚡⚡]]', eventName, payload);

          callback(payload);
        }
      });
    }
  };

  const off = async (eventName: string) => {
    if (socket) {
      console.log('[[❌ UNINSTALL ❌]]', eventName);
      socket.off(eventName);
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket, status, sendMessage, onCallback, off }}>
      {children}
    </WebSocketContext.Provider>
  );
};
