/* eslint-disable */
import React from 'react';
import MaskedInput from 'react-text-mask';

const FullDateMask = ({ inputRef, ...other }: any) => (
  <MaskedInput
    {...other}
    mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    placeholder="MM/DD/YYYY"
    guide={false}
  />
);

export default FullDateMask;
