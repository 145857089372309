import React from 'react';
import styles from './ThemedHtml.module.css';

interface ThemedHtmlProps {
    htmlContent: string;
}
// Find a better way to add the theme styles to imported HTML.  This is not setting the color correctly.
const ThemedHtml: React.FC<ThemedHtmlProps> = ({ htmlContent }) => {
    return (
        <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default ThemedHtml;