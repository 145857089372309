export enum E2eACLControlE {
  franchise_e2e_configuration = 'franchise_e2e_configuration',
  processor_e2e_configuration = 'processor_e2e_configuration',
  laa_e2e_configuration = 'laa_e2e_configuration',
  freemium_e2e_configuration = 'freemium_e2e_configuration',
  roles_e2e_configuration = 'roles_e2_configuration',
  org_e2e_configuration = 'org_e2e_configuration',
}

export enum E2ePathSubmissionTypesE {
  'waterfall' = 'Waterfall',
  'shotgun' = 'Shotgun',
}

export type E2eAclStoreSettingFpRoleTypes = 'Admin' | 'User'; // Add more roles as needed
