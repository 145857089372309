import React from 'react';
import ErrorPage from 'src/pages/error';
import { reportErrorToSlack } from 'src/utils/general';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    } else {
      reportErrorToSlack(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage errorCode={500} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
