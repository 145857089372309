import React, { FC } from 'react';
import { Box } from '@mui/material';

interface SingleLogoProps {
  image: {
    src: string;
    alt: string;
  };
}

const SingleLogo: FC<SingleLogoProps> = ({ image }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      <Box
        component="img"
        src={image.src}
        alt={image.alt}
        sx={{
          width: 'auto',
          height: '35px',
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

export default SingleLogo;
