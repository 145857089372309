import React from 'react';
import { Button, CardContent, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ThemedCard from 'src/e2e-redesign/components/ThemedCard';

interface WaterfallContinuePipingCardProps {
  onClick: () => void;
}

export default function MoveForwardCard({ onClick }: WaterfallContinuePipingCardProps) {
  const theme = useTheme();
  return (
    <ThemedCard sx={{ maxWidth: '446px', backgroundColor: '#F3F6FB', height: 200, marginTop: 2, marginLeft: 3 }}>
      {/* <CardHeader */}
      {/*   title={ */}
      {/*     <Typography variant="h5" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}> */}
      {/*       Not satisfied with our offers? */}
      {/*     </Typography> */}
      {/*   } */}
      {/*   sx={{ paddingBottom: 0 }} */}
      {/* /> */}
      <CardContent>
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '40px' }}>
          Would you like to move forward with additional providers?
        </Typography>
        <Button variant="contained" color="warning" fullWidth onClick={onClick}>
          Continue
        </Button>
      </CardContent>
    </ThemedCard>
  );
}
