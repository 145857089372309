import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Drawer, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { MockLenderDetails } from 'src/e2e-redesign/mock-data/MockLenderDetails';
import { selectAppLogic_functionsAndVals } from 'src/e2e-redesign/views/SelectedApplicationType/logic/select-app-logic';
import { E2eAppSettingsFormPathsI } from 'src/interfaces/application-settings.interfaces';

import ThemedContainer from '../ThemedContainer';
import ProviderDetailsAccordion from './ProviderDetailsAccordion';
import ProviderDetailsCard from './ProviderDetailsCard';

interface ProviderDetailsDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  path: E2eAppSettingsFormPathsI;
}

export default function ProviderDetailsDrawer({ isDrawerOpen, toggleDrawer, path }: ProviderDetailsDrawerProps) {
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  const fnv = selectAppLogic_functionsAndVals(routingPackage);
  const { handleSelectPathType, getLenderDetailsWithLogos } = fnv.fn;
  const { lenderDetailsWithLogos } = fnv.v;
  const [expanded, setExpanded] = useState<string | false>(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    getLenderDetailsWithLogos(path);
  }, [path]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer} sx={{ width: '100%' }} variant="temporary">
      <Box sx={{ width: '100vw', height: '100%', display: 'flex', flexDirection: 'column' }} role="presentation">
        <ThemedContainer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ padding: 2, flexGrow: 1 }}>
            <Typography variant="h1" sx={{ marginBottom: 2 }}>
              {path.name}
            </Typography>
            {isMobile ? (
              lenderDetailsWithLogos.map((lender: any, index: number) => (
                <ProviderDetailsAccordion
                  lender={lender}
                  index={index}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              ))
            ) : (
              <Grid container spacing={6}>
                {lenderDetailsWithLogos.map((lender: any, index: number) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <ProviderDetailsCard lender={lender} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="warning" size="large" onClick={() => handleSelectPathType(path)}>
              {path.button_text}
            </Button>
          </Box>
        </ThemedContainer>
      </Box>
    </Drawer>
  );
}
