import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

interface CleanButtonProps extends ButtonProps {
  ghost?: boolean;
}

const StyledPrimaryButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'ghost',
})<CleanButtonProps>(({ theme, ghost, variant = 'contained' }) => ({
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  background: variant == 'contained' ? '#243F8F' : 'transparent',
  ...(ghost && {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
  }),
}));

export const CleanButton: React.FC<CleanButtonProps> = ({ ghost, children, ...props }) => {
  return (
    <StyledPrimaryButton ghost={ghost} {...props}>
      {children}
    </StyledPrimaryButton>
  );
};
