import React from 'react';
import PageIntro from 'src/e2e-redesign/components/PageIntro';
import { useAuth } from 'src/hooks/use-auth';

interface DashboardIntroProps {
  customTitle?: string; // Optional custom title
  customParagraph?: string; // Optional custom paragraph
}

const DashboardIntro: React.FC<DashboardIntroProps> = ({ customTitle, customParagraph }) => {
  const { user } = useAuth();
  const capitalize = (s?: string | null): string =>
    typeof s === 'string' && s ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() : '';

  const makePossessive = (lastName: string | null | undefined): string => {
    if (!lastName) return '';
    return lastName.toLowerCase().endsWith('s') ? `${lastName}'` : `${lastName}'s`;
  };

  const applicantFirstName = capitalize(user?.first_name);
  const applicantLastName = makePossessive(capitalize(user?.last_name));
  const completeGreeting = customTitle || `${applicantFirstName} ${applicantLastName} Dashboard`;
  const email = user?.email;

  return <PageIntro title={completeGreeting} paragraph={customParagraph || email} />;
};

export default DashboardIntro;
