import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#243F8F',
    },
    secondary: {
      main: '#E0E8F5',
    },
    warning: {
      main: '#F4BF4F',
    },
    background: {
      default: '#ffffff',
    },
    info: {
      main: '#5FB2FF',
    },
    text: {
      primary: '#677292',
    },
    success: {
      main: '#60C287',
    },
    error: {
      main: '#FF5757',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '1.3rem',
      fontWeight: 700,
      lineHeight: '1.51rem',
      marginBottom: '10px',
      color: '#243F8F',
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
        lineHeight: '2.5rem',
      },
    },
    h2: {
      fontSize: '1.3rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
      marginBottom: '10px',
      color: '#243F8F',
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
        lineHeight: '2rem',
      },
    },
    body1: {
      fontSize: '1rem', // 1rem equivalent to 16px
      lineHeight: '1.25rem',
      fontWeight: 400,
      color: '#677292',
      '@media (min-width:600px)': {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root': {
          '--primary-color': '#1976d2',
          '--secondary-color': '#E0E8F5',
          '--warning-color': '#F4BF4F',
          '--info-color': '#5FB2FF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          textTransform: 'uppercase',
          padding: '12px 16px',
          fontWeight: 700,
          fontSize: '0.875rem', // 0.875rem equivalent to 14px to match Figma
        },
        containedPrimary: {
          backgroundColor: 'theme.palette.primary.main',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#115293',
          },
        },
        containedSecondary: {
          backgroundColor: 'theme.palette.secondary.main',
          color: '#1976d2',
          '&:hover': {
            backgroundColor: 'theme.palette.secondary.main',
          },
        },
        containedWarning: {
          backgroundColor: 'theme.palette.warning.main',
          color: '#243F8F',
          '&:hover': {
            backgroundColor: '#dbab47',
          },
        },
        containedSuccess: {
          backgroundColor: 'theme.palette.success.main',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'theme.palette.success.main',
          },
        },
        sizeLarge: {
          width: '350px',
        },
        sizeSmall: {
          width: '180px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#E0E8F5',
          '&.Mui-checked': {
            color: '#5FB2FF',
          },
          '& .MuiSvgIcon-root': {
            backgroundColor: '#E0E8F5',
            borderRadius: '4px',
            border: '1px solid #E0E8F5',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          color: '#243F8F',
        },
        input: {
          fontSize: '16px', // IMPORTANT: DO NOT CHANGE THIS. Smaller than 16px will cause Safari IPhone to auto zoom inputs
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          color: '#243F8F',
        },
        input: {
          fontSize: '16px', // IMPORTANT: DO NOT CHANGE THIS. Smaller than 16px will cause Safari IPhone to auto zoom inputs
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontSize: '16px', // IMPORTANT: DO NOT CHANGE THIS. Smaller than 16px will cause Safari IPhone to auto zoom inputs
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          typography: 'h2',
          color: '#243F8F',
          fontWeight: 700,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '15px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '16px', // IMPORTANT: DO NOT CHANGE THIS. Smaller than 16px will cause Safari IPhone to auto zoom inputs
        },
      },
    },
  },
});

export default theme;
