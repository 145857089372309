import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface RenderDefinitionItemProps {
  term: string;
  definition: string | number | undefined;
}

export default function OfferDefinitionItem({ term, definition }: RenderDefinitionItemProps) {
  const theme = useTheme();

  if (definition === undefined || definition === '') return null;
  return (
    <>
      <Typography
        component="dt"
        variant="body1"
        style={{
          color: theme.palette.text.primary,
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        {term}
      </Typography>
      <Typography
        component="dd"
        variant="body1"
        style={{
          color: theme.palette.primary.main,
          margin: 0,
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          whiteSpace: 'normal',
          overflow: 'visible',
          textOverflow: 'clip',
        }}
      >
        {definition}
      </Typography>
    </>
  );
}
