/* eslint-disable */
import React, { useState } from 'react';

type ModalDetails = {
  modal: JSX.Element;
  title?: string;
  fullScreen?: boolean;
};

let modals: ModalDetails[] = [];
let setModals: React.Dispatch<React.SetStateAction<ModalDetails[]>>;

const updateModals = (components: ModalDetails[]) => {
  if (setModals) {
    setModals(components);
  } else {
    modals = [...components];
  }
};

export const pushModal = (modal: JSX.Element, title?: string, fullScreen?: boolean) => {
  const modalDetails: ModalDetails = { modal, title, fullScreen };
  modals.push(modalDetails);
  modals = [...modals];
  updateModals(modals);
};

export const popModal = () => {
  modals.pop();
  modals = [...modals];
  updateModals(modals);
};

export const clearModals = () => {
  modals = [];
  updateModals(modals);
};

export const modalCounts = (): number => {
  return modals.length;
};

export interface IModalManagerProps {
  container?: React.FC;
  baseZIndex?: number;
}

export const ModalManager = (props: IModalManagerProps) => {
  const [modalComponents, setModalComponents] = useState(modals);

  setModals = setModalComponents;

  if (!modalComponents.length) {
    return null;
  }

  return (
    <>
      {modalComponents.map((details, index) => {
        return (
          <Modal
            key={`modal-manager-${index}`}
            container={props.container}
            component={details.modal}
            title={details.title}
            fullScreen={details.fullScreen}
          />
        );
      })}
    </>
  );
};

type ContainerProps = {
  title?: string;
  fullScreen?: boolean;
};

interface IModalProps {
  container?: React.FC<ContainerProps>;
  component: JSX.Element;
  title?: string;
  fullScreen?: boolean;
}

const Modal = ({ container, component, title, fullScreen }: IModalProps): JSX.Element => {
  const Container = container;
  if (Container) {
    return (
      // @ts-ignore
      <Container title={title} fullScreen={fullscreen}>
        {component}
      </Container>
    );
  } else {
    return component;
  }
};

export default ModalManager;
