import { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormikHelpers, useFormik } from 'formik';
import { isRedirect } from 'node-fetch';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthDivider from 'src/components/auth/auth-divider';
import FPAuthHelperLinksWrapper from 'src/components/auth/fp-auth-helper-links';
import OTPModal from 'src/components/modals/otp-modal/otp-modal';
import { RouterLink } from 'src/components/router-link';
import type { AuthContextType } from 'src/contexts/auth/jwt-context';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import PageIntro from 'src/e2e-redesign/components/PageIntro';
import { NewLoginFunctionsI } from 'src/e2e-redesign/views/NewLogin/logic/new-login-logic';
import { NewE2ERoutes } from 'src/enums/routes.enums';
import { useAuth } from 'src/hooks/use-auth';
import { useMounted } from 'src/hooks/use-mounted';
import { fpAuthColors } from 'src/theme/fp-auth-colors';
import type { Page as PageType } from 'src/types/page';
import * as Yup from 'yup';

interface Values {
  email: string;
  password: string;
  submit: null;
}

const initialValues: Values = {
  email: '',
  password: '',
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string().max(255).required('Password is required'),
});

interface NewLoginFormProps {
  fnv: NewLoginFunctionsI;
}

const NewLoginForm: PageType = (props: NewLoginFormProps) => {
  const { handleAASORedirect } = props.fnv.fn;
  const isMounted = useMounted();
  const routingPackage: E2eLogicObjectsWithParams = e2eLogicUtils_getBaseRoutingLogicObjects();
  const { aasoContext } = routingPackage;
  const navigate = useNavigate();
  const location = useLocation();
  const [signInCalled, setSignInCalled] = useState(false);
  const [openOtp, setOpenOtp] = useState<boolean>(false);
  const [redirectOnly, setRedirectOnly] = useState(false);
  const [fullRedirectLink, setFullRedirectLink] = useState<string>();
  const { signIn } = useAuth<AuthContextType>();
  const [showPassword, setShowPassword] = useState(false);
  const { aaso } = aasoContext;
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const isValidUrl = (str: any) => {
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const returnTo = params.get('returnTo');

    // TODO E2E P4 - we'll have to account for other redirects, this is where we do it
    if (returnTo) {
      const returnToUrl = decodeURIComponent(returnTo);
      if (isValidUrl(returnToUrl)) {
        const url = new URL(returnToUrl);
        const searchParams = new URLSearchParams(url.search);
        const pullOffer = searchParams.get('pullOffer');
        if (pullOffer && url) {
          setRedirectOnly(true);
          // const testString =
          //   'http://localhost:3000/primary-store--2/fh2Wss72uEzzXOW2924UPxtTWx2cta/1-1/66a128ecf71debfebed5ddbd/provider-responses?pullOffer=true&lenderId=31dsaToken=072XQT5T&appStatus=APPROVED';
          setFullRedirectLink(returnToUrl);
        }
      }
    }
  }, [location, navigate]);

  useEffect(() => {
    if (isMounted() && !openOtp && signInCalled) {
      if (!redirectOnly) {
        // e2e path, we need to pull aaso
        handleAASORedirect();
      } else {
        // normal redirect
        if (fullRedirectLink) {
          window.location.href = fullRedirectLink;
        }
      }
    } else {
    }
  }, [openOtp, signInCalled, aaso?.current_stage, aaso?._id, redirectOnly, fullRedirectLink]);

  const handleSubmit = async (values: Values, helpers: FormikHelpers<Values>): Promise<void> => {
    try {
      await signIn(values.email, values.password, setOpenOtp);
      setSignInCalled(true);
    } catch (err) {
      console.error(err);

      if (isMounted()) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      handleSubmit(values, helpers);
    },
  });

  const theme = useTheme();

  return (
    <>
      <Box sx={{ maxWidth: 'md', width: '100%', mx: 'auto', paddingTop: { xs: '100px', sm: '200px' } }}>
        {/* TODO: Add back when this uses the new E2E Layout */}
        {/* <PageIntro title="Log In" paragraph="Welcome Back" /> */}
        <Box>
          <Typography
            sx={{
              color: '#243F8F',
              fontSize: { xs: '1.3rem', sm: '2.25rem' },
              lineHeight: { xs: '1.51rem', sm: '2.5rem' },
              fontWeight: 700,
            }}
            component="h1"
            gutterBottom
          >
            Log In
          </Typography>
          <Typography variant="body1" paragraph mt={2}>
            Welcome Back
          </Typography>
        </Box>

        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              // sx={{
              //   '& .MuiInputBase-input': {
              //     fontSize: '16px',
              //   },
              // }}
            />
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '16px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {formik.errors.submit && (
            <FormHelperText error sx={{ mt: 3 }}>
              {formik.errors.submit as string}
            </FormHelperText>
          )}
          <Button
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            sx={{
              alignSelf: { xs: 'center', md: 'flex-start' },
              marginTop: { xs: '20px', md: '30px' },
              color: '#243F8F',
              '&:hover': {
                backgroundColor: '#dbab47',
              },
            }}
            type="submit"
            variant="contained"
            color="warning"
          >
            Log In
          </Button>
        </form>

        <AuthDivider />

        <FPAuthHelperLinksWrapper>
          <Box sx={{ flex: 1 }}>
            <Typography align="center" variant="subtitle2">
              <Link component={RouterLink} href={'/reset-password'} underline="hover" color={fpAuthColors.fpBirdBlue}>
                Forgot your password?
              </Link>
            </Typography>
          </Box>
        </FPAuthHelperLinksWrapper>
      </Box>
      <OTPModal open={openOtp} setOpen={setOpenOtp} setsignInCalled={setSignInCalled} formik={formik} />
    </>
  );
};

export default NewLoginForm;
