import { DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';

export interface E2eClientErrorDisclosures {
  disclosure_type?: DisclosureTypeE;
  lender_id?: number;
  processor_id?: number;
  _id?: string; // the id of the disclosure that needs to be signed
  aaso_id?: string;
  form_id?: number;
}

export enum E2eClientErrorType {
  'E2eFormSubmissionError' = 'E2eFormSubmissionError',
  'E2eFormValidationError' = 'E2eFormValidationError',
  'E2eFormGeneralError' = 'E2eFormGeneralError',
}

interface E2eClientFormErrors {
  missing_fields?: string[];
}
export interface E2eClientError extends Error {
  message: string;
  disclosures?: E2eClientErrorDisclosures[];
  form?: E2eClientFormErrors;
}

export interface E2eClientErrorAggregate extends Error {
  message: string;
  errors: E2eClientError[];
  type: E2eClientErrorType;
}
