import React, { FC } from 'react';
import { Button, CardContent, Grid, Paper, Stack, Typography } from '@mui/material';
import { Form, FormRenderProps } from 'react-final-form';
import toast from 'react-hot-toast';
import { CleanButton } from 'src/components/clean/button';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import LoadingScreen from 'src/e2e-redesign/components/LoadingScreen';
import LoadingState from 'src/e2e-redesign/components/LoadingState';
import { e2eAppSectionLogic_functionsAndVals } from 'src/e2e-redesign/views/application/logic/e2e-app-logic';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { FieldDetails } from 'src/interfaces/application.interfaces';
import { validateFormValues } from 'src/pages/application/application-logic';
import AbandonedFormWrapper from 'src/pages/application/components/abandoned-form-wrapper';

import AddressValidationModal from './components/address-validation-modal';

interface ApplicationFormSectionProps {
  routingPackage: E2eLogicObjectsWithAasoParams;
}
const ApplicationFormSection: FC<any> = (props: ApplicationFormSectionProps) => {
  const routingPackage = props.routingPackage;
  const { e2eStore, navigate } = routingPackage;
  const aaso = e2eStore.aaso;
  const fnv = e2eAppSectionLogic_functionsAndVals(props.routingPackage);
  const {
    validationSchema,
    e2eFormPath,
    showLoading,
    formValues,
    updatedFields,
    required_fields,
    openModal,
    canSubmit,
  } = fnv.v;
  const {
    handleSubmitForm,
    handleAutoGen,
    setFormValues,
    handleNavigateBack,
    setOpenModal,
    setForceAddressVerification,
  } = fnv.fn;
  const DevAdminOnlyFormDataViewer = (props: any) => {
    const { errors, values } = props;

    return (
      <Paper elevation={3} style={{ backgroundColor: '#f5f5f5', border: '1px solid #ccc' }}>
        <Typography variant={'h5'} style={{ color: '#1976d2', marginBottom: '15px' }}>
          ONLY SHOWS ON DEV
        </Typography>
        <Typography variant={'body1'} style={{ fontStyle: 'italic', fontWeight: 'bold', marginBottom: '20px' }}>
          If you used auto-gen and are seeing the grey button, click on any input and press tab.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant={'h6'}>Form Data</Typography>
            <Typography
              component={'pre'}
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: '#e8eaf6',
                padding: '10px',
                borderRadius: '4px',
                overflowX: 'hidden',
                boxSizing: 'border-box',
                maxWidth: '100%',
              }}
            >
              {JSON.stringify(values, null, 2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={'h6'}>Form Errors</Typography>
            <Typography
              component={'pre'}
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: '#ffebee',
                padding: '10px',
                borderRadius: '4px',
                overflowX: 'hidden',
                boxSizing: 'border-box',
                maxWidth: '100%',
              }}
            >
              {JSON.stringify(errors, null, 2)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const handleButton = (valid: boolean, submitting: boolean) => {
    switch (aaso.current_stage) {
      case ApplicationStage.FORM_SUBMITTED:
      case ApplicationStage.LENDER_SELECTED:
      case ApplicationStage.COMPLETED:
        return (
          <CleanButton
            fullWidth
            sx={{ maxWidth: { sm: '25%' } }}
            variant="contained"
            color="primary"
            onClick={() => navigate(`../${E2ERoutes.PrimeLenderInformationReview}`)}
          >
            Move Forward
          </CleanButton>
        );
      case ApplicationStage.STARTED:
      case ApplicationStage.DISCLOSURE_PRIVACY:
      case ApplicationStage.DISCLOSURE_TERMS:
      case ApplicationStage.DISCLOSURE_LENDERS:
      case ApplicationStage.FORM_STARTED:
      default:
        return (
          <CleanButton
            fullWidth
            disabled={!valid || submitting}
            sx={{ maxWidth: { sm: '25%' } }}
            variant="contained"
            color="primary"
            type={'submit'}
          >
            SUBMIT
          </CleanButton>
        );
    }
  };

  return (
    <>
      {showLoading && <LoadingState title={'Processing'} paragraph={'We are processing your application'} />}
      {!showLoading && e2eFormPath && (
        <Form
          key={formValues?.sql_id}
          enableReinitialize
          validate={validateFormValues(validationSchema)}
          validateOnBlur={true}
          initialValues={formValues}
          onSubmit={(values) => {
            if (canSubmit) {
              setFormValues(values);
              handleSubmitForm(values);
            } else {
              toast.error(
                'You have already submitted the form for this path. If you would like to edit your form, contact your sales rep.',
              );
            }
          }}
          render={(formRenderProps: FormRenderProps) => {
            const { handleSubmit, errors, valid, submitting, values } = formRenderProps;
            return (
              <form onSubmit={handleSubmit} noValidate>
                {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                  <Button
                    color="secondary"
                    onClick={() => handleAutoGen(formRenderProps)}
                    variant="contained"
                    style={{ marginBottom: 30 }}
                  >
                    Auto-Gen
                  </Button>
                )}
                <AbandonedFormWrapper
                  updatedFields={updatedFields}
                  fieldDetailType={FieldDetails.final_details}
                  formRenderProps={formRenderProps}
                  required_fields={required_fields}
                />
                {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                  <DevAdminOnlyFormDataViewer values={values} errors={errors} />
                )}
                <Stack direction={'column'} spacing={3} px={5}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ marginTop: 50 }}
                  >
                    {/* <CleanButton */}
                    {/*   sx={{ color: '#243F8F', width: '100%', maxWidth: { sm: '25%' } }} */}
                    {/*   fullWidth */}
                    {/*   style={{ maxWidth: '25%' }} */}
                    {/*   variant="text" */}
                    {/*   onClick={handleNavigateBack} */}
                    {/* > */}
                    {/*   BACK */}
                    {/* </CleanButton> */}
                    {aaso && handleButton(valid, submitting)}
                  </Stack>
                </Stack>
              </form>
            );
          }}
        />
      )}
      <AddressValidationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onProceed={() => {
          setForceAddressVerification(true);
          Promise.resolve().then(() => {
            handleSubmitForm(formValues);
          });
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default ApplicationFormSection;
