import { useMutation, useQuery } from '@tanstack/react-query';
import type { DefinedUseQueryResult } from '@tanstack/react-query/src/types';
import toast from 'react-hot-toast';
import {
  API_E2E_GET_APPLICATION_SETTINGS,
  API_E2E_GET_LIMITED_APPLICATION_SETTINGS,
  API_E2E_PATCH_APPLICATION_SETTINGS,
} from 'src/api/e2e-request-objects';
import { TsMutateI, TsQueryI } from 'src/api/query-logic/query-interfaces';
import { createQueryKey } from 'src/api/query-logic/query-utils';
import { apiRequest } from 'src/api/request-handler';
import { AppLogicObjectsResponseI } from 'src/interfaces/e2e-state.interfaces';

/**
 * HOWTO - tanstack with zustand
 * Why?
 * - we are going to want to optimize these queries later and by doing this we can optimize on the frontend and back
 * - we can use tstacks isLoading, isError, isSuccess, etc.
 *
 * What to do:
 * - Create the request object in the request-objects folder, e.g., API_E2E_GET_APPLICATION_SETTINGS
 *    - just use queryGetApplicationSettings as a template
 * - create a query function in a NEW file <query-e2e-application-settings.ts>
 * - create setState in the e2e-store file
 * - pull the query function in whatever component you want to use it in
 *    - see welcome page as an example
 * Notes:
 * - we are NOT using the caching feature of tanstack right now so staleTime is set to 0
 */
export const queryGetE2eApplicationSettings = (obj: TsQueryI): DefinedUseQueryResult<AppLogicObjectsResponseI> => {
  return useQuery({
    queryKey: createQueryKey('get-application-settings', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_E2E_GET_APPLICATION_SETTINGS, obj.reqData);
      obj.setState && obj.setState(res, obj.urlParams);
      return res;
    },
    staleTime: 5 * 60 * 1000, // 0 Always stale - in ms
    // if you add a stale time, then you can't include initial data
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    // refetchInterval: 0,
    // gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};

export const queryGetE2eLimitedApplicationSettings = (obj: TsQueryI) => {
  return useQuery({
    queryKey: createQueryKey('get-l-application-settings', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_E2E_GET_LIMITED_APPLICATION_SETTINGS, obj.reqData);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 5 * 60 * 1000, // 0 Always stale - in ms
    // if you add a stale time, then you can't include initial data
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    // refetchInterval: 0,
    // gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};

// HOWTO - use mutations with zustand
export const exampleOfChangingApplicationSettings = (obj: TsMutateI) => {
  return useMutation({
    mutationFn: (objData: TsQueryI) => {
      const res = apiRequest(API_E2E_PATCH_APPLICATION_SETTINGS, obj.reqData).then((res) => {
        // we can update state here if we want - if so, don't invalidate cache
        obj.setState && obj.setState(res);
        return res;
      });
      return res;
    },
    onMutate: (variables) => {
      // A mutation is about to happen!
      console.log('on mutate', variables);
      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id `, error, variables, context);
    },
    onSuccess: (data, variables, context) => {
      console.log('success');
      if (data && obj.queryClient) {
        // if we invalidate query, it will re-pull - BE CAREFUL WITH MONGO - for updates to Mongo, we want to return the updated object and use that
        obj.queryClient.invalidateQueries({
          queryKey: createQueryKey('get-application-settings', obj.queryKey),
        });
        toast.success('Updated');
      } else {
        toast.error(data.message);
      }
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
    networkMode: 'always',
  });
};
