import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useNavigate } from 'react-router-dom';
import { e2eConfigLogic_parseTemplate } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import ThemedHtml from 'src/e2e-redesign/components/ThemedHtml/ThemedHtml';
import { CreateAccountFormValuesI } from 'src/e2e-redesign/views/CreateAccountNewCustomer/logic/create-account-logic';
import { DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';

import CustomCheckbox from './CustomCheckbox';
import { validationSchema } from './formSchema';
import FpDisclosureDialog from './FpDisclosureDialog';

interface FormField {
  name: string;
  label: string;
  type: string;
}

const listItems: string[] = [
  'By providing my mobile number, I agree that FormPiper and Premier Service may contact me at this number regarding my account for any and all purposes.',
  'By checking each box, I verify that I have read and understand the terms, policies and notices.',
  'By clicking CONTINUE, I authorize that all information I have provided to the FormPiper application, including any and all personal and financial data may be collected and stored by FormPiper in accordance with the purposes and provisions in the FormPiper Privacy Policy. I further acknowledge and authorize FormPiper to share my data provided in my application(s) to providers for the purpose of evaluating my information for prospective grants of financing/leasing. Form Piper may share my contact data with Affiliates that may offer relevant services or data to you.',
  'I attest that the information provided above is true and accurate.',
];

export interface CreateAccountFormProps {
  initialValues: CreateAccountFormValuesI;
  onSubmit: (values: CreateAccountFormValuesI) => Promise<boolean>;
  getInputProps: (field: string, endAdornment: any) => any;
  handleIsDisabled: (values: CreateAccountFormValuesI) => boolean;
}
const CreateAccountForm: React.FC<CreateAccountFormProps> = (props: CreateAccountFormProps) => {
  const routingPackage: E2eLogicObjectsWithParams = e2eLogicUtils_getBaseRoutingLogicObjects();
  const e2eStore = routingPackage.e2eStore;
  const disclosures = routingPackage.e2eStore.disclosures;
  const bullets = disclosures.find((d) => d.disclosure_type === DisclosureTypeE.fp_bullets);
  const { initialValues, onSubmit, getInputProps, handleIsDisabled } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const processorName = e2eStore.app_configurations?.processor_details?.store_name;
  initialValues.fp_bullets.diclosure_id = bullets?._id ?? initialValues.fp_bullets.diclosure_id;

  const formFields: FormField[] = [
    { name: 'first_name', label: 'First Name', type: 'text' },
    { name: 'last_name', label: 'Last Name', type: 'text' },
    { name: 'email', label: 'Email Address', type: 'email' },
    { name: 'mobile_phone', label: 'Mobile Phone Number', type: 'text' },
    { name: 'password', label: 'Create Password', type: showPassword ? 'text' : 'password' },
    { name: 'confirm_password', label: 'Confirm Password', type: showPassword ? 'text' : 'password' },
  ];
  console.log('New Create Account Page');

  const handleOpenDialog = (dialogType: string) => {
    setOpenDialog(dialogType);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const endAdornment = () => (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <Grid
                item
                xs={12}
                sm={
                  field.name === 'first_name' ||
                  field.name === 'last_name' ||
                  field.name === 'email' ||
                  field.name === 'mobile_phone' ||
                  field.name === 'password' ||
                  field.name === 'confirm_password'
                    ? 6
                    : 12
                }
                key={field.name}
              >
                <Field
                  component={TextField}
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { borderRadius: '15px' } }}
                  InputProps={getInputProps(field.name, endAdornment)}
                />
              </Grid>
            ))}
            <Grid item xs={12} sx={{ mb: 1 }}>
              <CustomCheckbox
                name="fp_myfp_tc[accepted]"
                label="Terms & Conditions"
                onClick={() => handleOpenDialog('terms')}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <CustomCheckbox
                name="fp_customer_notice[accepted]"
                label="Customer Notice"
                onClick={() => handleOpenDialog('customerNotice')}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <CustomCheckbox
                name="fp_privacy[accepted]"
                label="Privacy Policy"
                onClick={() => handleOpenDialog('privacyPolicy')}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography paragraph variant="body1">
                {bullets && (
                  // <ThemedHtml
                  //   htmlContent={e2eConfigLogic_parseTemplate(bullets.document, {
                  //     fpAddAdditionalEntitied: processorName
                  //       ? `, ${e2eStore.app_configurations?.store_details?.store_name} and ${e2eStore.app_configurations?.processor_details?.store_name}`
                  //       : ` and ${e2eStore.app_configurations?.store_details?.store_name}`,
                  //   })}
                  // />
                  <ThemedHtml htmlContent={bullets.document} />
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { md: 'flex-start' },
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color={'warning'}
                size="large"
                type="submit"
                disabled={handleIsDisabled(values) || isSubmitting}
              >
                Continue
              </Button>
              <Button
                type="button"
                onClick={() => navigate(-1)}
                sx={{
                  color: theme.palette.text.primary,
                  textDecoration: 'underline',
                  marginLeft: { xs: 0, md: 6 },
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          <FpDisclosureDialog open={openDialog !== null} onClose={handleCloseDialog} dialogType={openDialog} />
        </Form>
      )}
    </Formik>
  );
};

export default CreateAccountForm;
