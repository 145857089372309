/* eslint-disable */
import {clearModals, modalCounts, popModal, pushModal} from './modal-manager';

/**
 * Secondary layer for modal
 */
export const pushForModal = (modal: JSX.Element) => {
  pushModal(modal);
};

export const popForModal = () => {
  popModal();
};

export const clearForModal = () => {
  clearModals();
};

export const forModalCounts = (): number => {
  return modalCounts();
};
