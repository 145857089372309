import React from 'react';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { e2eApp_functionsAndVals, e2eApp_init } from 'src/e2e-redesign/views/application/logic/e2e-app-logic';
import ApplicationCard from 'src/pages/application/application-card';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';

export default function NewE2eApplication() {
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  e2eApp_init(routingPackage);
  const fnv = e2eApp_functionsAndVals(routingPackage);
  const { canViewPage, subTitle } = fnv.v;

  /**
   * - Need to port over the dynamic form from the old flow
   * - Pull form details
   * - P0!!! - we need to add a check that prevents the user from going here without disclosures
   *    - add this to the backend as well
   * - Once they hit submit:
   *        - send to backend
   *            - verify the data
   *            - submit
   *        - update the AASO object
   *        - call routeUser function
   *            - this is where we will handle whether or not they get directed to synchrony
   *            - if they get redirected to a provider page... Alex to figure this out
   *            - if they stay on FormPiper,
   *                - route them to Provider responses
   *                    - src/e2e-redesign/views/LenderResponses/PrimeProviderResponseSingleFlow.tsx
   *
   * Resources:
   * - src/pages/application/application.tsx
   */
  // R:TODO E2E P0 - this doesn't work very well...
  /**
   * We should just prevent them from going back to the form {replace: true}
   * but we will have to test it out
   */
  // R:TODO E2E P0 - all of the page tabs show "Login" we need to update that
  if (!canViewPage) return <></>;
  return (
    <>
      <Seo title="Application" />
      <ThemedContainer>
        <PageIntro title="Let&apos;s get some information" paragraph={subTitle} />
        <ApplicationCard title={''} subtitle={''} routingPackage={routingPackage} />
      </ThemedContainer>
    </>
  );
}
