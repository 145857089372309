import { FC, ReactNode } from 'react';
import { Box, styled, ThemeProvider, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import theme from '../theme';
import MainHeader from './components/MainHeader';
import WelcomeHeader from './components/WelcomeHeader';

import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

import { useAuth } from 'src/hooks/use-auth';

interface LayoutProps {
  children: ReactNode;
}

const LayoutContainer = styled(Box)<{ isWelcome: boolean; isMobile: boolean }>(({ isWelcome, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  ...(isWelcome &&
    isMobile && {
      backgroundImage: `url('/assets/e2e-redesign/images/welcome-header.png')`,
    }),
}));



const MainLayout: FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  const isWelcome = location.pathname.endsWith('/welcome');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showUserName = () => {
    // TODO E2E P0 - make this look better.. need to get with vincent
    if (user) {
      return (
        <p>
          {user.first_name} {user.last_name}
        </p>
      );
    }
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <LayoutContainer isWelcome={isWelcome} isMobile={isMobile}>
        {isWelcome && isMobile ? <WelcomeHeader /> : <MainHeader />}
        {/* {isAuthenticated && showUserName()} */}
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </LayoutContainer>
    </ThemeProvider>
  );
};

export default MainLayout;
