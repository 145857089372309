/* eslint-disable */
import React, { FC } from 'react';
import { FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';
import { Eye, EyeOff } from 'react-feather';
import { getMaskType } from 'src/pages/application/components/dynamic-input';

const SensitiveInput: FC<SensitiveInputI> = (props: SensitiveInputI) => {
  const [showPassword, setShowPassword] = React.useState(true);

  const onFocus = (event: any) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = 'off';
    }
  };

  const handleChange = (e: any) => {
    // we switch it to sensitive once they start typing so we can avoid password managers
    if (showPassword) {
      setShowPassword(false);
    }
    props.onChange(e);
  };

  return (
    <div>
      <TextField
        fullWidth
        label={props.label}
        name={props.name}
        onChange={handleChange}
        onBlur={props.onBlur}
        // select
        // SelectProps={{ native: true }}
        value={props.value}
        variant="outlined"
        onFocus={onFocus}
        defaultValue={props.value}
        type={showPassword ? 'search' : 'password'}
        autoComplete="off"
        // className={classes.inputfieldpayment}
        // margin="normal"
        disabled={props.disabled}
        InputProps={{
          inputComponent: getMaskType(props?.objSchema?.mask) as any,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Eye /> : <EyeOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              outline: 'none',
              boxShadow: 'none',
            },
          },
        }}
      />
      <FormHelperText style={{ color: 'red' }}>{props.helperText}</FormHelperText>
    </div>
  );
};

interface SensitiveInputI {
  className?: string;
  value: string;
  onChange: any;
  label: string;
  id: string;
  name: string;
  helperText: any;
  variant: string;
  onBlur: any;
  placeholder: string;
  error: any;
  objSchema: any;
  disabled?: boolean;
}

export default SensitiveInput;
