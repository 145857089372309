/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { API_E2E_GET_MISSING_DISCLOSURES, API_E2E_POST_MISSING_DISCLOSURES } from 'src/api/e2e-request-objects';
import { apiRequest } from 'src/api/request-handler';
import { E2eClientErrorDisclosures } from 'src/errors/error.interfaces';
import { useAuth } from 'src/hooks/use-auth';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import MissingSingleDisclosure from 'src/modal-manager/modals/missing-single-disclosure';

interface PaymentModalProps {
  isOpen: boolean;
  data: E2eClientErrorDisclosures;
  cancelAction: () => void;
  clearAll?: () => void;
  onAccept?: (data: any) => void;
}

interface SavedDisclosuresI {
  _id: string;
  agreeTerms: boolean;
  optInMarketing: boolean;
  name: string;
  aaso_id?: string;
  form_id?: number;
  lender_id?: number;
}
const SignMissingDisclosuresDModal: FC<PaymentModalProps> = (props: PaymentModalProps) => {
  const auth = useAuth();
  const { aaso_id } = useParams();
  const { data, onAccept, cancelAction } = props;
  const [disclosures, setDisclosures] = useState<Disclosure[]>([]);
  const [selectedDisclosure, setSelectedDisclosure] = useState<Disclosure | null>(null);
  const [savedDisclosures, setSavedDisclosures] = useState<SavedDisclosuresI[]>([]);
  const [index, setIndex] = useState(0);
  const getMissingDisclosures = async () => {
    const res = await apiRequest(API_E2E_GET_MISSING_DISCLOSURES, { body: data });
    if (res) {
      setDisclosures(res);
    }
  };
  useEffect(() => {
    getMissingDisclosures();
  }, [data]);
  // TODO E2E P0 - handle missing disclosures

  useEffect(() => {
    setSelectedDisclosure(disclosures[index]);
  }, [disclosures]);

  const saveLenderDisclosures = async (savedDisclosures: SavedDisclosuresI[]) => {
    const res = await apiRequest(API_E2E_POST_MISSING_DISCLOSURES, {
      body: savedDisclosures,
      query: { aaso_id: aaso_id ? aaso_id : undefined },
    });
    if (res) {
      cancelAction();
    }
  };

  useEffect(() => {
    // check if all disclosures have been signed
    if (savedDisclosures.length > 0 && disclosures.length === savedDisclosures.length) {
      // close modal
      if (onAccept) {
        saveLenderDisclosures(savedDisclosures);
      }
    } else {
      if (savedDisclosures.length <= disclosures.length) {
        // find the next disclosure to sign
        setSelectedDisclosure(disclosures[index]);
      }
    }
  }, [savedDisclosures, index]);

  const handleOnContinue = (data: any) => {
    if (savedDisclosures.length <= disclosures.length) {
      setSavedDisclosures([...savedDisclosures, data]);
      setIndex(index + 1);
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span>Disclosures</span>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {selectedDisclosure && (
          <MissingSingleDisclosure
            onContinue={handleOnContinue}
            disclosure={selectedDisclosure}
            errorData={disclosures[index]}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SignMissingDisclosuresDModal;
