import { useEffect, useState } from 'react';
import { E2eBaseCallDetailsI, e2eGetBaseAasoRequests } from 'src/api/query-logic/e2e-query-base-calls';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_addUniquePastPath,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import { ApplicationStage } from 'src/enums/aaso.enums';

export const e2eThankYou_init = (routingPackage: E2eLogicObjectsWithAasoParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, aaso_id } = params;
  const { e2eStore } = routingPackage;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid,
    e2e_form_id,
    aaso_id,
  };

  e2eGetBaseAasoRequests(baseCallDetails, e2eStore, true);
};

export interface LenderDetails {
  image_path: string;
  name: string;
  product_details: {
    html: string;
  }[];
}

export interface SelectAppLogicI extends ComponentLogicI {
  fn: {};
  v: {};
}
export const selectAppLogic_functionsAndVals = (routingPackage: E2eLogicObjectsWithAasoParams): SelectAppLogicI => {
  const { e2eStore } = routingPackage;
  const aaso = e2eStore.aaso;
  const appConfig = e2eStore.app_configurations;
  const [aasoDetails, setAasoDetails] = useState<{
    past_path_index: number[];
    past_path_ids: string[];
    current_stage: ApplicationStage;
  }>();
  const updateAaso = e2eStore.updateAASO;

  useEffect(() => {
    // R:TODO E2E P1 - refactor this with e2ePathCondLogic_routeToNewPath - standardize update past_path_ids
    // if the user gets to this page, then we need to note that the user has submitted their form and add past_path_ids
    const formPaths = appConfig?.current_form?.paths;
    if (formPaths && !aasoDetails) {
      const currentPath = appConfig?.current_path;
      if (currentPath) {
        if (aaso.past_path_ids && aaso.current_path_id) {
          const updatedPaths = e2eLogicUtils_addUniquePastPath(
            aaso.past_path_ids,
            aaso.past_path_index,
            formPaths,
            currentPath.id,
          );
          const newAaso = {
            current_stage: ApplicationStage.THANK_YOU_PAGE,
            past_path_ids: updatedPaths.past_path_ids,
            past_path_index: updatedPaths.past_path_index,
          };
          setAasoDetails(newAaso);
        }
      }
    }
  }, [appConfig, aaso, aasoDetails]);

  useEffect(() => {
    if (aasoDetails) {
      updateAaso({
        data: {
          ...aasoDetails,
        },
        sync: true,
      });
    }
  }, [aasoDetails]);

  return {
    fn: {},
    v: {},
  };
};
