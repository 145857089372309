import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface FooterProps {
  allDeclined: boolean;
}

const Footer = ({ allDeclined }: FooterProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleButtonClick = () => {
    if (allDeclined) {
      window.location.href = 'https://www.myformpiper.com'; // Change URL as needed
    } else {
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#F3F6FB',
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'sticky',
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <Button variant="contained" color="warning" size="large" onClick={handleButtonClick}>
        {allDeclined ? 'MyFormPiper Portal' : 'Need Help?'}
      </Button>
      {!allDeclined && (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Need Help?</DialogTitle>
          <DialogContent>
            <DialogContentText>If you're not ready to accept an offer or have questions, contact your store associate.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="warning" onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Footer;
