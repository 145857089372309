import { ReactNode } from 'react';
import { DialogTitle, Grid } from '@mui/material';
import { ForDialogTypes, ForModalNames, MessageModalI } from 'src/modal-manager/for-dialog.types';
import { popForModal, pushForModal } from 'src/modal-manager/for-modal-manager';
import SignMissingDisclosuresDModal from 'src/modal-manager/modals/sign-missing-disclosures.d-modal';

const DialogHeader: React.FC<ForDialogFrameProps> = (props: ForDialogFrameProps): JSX.Element => {
  return (
    <DialogTitle id="alert-dialog-title">
      <span>Edit Payment</span>
    </DialogTitle>
  );
};

const GenericDialog: React.FC = (): JSX.Element => {
  return <span>generic</span>;
};

const ForDialog: React.FC<ForDialogProps> = (props: ForDialogProps): JSX.Element => {
  return (
    <Grid item xs={12}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <DialogHeader type={props.type} />
        </Grid>
        <Grid item>icon</Grid>
      </Grid>
      {props.dialogContent ? props.dialogContent() : <GenericDialog />}
    </Grid>
  );
};

interface ForDialogProps {
  type: ForDialogTypes;
  header?: string;
  title?: string;
  message: string | JSX.Element[];
  dataTestIdPrefix?: string;
  dialogContent?: () => ReactNode;
}

interface ForDialogFrameProps {
  type: ForDialogTypes;
  header?: string;
}

export const MessageModal: React.FC<MessageModalI> = (props: MessageModalI): JSX.Element => {
  const modalName = props.modal_name;

  const cancelAction = (): void => {
    popForModal();
  };

  let returnModal;

  switch (modalName) {
    case ForModalNames.SignMissingDisclosures:
      returnModal = (
        <SignMissingDisclosuresDModal
          key={ForModalNames.SignMissingDisclosures}
          data={props.data}
          isOpen={true}
          cancelAction={cancelAction}
          onAccept={props.onAccept}
        />
      );
      break;
  }
  return returnModal;
};

export const showForDialog = (props: MessageModalI) => {
  pushForModal(<MessageModal {...props} />);
};

export default ForDialog;
