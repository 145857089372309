import type { FC } from 'react';
import React from 'react';
import { Button, Grid } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface LenderStatusDevSelectResponseProps {
  handleAction: any;
  cancelAction: any;
}

export enum DevSubmissionEnums {
  'None' = 'None', // will take the current form values
  'Approved' = 'Approved',
  'Hold' = 'Hold',
  'Declined' = 'Declined',
  'Error' = 'Error',
  'DO_NOT_SUBMIT' = 'DO_NOT_SUBMIT', // will do everything but submit the application
  // Koalafi Specific
  'FsLeaseApproval' = 'FS Lease Approval',
  'FsLeaseSoftDecline' = 'FS Lease Soft Decline',
  'FsLoanSoftDecline' = 'FS Loan Soft Decline',
  'FsFullDecline' = 'FS Full Decline',
  'LeaseSoftDecline' = 'Lease Soft Decline',
  'LeaseDecline' = 'Lease Decline',
}

const LenderStatusDevMenu: FC<LenderStatusDevSelectResponseProps> = (props: LenderStatusDevSelectResponseProps) => {
  const { handleAction, cancelAction } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    cancelAction();
  };

  return (
    <Grid item>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size={'medium'}
        onClick={handleClick}
      >
        Submit
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.None);
            handleClose();
          }}
        >
          {DevSubmissionEnums.None}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.Approved);
            handleClose();
          }}
        >
          {DevSubmissionEnums.Approved}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.Hold);
            handleClose();
          }}
        >
          {DevSubmissionEnums.Hold}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.Declined);
            handleClose();
          }}
        >
          {DevSubmissionEnums.Declined}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.Error);
            handleClose();
          }}
        >
          {DevSubmissionEnums.Error}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.FsLeaseApproval);
            handleClose();
          }}
        >
          {DevSubmissionEnums.FsLeaseApproval}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.FsLeaseSoftDecline);
            handleClose();
          }}
        >
          {DevSubmissionEnums.FsLeaseSoftDecline}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.FsLoanSoftDecline);
            handleClose();
          }}
        >
          {DevSubmissionEnums.FsLoanSoftDecline}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.FsFullDecline);
            handleClose();
          }}
        >
          {DevSubmissionEnums.FsFullDecline}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.LeaseSoftDecline);
            handleClose();
          }}
        >
          {DevSubmissionEnums.LeaseSoftDecline}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.LeaseDecline);
            handleClose();
          }}
        >
          {DevSubmissionEnums.LeaseDecline}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(DevSubmissionEnums.DO_NOT_SUBMIT);
            handleClose();
          }}
        >
          {DevSubmissionEnums.DO_NOT_SUBMIT}
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default LenderStatusDevMenu;
