import { useCallback } from 'react';
import { handleError } from 'src/utils/error';

const useErrorHandler = () => {
  const handleErrorCallback = useCallback((error: unknown) => {
    handleError(error);
  }, []);

  return { handleError: handleErrorCallback };
};

export default useErrorHandler;
