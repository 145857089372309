import { useQuery } from '@tanstack/react-query';
import { API_E2E_GET_AASO_BY_ID } from 'src/api/e2e-request-objects';
import { TsQueryI } from 'src/api/query-logic/query-interfaces';
import { createQueryKey } from 'src/api/query-logic/query-utils';
import { AASO } from 'src/interfaces/aaso.interfaces';

import { QueryTypes } from '../query.constants';
import { apiRequest, apiRequestSync } from '../request-handler';
import { API_GET_AASO } from '../request-objects';

interface Error {
  statusCode: number;
  message: string;
}

export const queryAASO = (aaso_id: string) =>
  useQuery<AASO | Error>({
    queryKey: [QueryTypes.AASO, aaso_id],
    queryFn: () => apiRequestSync(API_GET_AASO, { params: { id: aaso_id } }),
    refetchOnWindowFocus: false,
    enabled: !!aaso_id,
    staleTime: 1000 * 60 * 5,
  });

export const queryGetAasoById = (obj: TsQueryI) => {
  return useQuery({
    queryKey: createQueryKey('get-aaso-by-id', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_E2E_GET_AASO_BY_ID, obj.reqData);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 0,
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    refetchInterval: 0,
    gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
