import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';

import DashboardNavigation from './dashboard-navigation';

export default function DashboardHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

const backgroundImage = isMobile
    ? `url('/assets/e2e-redesign/images/dashboard-mobile-header.png')`
    : `url('/assets/e2e-redesign/images/dashboard-tablet-header.png')`;

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };
  
    const handleDrawerClose = () => {
      setIsDrawerOpen(false);
    };

  return (
    <>
      <Box
        component="header"
        sx={{
          backgroundImage: backgroundImage,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: {
            xs: '200px',
            sm: '200px',
            lg: '300px',
          },
          padding: '20px',
          paddingBottom: 0,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src="/assets/e2e-redesign/images/formpiper-dashboard-logo.png"
            alt="FormPiper Logo"
            sx={{
              width: '196px',
              height: 'auto',
              marginRight: '10px',
            }}
          />
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.secondary.main}`,
              borderRadius: '10px',
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              xs: '100%',
              sm: '400px',
            },
            backgroundColor: theme.palette.secondary.main,
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '40px',
            }}
          >
            <Box
              component="img"
              src="/assets/e2e-redesign/images/formpiper-dashboard-logo.png"
              alt="FormPiper Logo"
              sx={{
                width: '196px',
                height: 'auto',
              }}
            />
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={toggleDrawer(false)}
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: '#ffffff',
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: '10px',
                marginRight: '10px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DashboardNavigation onNavigate={handleDrawerClose} />
        </Box>
      </Drawer>
    </>
  );
}