import axios from 'axios';

export const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return 'http://localhost:8083';
    case 'development':
      return 'https://client.formpiper-dev.com';
    case 'production':
    default:
      return 'https://client.formpiper.com';
  }
};

export const getBaseApiUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return 'http://localhost:8083';
    case 'development':
      return 'https://client-api.formpiper-dev.com';
    case 'production':
    default:
      return 'https://client-api.formpiper.com';
  }
};

const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': getBaseApiUrl(),
  },
});

axiosInstance.defaults.baseURL = getBaseUrl();

export { axiosInstance };
