import { useQuery } from '@tanstack/react-query';
import { TsQueryI } from 'src/api/query-logic/query-interfaces';
import { createQueryKey } from 'src/api/query-logic/query-utils';
import { apiRequest } from 'src/api/request-handler';
import { API_GET_LOCATIONS } from 'src/api/request-objects';

/**
 * HOWTO - tanstack with zustand
 * Why?
 * - we are going to want to optimize these queries later and by doing this we can optimize on the frontend and back
 * - we can use tstacks isLoading, isError, isSuccess, etc.
 *
 * What to do:
 * - Create the request object in the request-objects folder, e.g., API_E2E_GET_APPLICATION_SETTINGS
 *    - just use queryGetApplicationSettings as a template
 * - create a query function in a NEW file <query-e2e-application-settings.ts>
 * - create setState in the e2e-store file
 * - pull the query function in whatever component you want to use it in
 *    - see welcome page as an example
 * Notes:
 * - we are NOT using the caching feature of tanstack right now so staleTime is set to 0
 */
export const queryE2eLocations = (obj: TsQueryI) => {
  return useQuery({
    queryKey: createQueryKey('get-locations', obj.queryKey),
    queryFn: async () => {
      const res = await apiRequest(API_GET_LOCATIONS);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 5 * 60 * 1000, // 0 Always stale - in ms
    // if you add a stale time, then you can't include initial data
    // initialData: () => {
    //   return {};
    // },
    ...obj.queryOpt,
    // enabled,
    refetchInterval: 0,
    gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
