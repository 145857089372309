import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { Issuer } from 'src/types/auth';

const loginPaths: Record<Issuer, string> = {
  [Issuer.JWT]: '/',
};

interface AuthGuardProps {
  children: ReactNode;
}

const idleTimeout = Number(process.env.REACT_APP_LOGOUT_IDLE_TIMEOUT || 60000 * 15);

export const DashboardAuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const auth = useAuth();
  const { isAuthenticated, issuer } = useAuth();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isInitialized && !auth.isAuthenticated) {
      navigate('/');
    }
  }, []);

  const handleOnIdle = useCallback(async () => {
    if (isAuthenticated) {
      await auth.signOut();
      toast.error(`You have been logged out due to inactivity within ${idleTimeout / 1800000} minutes`);
    }
  }, [isAuthenticated, auth.signOut]);

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 300,
    crossTab: true,
  });

  const check = useCallback(() => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.href,
      }).toString();
      const href = loginPaths[issuer] + `?${searchParams}`;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, issuer, router]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!checked) {
    return null;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.
};
