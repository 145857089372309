import React, { FC } from 'react';
import { CardHeader, Container, Grid, Typography } from '@mui/material';
import { CleanCard } from 'src/components/clean/card';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import ApplicationFormSection from 'src/pages/application/application-form-section';

interface ApplicationCardI {
  title: string;
  subtitle: string;
  routingPackage: E2eLogicObjectsWithAasoParams;
}

const ApplicationCard: FC<ApplicationCardI> = ({ title, subtitle, routingPackage }: ApplicationCardI) => {
  return (
    <Container maxWidth="lg">
      {/* <CleanCard sx={{ maxHeight: '85vh', overflow: 'auto' }}> */}
      <CardHeader
        title={
          <Grid container spacing={3} sx={{ px: { md: 5 } }} justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Typography variant="h5" color={'#243F8F'}>
                {title}
              </Typography>
              <Typography variant={'subtitle1'}>{subtitle}</Typography>
            </Grid>
          </Grid>
        }
      />
      <ApplicationFormSection routingPackage={routingPackage} />
      {/* </CleanCard> */}
    </Container>
  );
};

export default ApplicationCard;
