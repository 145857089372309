/* eslint-disable */
import React, { useEffect } from 'react';
import type { FC } from 'react';
import { Grid, Stack } from '@mui/material';
import { FormikProps } from 'formik';
import { FormRenderProps } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { API_E2E_POST_FORM_ABANDONED } from 'src/api/e2e-request-objects';
import { apiRequest } from 'src/api/request-handler';
import useE2eStore from 'src/e2e-store';
import { FieldDetailI, FieldDetails } from 'src/interfaces/application.interfaces';
import DynamicSections from 'src/pages/application/components/dynamic-sections';

interface DynamicSectionsI {
  updatedFields: FieldDetailI[];
  fieldDetailType: FieldDetails;
  formProps?: FormikProps<any>;
  required_fields: string[];
  formRenderProps: FormRenderProps;
}

const AbandonedFormWrapper: FC<DynamicSectionsI | any> = (props: DynamicSectionsI) => {
  const { updatedFields, fieldDetailType, required_fields, formRenderProps } = props;
  const { aaso_id, e2e_form_id, store_uuid } = useParams();
  const e2eStore = useE2eStore((state) => state);
  useEffect(() => {
    // R:TODO E2E P1 - move this abandoned app logic elsewhere
    const handleBeforeUnload = async (event: any) => {
      // Custom logic to run when the user is about to leave the page
      console.log('User is leaving the page - save form_values');
      // If you want to show a confirmation dialog
      event.preventDefault();
      event.returnValue = ''; // This line is necessary for the confirmation dialog to show
      if (!e2eStore?.form_values?.submitted) {
        const formValues = formRenderProps.values; // the form values
        const res = await apiRequest(API_E2E_POST_FORM_ABANDONED, {
          body: { form: formValues },
          params: { e2e_form_id, aaso_id, store_uuid },
        });
        console.log(res);
        if (res) {
          console.log('saved abandoned form', res);
        }
      }
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formRenderProps.values, e2eStore.form_values]);

  return (
    <Stack direction={'column'} spacing={3}>
      <Grid container spacing={1}>
        <DynamicSections
          selectedFormDetails={updatedFields}
          fieldDetailType={fieldDetailType}
          formProps={formRenderProps}
          required_fields={required_fields}
        />
      </Grid>
    </Stack>
  );
};

export default AbandonedFormWrapper;
