import type { FC, ReactNode } from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import FPAuthLegalCopyright from '../../sections/auth/fp-auth-legal-copyright';
import FPAuthPortalGreeting from '../../sections/auth/fp-auth-portal-greeting';

interface FPAuthLayoutProps {
  children: ReactNode;
}

const FPAuthLayoutRoot = styled(Grid)(({ theme }) => ({
  backgroundColor: '#edf2f9',
  minHeight: '101.75vh',
  padding: theme.spacing(4),
}));

const FPAuthFormWrapper = styled(Paper)(({ theme }) => ({
  height: '87.5%',
  padding: theme.spacing(2),
}));

const FPAuthLayout: FC<FPAuthLayoutProps> = ({ children }: FPAuthLayoutProps) => {
  const baseMsg = 'Thank you for using FormPiper. Login now to view your application status';

  return (
    <FPAuthLayoutRoot container spacing={2}>
      <Grid item xs={12} lg={4} sx={{ minHeight: '100%' }}>
        <FPAuthPortalGreeting messaging={baseMsg} />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Stack direction="column" justifyContent="center" spacing={4} sx={{ height: '100%' }}>
          <FPAuthFormWrapper elevation={24}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ height: '100%' }}>
              {children}
            </Stack>
          </FPAuthFormWrapper>
          <FPAuthLegalCopyright />
        </Stack>
      </Grid>
    </FPAuthLayoutRoot>
  );
};

export default FPAuthLayout;
