/* eslint-disable */
import React from 'react';
import type { FC } from 'react';
import { Grid, TextField } from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import DynamicInput from 'src/pages/application/components/dynamic-input';

interface DynamicFieldI {
  formProps: FormRenderProps;
  objSchema: any;
  id: string;
  required_fields?: string[];
}

const DynamicField: FC<DynamicFieldI> = (props: DynamicFieldI) => {
  const { formProps, objSchema, id, required_fields } = props;

  return (
    <Grid item md={3} xs={12} key={`dynamic_field_grid${id}`}>
      <Field name={id} key={`field_${id}`}>
        {(fieldProps) => (
          <div key={`dynamic_field_div${id}`}>
            <DynamicInput
              key={`dynamic_field_input_${id}`}
              formProps={formProps}
              objSchema={objSchema}
              fieldProps={fieldProps}
              keyValue={id}
              required_fields={required_fields}
            />
          </div>
        )} 
      </Field>
    </Grid>
  );
};

export default DynamicField;
