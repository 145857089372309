import React from 'react';
import { Container } from '@mui/material';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { e2eThankYou_init } from 'src/e2e-redesign/views/thank-you-page/logic/thank-you-logic';
import ThankYouComponent from 'src/e2e-redesign/views/thank-you-page/thank-you-component';
import useE2eStore from 'src/e2e-store';

export default function E2eThankYouPage() {
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  e2eThankYou_init(routingPackage);
  const typd = useE2eStore((state) => state.thank_you_page_details);
  // I think I can remove this now that I'm saving the path once the user saved the form
  // selectAppLogic_functionsAndVals(routingPackage);
  return <Container maxWidth="sm">{typd && <ThankYouComponent typd={typd} />}</Container>;
}
