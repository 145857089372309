import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PageIntro from 'src/e2e-redesign/components/PageIntro';
import ThemedContainer from 'src/e2e-redesign/components/ThemedContainer';

interface LoadingStateProps {
  title: string;
  paragraph: string;
}

export default function LoadingState({ title, paragraph }: LoadingStateProps) {
  const theme = useTheme();

  return (
    <ThemedContainer>
      {/* <PageIntro title={title} paragraph={paragraph} /> */}
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: { xs: '100px', md: '200px' },
        }}
      >
        <img
          src="/static/images/fp-loading-logo.png"
          alt="Logo"
          style={{ width: '100px', height: '100px', zIndex: 1 }}
        />
        <CircularProgress
          variant="indeterminate"
          size={250}
          thickness={2}
          sx={{
            color: theme.palette.secondary.main,
            position: 'absolute',
            animation: 'spin 2s linear infinite',
            zIndex: 0,
          }}
        />
      </Box>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </ThemedContainer>
  );
}
