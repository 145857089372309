import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import theme from 'src/e2e-redesign/theme';
import { styled } from '@mui/material/styles';

const StyledPageIntro = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#ffffff',
}));

interface PageIntroProps {
  title: string;
  paragraph?: string;
}

const PageIntro: FC<PageIntroProps> = ({ title, paragraph }) => {
  return (
    <StyledPageIntro>
      <Typography sx={{ color: theme.palette.primary.main }} variant="h1" component="h1" gutterBottom>
        {title}
      </Typography>
      {paragraph && (
        <Typography variant="body1" paragraph mt={2}>
          <div dangerouslySetInnerHTML={{ __html: paragraph }} />
        </Typography>
      )}
    </StyledPageIntro>
  );
};

export default PageIntro;
