import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import ThemedCard from '../../components/ThemedCard';

interface WelcomeCardProps {
  title: string;
  buttonText: string;
  buttonColor: 'warning' | 'primary';
  onButtonClick: () => void;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ title, buttonText, buttonColor, onButtonClick }) => {
  return (
    <ThemedCard
      backgroundColor={buttonColor === 'warning' ? 'white' : 'border'}
      sx={{
        paddingTop: { xs: '30px', md: '50px' },
        paddingBottom: { xs: '30px', md: '50px' },
      }}
    >
      <Typography component="h2" variant="h2" sx={{ textAlign: 'center', paddingBottom: '20px' }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Button variant="contained" color={buttonColor} size="large" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </Box>
    </ThemedCard>
  );
};

export default WelcomeCard;
