import ApplicationModal from 'src/components/modals/continue-application/continue-application-modal';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import ThemedContainer from 'src/e2e-redesign/components/ThemedContainer';
import {
  newLoginLogic_functionsAndVals,
  newLoginLogic_init,
} from 'src/e2e-redesign/views/NewLogin/logic/new-login-logic';
import NewLoginForm from 'src/e2e-redesign/views/NewLogin/new-login-form';
import type { Page as PageType } from 'src/types/page';

const NewLogin: PageType = () => {
  const routingPackage: E2eLogicObjectsWithParams = e2eLogicUtils_getBaseRoutingLogicObjects();

  /**
   * Init page
   * - pull data needed for this page using tanstack
   * - set any useEffect that impacts the entire page
   */
  newLoginLogic_init(routingPackage);

  /**
   * All functions and values required for views/components will live separately from JSX
   * - pass them to child components if necessary (DO NOT REINIT)
   */
  const fnv = newLoginLogic_functionsAndVals(routingPackage);
  const { handleContinueExistingApp, handleCreateNewApp } = fnv.fn;
  const { continueRoute, openContinueApp, continueAASO, returnTo } = fnv.v;

  return (
    <>
      <Seo title="Log In" />
      <ThemedContainer>
        <NewLoginForm fnv={fnv} />
        <ApplicationModal
          open={openContinueApp}
          handleContinue={handleContinueExistingApp}
          handleStartNew={() => returnTo && handleCreateNewApp(returnTo)}
          continueAASO={continueAASO}
          continueRoute={continueRoute}
        />
      </ThemedContainer>
    </>
  );
};

export default NewLogin;
