/* eslint-disable */
import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { FieldRenderProps, FormRenderProps } from 'react-final-form';
import { useParams } from 'react-router-dom';
import AlphaNumeric from 'src/components/masks/alpha-numeric';
import CreditCardMask from 'src/components/masks/credit-card-mask';
import FullDateMask from 'src/components/masks/full-date-mask';
import CurrencyDecimalInput from 'src/components/masks/money-decimal-mask';
import CurrencyInput from 'src/components/masks/money-mask';
import MonthYearMask from 'src/components/masks/month-year-mask';
import NumberMask from 'src/components/masks/number-mask';
import PercentMask from 'src/components/masks/percent-mask';
import PhoneMask from 'src/components/masks/phone-mask';
import SsnMask from 'src/components/masks/ssn-mask';
import TimeInMonths from 'src/components/masks/time-in-months-mask';
import { FieldConditionActionTypes, RootFieldDetailsI } from 'src/interfaces/application.interfaces';
import { getSpecialMessage } from 'src/pages/application/components/form-disclosures';
import SensitiveInput from 'src/pages/application/components/sensitive-input';
import StateSelector from 'src/pages/application/components/state-selector';
import YearSelector from 'src/pages/application/components/year-selector';

// import ReactTextField from './react-final-form-fields/special-text-field';

interface MaskSelectorProps {
  formProps: FormRenderProps<any>;
  objSchema: RootFieldDetailsI;
  fieldProps: FieldRenderProps<any>;
  keyValue: string;
  storeName?: string;
  // these are for the preview
  disabled?: boolean;
  removeRequiredAsterisk?: boolean;
  required_fields?: string[];
}

export const getMaskType = (type: any) => {
  switch (type) {
    case 'phone':
      return PhoneMask;
    case 'ssn':
      return SsnMask;
    case 'fullDate':
      return FullDateMask;
    case 'monthYear':
      return MonthYearMask;
    case 'currency':
      return CurrencyInput;
    case 'currency_decimal':
      return CurrencyDecimalInput;
    case 'number':
      return NumberMask;
    case 'alphaNumeric':
      return AlphaNumeric;
    case 'timeInMonths':
      return TimeInMonths;
    case 'creditCard':
      return CreditCardMask;
    case 'percent':
      return PercentMask;
    default:
      NumberMask;
    // default: StandardInput()
  }
};

const DynamicInput = (props: MaskSelectorProps) => {
  const { objSchema, keyValue, storeName, formProps, fieldProps, disabled, removeRequiredAsterisk, required_fields } =
    props;
  const { touched, errors, values } = formProps;
  const { label, component, type, disclosure, msg, mask, required, least_common_options } = objSchema;
  const { token } = useParams();

  const {
    input: { name, onChange, onBlur, value, ...restInput },
    meta,
    ...rest
  } = fieldProps;

  const key = keyValue;

  /**
   * ToDo:
   * - I got this working, but I still need to incorporate the following:
   *  - msg
   *  - disclosure
   */

  const handleFieldErrors = (meta: any) => {
    if (token) {
      return required && !value ? true : undefined;
    } else {
      return meta.error && meta.touched;
    }
  };

  const getName = (name: any) => {
    const requiredName = `${name} *`;
    if (objSchema?.condition?.type === FieldConditionActionTypes.require) {
      return requiredName;
    }
    if (required_fields && required_fields.length > 0) {
      if (required_fields.includes(key)) {
        return requiredName;
      }
    } else if (objSchema.required) {
      return requiredName;
    } else {
      if (required && !removeRequiredAsterisk) {
        return requiredName;
      }
    }
    return name;
  };

  const textField = (objSchema: any) => {
    return (
      <>
        <TextField
          {...rest}
          id={key}
          InputProps={{
            inputComponent: getMaskType(mask) as any,
            // we want to remove theoutline border:
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
            },
          }}
          fullWidth
          // style={{ border: '3px solid red' }}
          label={getName(label)}
          name={key}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          variant="outlined"
          autoComplete="off"
          type={'search'} // this is to turn off 1Password autocomplete
          disabled={disabled}
        />
      </>
    );
  };

  const emailField = (objSchema: any) => {
    return (
      <>
        <TextField
          {...rest}
          id={key}
          InputProps={{
            inputComponent: getMaskType(mask) as any,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
            },
          }}
          fullWidth
          label={getName(label)}
          name={key}
          onBlur={onBlur}
          onChange={onChange}
          value={value.trim()}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          variant="outlined"
          autoComplete="off"
          type={'search'} // this is to turn off 1Password autocomplete
          disabled={disabled}
        />
      </>
    );
  };

  const selectField = (objSchema: any) => {
    // const items = applicationSelectObjects[objSchema.enum];
    // if it's state, I need to account for that
    let items = least_common_options;
    if (Array.isArray(items) && items.length <= 0) {
      items = objSchema?.base_options;
    }
    return (
      <div>
        <TextField
          select
          SelectProps={{ native: true }}
          id={key}
          label={getName(label)}
          fullWidth
          placeholder={getName(label)}
          name={key}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.:focus-visible .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                outline: 'none',
                boxShadow: 'none',
              },
            },
          }}
          variant="outlined"
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          helperText={meta.touched ? meta.error : undefined}
          error={handleFieldErrors(meta)}
          disabled={disabled}
        >
          {items.map((i: any) => (
            <option key={`${key}_${i.value}`} value={i.value}>
              {i.label}
            </option>
          ))}
        </TextField>
        {/*<FormHelperText style={{color: 'red'}}>{touched[key] && errors[key]}</FormHelperText>*/}
      </div>
    );
  };

  const yearsSelect = (objSchema: any) => {
    const params = objSchema.params;

    return (
      <YearSelector
        id={key}
        label={getName(label)}
        placeholder={getName(label)}
        name={key}
        variant="outlined"
        aheadOrBehind={params?.aheadOrBehind}
        maxOffset={params?.maxOffset}
        minOffset={params?.minOffset}
        spread={params?.spread}
        sort={params?.sort}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        helperText={meta.touched ? meta.error : undefined}
        error={handleFieldErrors(meta)}
        disabled={disabled}
      />
    );
  };

  const stateSelect = (objSchema: any) => {
    return (
      <StateSelector
        id={key}
        label={getName(label)}
        placeholder={getName(label)}
        name={key}
        variant="outlined"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        helperText={meta.touched ? meta.error : undefined}
        error={handleFieldErrors(meta)}
        disabled={disabled}
      />
    );
  };

  const sensitiveType = (objSchema: any) => {
    return (
      <SensitiveInput
        id={key}
        objSchema={objSchema}
        label={getName(label)}
        placeholder={getName(label)}
        name={key}
        variant="outlined"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        helperText={meta.touched ? meta.error : undefined}
        error={handleFieldErrors(meta)}
        disabled={disabled}
      />
    );
  };

  const getInputType = (objSchema: any) => {
    switch (component) {
      case 'select':
        return selectField(objSchema);
      case 'state':
        return stateSelect(objSchema);
      case 'years':
        return yearsSelect(objSchema);
      case 'sensitive':
        return sensitiveType(objSchema);
      case 'email':
        return emailField(objSchema);
      case 'text':
      default:
        return textField(objSchema);
    }
  };

  const showSpecialDisclosure = () => {
    const msg = getSpecialMessage(objSchema, storeName);
    if (msg && msg.length > 0) {
      return <FormHelperText style={{ fontSize: 12 }}>{msg}</FormHelperText>;
    } else {
      return <></>;
    }
  };

  return (
    <>
      {getInputType(objSchema)}
      {showSpecialDisclosure()}
    </>
  );
};

export default DynamicInput;
