import React, { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { apiRequest } from '../../../api/request-handler';
import { API_VALIDATE_OTP } from '../../../api/request-objects';
import { AuthContextType } from '../../../contexts/auth/jwt-context';
import { useAuth } from '../../../hooks/use-auth';

interface OTPModalI {
  formik: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  setsignInCalled: (signInCalled: boolean) => void;
}

const OTPModal: FC<OTPModalI> = ({ open, setOpen, formik, setsignInCalled }) => {
  const { signIn } = useAuth<AuthContextType>();
  const [otp, setOtp] = useState<string>('');

  const handleClose = () => {
    setsignInCalled(false);
    setOpen(false);
  };

  const handleVerify = async () => {
    try {
      // Request backend to verify OTP
      const response = await apiRequest(API_VALIDATE_OTP, {
        body: { otp, email: formik.values.email },
      });

      if (response === 'approved') {
        console.log('Phone number successfully verified');
        await signIn(formik.values.email, formik.values.password, undefined);
        setsignInCalled(true);
        setOpen(false);
      } else {
        toast.error('Verification failed');
        console.log('Verification failed');
      }
    } catch (error) {
      toast.error('Error verifying OTP');
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter One-Time Password</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the one-time password sent to your mobile number.</DialogContentText>
          <TextField
            margin="dense"
            label="One-Time Password"
            type="text"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleVerify} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OTPModal;
