import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fpAuthColors } from '../../theme/fp-auth-colors';

const FPALCWrapper = styled(Grid)(() => ({
  flex: 0,
  width: '100%',
}));

const StyledALCText = styled(Typography)(() => ({
  color: fpAuthColors.fpDarkBlue,
  fontSize: '.75rem',
}));

const FPAuthLegalCopyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FPALCWrapper container spacing={2}>
      <Grid item xs={12} lg>
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Box>
            <StyledALCText />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} lg="auto">
        <StyledALCText>&copy; FormPiper {currentYear}</StyledALCText>
      </Grid>
    </FPALCWrapper>
  );
};

export default FPAuthLegalCopyright;

