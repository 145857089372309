import React, { useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';

interface LenderResponseTabsI {
  combinedResponses: GroupedOfferI[];
}
const LenderResponseTabs = (props: LenderResponseTabsI) => {
  const { combinedResponses } = props;
  const [value, setValue] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const tabsRef = useRef(null);
  const tabsPositionRef = useRef(null);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    const element = document.getElementById(`lender-${newValue}`);
    if (element && tabsRef.current) {
      // @ts-ignore
      const yOffset = -tabsRef.current.offsetHeight;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // @ts-ignore
      if (tabsRef.current && tabsPositionRef?.current) {
        // @ts-ignore
        const tabsTop = tabsRef.current.getBoundingClientRect().top;
        // @ts-ignore
        const originalPosition = tabsPositionRef.current.getBoundingClientRect().top;

        setIsSticky(window.scrollY > originalPosition);

        const lenderElements = combinedResponses.map((_, index) => document.getElementById(`lender-${index}`));

        lenderElements.forEach((element, index) => {
          if (element) {
            const rect = element.getBoundingClientRect();
            // @ts-ignore
            if (rect.top <= tabsRef.current.offsetHeight && rect.bottom >= tabsRef.current.offsetHeight) {
              setValue(index);
            }
          }
        });
      }
    };

    // Capture the original position of the tabs
    if (tabsRef.current) {
      // @ts-ignore
      tabsPositionRef.current = tabsRef.current;
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [combinedResponses]);

  return (
    <>
      {/* @ts-ignore */}
      <Box ref={tabsPositionRef} style={{ height: isSticky ? `${tabsRef.current?.offsetHeight}px` : '0px' }} />
      <Box
        ref={tabsRef}
        sx={{
          position: isSticky ? 'fixed' : 'static',
          top: isSticky ? 0 : 'auto',
          left: 0,
          right: 0,
          backgroundColor: 'background.paper',
          zIndex: 1000,
          boxShadow: isSticky ? '0px 2px 4px -1px rgba(0,0,0,0.2)' : 'none',
          transition: 'box-shadow 0.3s ease',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          {combinedResponses.map((offer, index) => (
            <Tab key={index} label={offer.lender.name} />
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default LenderResponseTabs;
