/* eslint-disable */
export const notACreditCardDisclosure = `The purpose of this FormPiper form is to help facilitate the collection and input of your data on to an application for credit. Depending on the lender(s) you selected, this may be an application for credit/lease-to-own purchase options, however there is no obligation to fill out this form. If you do not wish to fill out this form, please see a store associate who will assist you in manually filling out an application for credit.`;
export const thisIsNotCcSecondDisclosure = `This form is not an application for credit. The information collected on this form will be transmitted to creditors to assist in completing credit applications which must be submitted with the assistance of an in-store associate. If you do not want to provide your email or mobile number please work with an in-store associate to apply directly through the lender portals.`;
export const oldThisIsNotCcSecondDisclosure = `This form is not an application for credit. The information collected on this form will be transmitted to
            creditors to assist in completing credit applications which must be submitted with the assistance of an
            in-store associate.`;

const addDisclosureParties = (storeName: any) => {
  const specialLendersWithDisclosures = [2];
  let disclosureParties = 'FormPiper';
  if (storeName && storeName.length > 0) {
    disclosureParties += `, ${storeName}`;
  }
  return `${disclosureParties} and Premier Service Group`;
};

export const getSpecialMessage = (objSchema: any, storeName: any) => {
  // ToDo - Turn this into a parser at some point that can read tags
  // it just doesn't make sense now, since we only have one special disclosure
  if (objSchema.msg) {
    return objSchema.msg;
  } else if (objSchema.specialDisclosure) {
    return `By providing your number you agree that ${addDisclosureParties(storeName)} may contact you
at this number regarding your account for any and all purposes.`;
  }
};
